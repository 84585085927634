import React from 'react';

import './ErrorsCard.scss';
import { KiteCard, KiteButton } from '@kite/react-kite';

export interface IErrorsCardProps {
  errors: string[];
  onButtonClick: () => void;
  loadingButton: string;
}

const ErrorsCard = ({
  errors,
  onButtonClick,
  loadingButton,
}: IErrorsCardProps) => {
  return (
    <KiteCard className="errors-card">
      <h3 className="errors-card__title">Errors</h3>
      <KiteButton
        onClick={onButtonClick}
        className="errors-card__button"
        type="outline"
        loading={loadingButton === 'errorsResolved'}
      >
        Errors Resolved
      </KiteButton>
      <ul>
        {errors.map((err) => (
          <li>{err}</li>
        ))}
      </ul>
    </KiteCard>
  );
};

export default ErrorsCard;
