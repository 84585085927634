import React, { useState } from 'react';
import { KiteModal, KiteButton, KiteInput } from '@kite/react-kite';
import { MultiSelect } from '@kite/react-kite-plus';
import classNames from 'classnames';

import './AddErrorModal.scss';

export interface IAddErrorModalProps {
  isOpen: boolean;
  onAdd: (selectedErrors: string[], errorDetails: string) => any;
  onHide: (bool: boolean) => any;
  errorOptions: string[];
}

const AddErrorModal = ({
  isOpen,
  onAdd,
  onHide,
  errorOptions,
}: IAddErrorModalProps) => {
  const [selectedErrors, setSelectedErrors] = useState<any>([]);
  const [errorDetails, setErrorDetails] = useState('');

  const handleAdd = () => {
    onAdd(selectedErrors, errorDetails);
    onHide(false);
  };

  return (
    <KiteModal
      className="add-error"
      canShow={isOpen}
      title="Add Errors"
      disableBackgroundClose
      showCloseButton
    >
      <p className="add-error__copy">
        This will add the following errors to this feature while it remains in
        Quality Control.
      </p>
      <MultiSelect
        className={classNames({
          'add-error__select': true,
          'add-error__select--none': !selectedErrors.length,
        })}
        options={errorOptions}
        label="Select Error Type (if applicable)"
        placeholder="None"
        maxWidth="470px"
        onSelectionsChange={(errors) => setSelectedErrors(errors)}
      />
      {!!selectedErrors.length && (
        <KiteInput
          className="add-error__input"
          label="Other Error Details"
          value={errorDetails}
          onChange={({ target }) => setErrorDetails(target.value)}
          maxWidth="470px"
        />
      )}
      <div className="add-error__button-wrapper">
        <KiteButton
          type="outline"
          className="add-error__button add-error__button--cancel"
          onClick={() => onHide(false)}
        >
          Cancel
        </KiteButton>
        <KiteButton
          className="add-error__button add-error__button--remove"
          onClick={handleAdd}
        >
          Add Errors
        </KiteButton>
      </div>
    </KiteModal>
  );
};

export default AddErrorModal;
