import React from 'react';
import classnames from 'classnames';

import './Metadata.scss';
import { KiteIcon, KiteButton, KiteAlert } from '@kite/react-kite';
import { CSSTransition } from 'react-transition-group';

export interface IMetadataProps {
  assetType: 'movie' | 'series' | 'episode';
  data: any;
  onUpdate: () => any;
  onRefresh?: () => void;
  showAlert: boolean;
  isNew?: boolean;
  loadingButton?: string;
  badMetadata?: boolean;
}

const Metadata = ({
  assetType,
  data,
  onUpdate,
  onRefresh,
  showAlert,
  isNew,
  loadingButton,
  badMetadata,
}: IMetadataProps) => {
  const { posterUrl, epicUrl, tmsId } = data;

  const fields = {
    episode: ['title', 'number', 'rating', 'season', 'year', 'description'],
    series: ['title', 'rating', 'genre', 'seasons', 'description'],
    movie: ['title', 'year', 'rating', 'duration', 'genres', 'description'],
  };

  if (isNew) {
    fields.series = ['title', 'rating', 'genre', 'description'];
  }

  const displayFields = fields[assetType].map((field) => (
    <p className="metadata__field" key={`${field}+${data.id}`}>
      <span className={`metadata__field-title metadata__field-${field}`}>
        {field}
      </span>
      {field === 'seasons' ? data[field].length : data[field]}
    </p>
  ));

  const metadataTop = (
    <div className="metadata__top">
      <div className="metadata__heading-wrapper">
        <h2>Feature Metadata</h2>
        <CSSTransition
          classNames="changes-message"
          in={showAlert}
          unmountOnExit
          timeout={400}
        >
          <>
            <KiteAlert
              level="inline"
              type="confirm"
              description="Changes Saved"
            />
          </>
        </CSSTransition>
      </div>

      <div className="metadata__inputs">
        <p className="metadata__copy">
          <span className="metadata__title">TMSID</span>
          {tmsId}
        </p>
        <KiteButton
          className="metadata__button--update"
          onClick={onUpdate}
          type="outline"
          loading={loadingButton === 'metadata'}
        >
          Change TMSID
        </KiteButton>
        <KiteButton
          className="metadata__button--refresh"
          onClick={onRefresh}
          type="outline"
          loading={loadingButton === 'refresh metadata'}
        >
          Refresh Metadata
        </KiteButton>
      </div>
    </div>
  );

  const metadataContent = (
    <div className="metadata__content">
      <div className="metadata__images">
        <h4>Images</h4>
        <div className="metadata__image-wrapper">
          <img className="metadata__poster" src={posterUrl} alt="" />
          <div className="metadata__image-caption">
            <p>Poster</p>
            <a href={posterUrl} target="_blank" rel="noopener noreferrer">
              <KiteIcon
                className="metadata__image-button"
                name="linkout"
                color="#31a2ff"
                ariaLabel="Open poster in new tab"
              />
            </a>
          </div>
        </div>
        <div className="metadata__image-wrapper">
          <img className="metadata__epic" src={epicUrl} alt="" />
          <div className="metadata__image-caption">
            <p>Epic Image</p>
            <a href={epicUrl} target="_blank" rel="noopener noreferrer">
              <KiteIcon
                className="metadata__image-button"
                name="linkout"
                color="#31a2ff"
                ariaLabel="Open epic image in new tab"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="metadata__details">
        {!isNew && <h4>Details</h4>}
        <div className="metadata__fields-wrapper">{displayFields}</div>
      </div>
    </div>
  );

  const badMetadataWarning = (
    <div className="metadata__bad">
      <KiteIcon
        name="caution-alert-f"
        color="#feb533"
        size="60px"
        margin="24px 0"
      />
      <p>
        No metadata available for this feature. Please update the TMSID or
        refresh metadata.
      </p>
    </div>
  );

  return (
    <div className={classnames({ metadata: true, 'metadata--new': isNew })}>
      {!isNew && metadataTop}
      <article className="metadata__card">
        <h3>Metadata</h3>
        {badMetadata ? badMetadataWarning : metadataContent}
      </article>
    </div>
  );
};

export default Metadata;
