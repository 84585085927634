import React from 'react';
import { KiteModal } from '@kite/react-kite';

import './SearchModal.scss';
import { Search } from '../../pages';

export interface ISearchModalProps {
  isOpen: boolean;
  onClose: () => any;
  onUpdate: (tmsId: string, title: string) => any;
  featureType: 'movie' | 'series' | 'episode';
  title: string;
  episodeTitle?: string;
}

const SearchModal = ({
  isOpen,
  onClose,
  featureType,
  title,
  episodeTitle,
  onUpdate,
}: ISearchModalProps) => {
  return (
    <KiteModal
      className="search-modal"
      canShow={isOpen}
      title="Update TMSID"
      disableBackgroundClose
      showCloseButton
      onHide={onClose}
    >
      <Search
        isModal
        modalType={featureType}
        initialSearch={title}
        episodeTitle={episodeTitle}
        onUpdate={onUpdate}
      />
    </KiteModal>
  );
};

export default SearchModal;
