import React, { Component } from 'react';
import { RouterComponentProps } from 'react-router-dom';
import {
  KiteCard,
  KiteButton,
  KiteLoader,
  KiteInput,
  KiteSelect,
} from '@kite/react-kite';

import * as SearchService from '../../apiCalls/SearchService';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';
import {
  SearchBar,
  ServerError,
  MovieResult,
  EpisodeResult,
} from '../../components';

import './Search.scss';

export interface ISearchState {
  results: any[];
  searchType: 'movie' | 'episode' | 'series';
  filter: { title: string; season: string; episode: string };
  filteredResults: any[];
  serverError: boolean;
  loading: boolean;
}

export interface ISearchProps {
  isModal?: boolean;
  modalType?: 'movie' | 'episode' | 'series';
  initialSearch?: string;
  episodeTitle: string;
  onUpdate: (tmsId: string) => void;
}

class Search extends Component<
  RouterComponentProps & ISearchProps,
  ISearchState
> {
  state: ISearchState = {
    results: [],
    searchType: 'movie',
    filter: {
      title: '',
      season: '',
      episode: '',
    },
    filteredResults: [],
    serverError: false,
    loading: false,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    const { modalType, initialSearch, episodeTitle, isModal } = this.props;

    if (this.context && !isModal) {
      const [analytics] = this.context;
      analytics.trackPageView('Search');
    }

    if (modalType) {
      this.setState({ searchType: modalType }, () => {
        if (initialSearch) {
          this.handleSearch(initialSearch).then(() => {
            if (episodeTitle) {
              this.handleFilterChange({
                target: { name: 'title', value: episodeTitle },
              });
            }
          });
        }
      });
    }
  }

  handleSearch = async (query) => {
    this.setState({ loading: true });
    const data = await SearchService.searchDS(query, [this.state.searchType]);
    const results = this.props.isModal
      ? data.new
      : [...data.existing, ...data.new];
    this.setState({ results, loading: false });
  };

  handleTypeChange = (e) => {
    this.setState({
      searchType: e.target.value,
      results: [],
      filteredResults: [],
    });
  };

  handleFilterChange = (e) => {
    const { filter, results } = this.state;
    const { name, value } = e.target;
    const newFilters = { ...filter, [name]: value };
    this.setState({ filter: newFilters });
    let filteredResults = [...results];

    if (newFilters.title) {
      filteredResults = filteredResults.filter((ep) => {
        if (ep.title) {
          return (
            ep.title.toUpperCase().includes(newFilters.title.toUpperCase()) ||
            ep.title.toUpperCase() === newFilters.title.toUpperCase()
          );
        } else {
          return false;
        }
      });
    }

    if (newFilters.season) {
      filteredResults = filteredResults.filter(
        (ep) => ep.season === +newFilters.season
      );
    }

    if (newFilters.episode) {
      filteredResults = filteredResults.filter(
        (ep) => ep.episode === +newFilters.episode
      );
    }

    this.setState({ filteredResults });
  };

  handleClearFilters = () => {
    this.setState({
      filteredResults: [],
      filter: {
        title: '',
        season: '',
        episode: '',
      },
    });
  };

  render() {
    const {
      results,
      serverError,
      loading,
      searchType,
      filter,
      filteredResults,
    } = this.state;

    const { isModal, onUpdate } = this.props;

    const isFiltered = !!filter.title || !!filter.season || !!filter.episode;

    const resultsToRender = isFiltered ? filteredResults : results;

    const displayEpisodeResults = resultsToRender.map((episode) => (
      <EpisodeResult
        key={episode.episodeTmsid}
        episode={episode}
        onAdd={() => {}}
        onUpdate={onUpdate}
        resultType={isModal ? 'update' : 'search'}
      />
    ));

    const displayMovieResults = results.map((movie) => (
      <MovieResult
        key={movie.tmsId}
        movie={movie}
        onAdd={() => {}}
        onUpdate={onUpdate}
        resultType={isModal ? 'update' : 'search'}
      />
    ));

    const displayFilters = (
      <div className="add-episode__filters">
        <div className="add-episode__filters-input-wrapper">
          <KiteInput
            className="add-episode__filters--title"
            id="add-episode-title"
            name="title"
            label="Filter by Title"
            value={filter.title}
            onChange={this.handleFilterChange}
          />
          <KiteInput
            type="number"
            id="add-episode-season"
            name="season"
            label="Filter by Season"
            value={filter.season}
            onChange={this.handleFilterChange}
          />
          <KiteInput
            type="number"
            id="add-episode-episode"
            name="episode"
            label="Filter by Number"
            value={filter.episode}
            onChange={this.handleFilterChange}
          />
        </div>
        {isFiltered && (
          <p className="add-episode__filtered-by">
            Filtered by:
            {!!filter.title && (
              <span className="add-episode__filtered-by-field">
                Title:{' '}
                <span className="add-episode__filtered-by-value">
                  {filter.title}
                </span>
              </span>
            )}
            {!!filter.season && (
              <span className="add-episode__filtered-by-field">
                Season:{' '}
                <span className="add-episode__filtered-by-value">
                  {filter.season}
                </span>
              </span>
            )}
            {!!filter.episode && (
              <span className="add-episode__filtered-by-field">
                Number:{' '}
                <span className="add-episode__filtered-by-value">
                  {filter.episode}
                </span>
              </span>
            )}
            <KiteButton
              className="add-episode__clear-filters"
              type="standalone-link"
              size="small"
              onClick={this.handleClearFilters}
            >
              Clear Filters
            </KiteButton>
          </p>
        )}
      </div>
    );

    const typeSelect = (
      <KiteSelect
        value={searchType}
        label="Asset Type"
        className="search__select"
        onChange={this.handleTypeChange}
        name="asset-type-select"
        id="asset-type-select"
      >
        {['movie', 'episode', 'series'].map((type) => (
          <option value={type}>{type}</option>
        ))}
      </KiteSelect>
    );

    const episodeSearch = (
      <KiteCard className="add-episode__results-card">
        <p className="add-episode__title">Search by Title</p>

        <div className="search__input-wrapper">
          {typeSelect}
          <SearchBar
            featureType="Titles"
            onSearch={this.handleSearch}
            showShadow={!results.length}
            startingQuery={this.props.initialSearch || ''}
          />
        </div>

        {!!results.length && (
          <>
            <p className="add-episode__title">
              Found Episodes ({results.length})
            </p>
            {displayFilters}
          </>
        )}
        <div className="search__results-wrapper">{displayEpisodeResults}</div>
        {!results.length && !loading && (
          <p className="add-episode__no-results">
            Search episodes by title to view available episodes
          </p>
        )}
        {loading && (
          <KiteLoader
            className="add-episode__loader"
            loaderTitle="Loading Episode Metadata"
            secondaryMessage="Searching for titles"
          />
        )}
      </KiteCard>
    );

    const movieSearch = (
      <KiteCard className="add-movie__results-card">
        <p className="add-movie__title">Search by Title</p>
        <div className="search__input-wrapper">
          {typeSelect}
          <SearchBar
            featureType="Titles"
            onSearch={this.handleSearch}
            showShadow={!results.length}
            startingQuery={this.props.initialSearch || ''}
          />
        </div>
        {!!results.length && (
          <p className="add-movie__title">Found Movies ({results.length})</p>
        )}

        <div className="search__results-wrapper">{displayMovieResults}</div>
        {!results.length && !loading && (
          <p className="add-movie__no-results">
            Search movies by title to view available movies
          </p>
        )}
        {loading && (
          <KiteLoader
            className="add-movie__loader"
            loaderTitle="Loading Movie Metadata"
            secondaryMessage="Searching for titles"
          />
        )}
      </KiteCard>
    );

    const seriesSearch = (
      <KiteCard className="add-movie__results-card">
        <p className="add-movie__title">Search by Title</p>

        <div className="search__input-wrapper">
          {typeSelect}
          <SearchBar
            featureType="Titles"
            onSearch={this.handleSearch}
            showShadow={!results.length}
            startingQuery={this.props.initialSearch || ''}
          />
        </div>

        {!!results.length && (
          <p className="add-movie__title">Found Series ({results.length})</p>
        )}

        <div className="search__results-wrapper">{displayMovieResults}</div>
        {!results.length && !loading && (
          <p className="add-movie__no-results">
            Search series by title to view available series
          </p>
        )}
        {loading && (
          <KiteLoader
            className="add-movie__loader"
            loaderTitle="Loading Movie Metadata"
            secondaryMessage="Searching for titles"
          />
        )}
      </KiteCard>
    );

    const searches = {
      movie: movieSearch,
      episode: episodeSearch,
      series: seriesSearch,
    };

    return (
      <div>
        <main className="search">{searches[searchType]}</main>
        <ServerError
          isOpen={serverError}
          onClose={() => this.setState({ serverError: false })}
        />
      </div>
    );
  }
}

export default Search;
