import React, { useContext, useEffect } from 'react';

import './Unauthorized.scss';
import { KiteIcon } from '@kite/react-kite';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';

const Unauthorized = () => {
  const [analytics] = useContext(AnalyticsContext);

  useEffect(() => {
    analytics.trackPageView('Unauthorized');
  }, []);
  return (
    <div>
      <header className="nav">
        <h1>Spectrum Access Admin</h1>
      </header>
      <main className="unauthorized">
        <KiteIcon name="info-circle-f" color="#d8dde6" size="200px" />
        <p className="unauthorized__title">
          Authorization required to view Spectrum Access Admin
        </p>
        <p className="unauthorized__copy">
          Please email{' '}
          <a href="mailto:DL-SpecAccessSupport@charter.com">
            Spectrum Access Support
          </a>{' '}
          to request access.
        </p>
      </main>
    </div>
  );
};

export default Unauthorized;
