import React from 'react';
import { KiteModal, KiteButton } from '@kite/react-kite';

import './AddSeriesModal.scss';

export interface IAddSeriesModalProps {
  isOpen: boolean;
  seriesTitle: string;
  onContinue: () => any;
  onHide: (bool: boolean) => any;
}

const AddSeriesModal = ({
  isOpen,
  onContinue,
  onHide,
  seriesTitle,
}: IAddSeriesModalProps) => {
  const handleContinue = () => {
    onContinue();
    onHide(false);
  };

  return (
    <KiteModal
      className="add-series-modal"
      canShow={isOpen}
      title="Add New Series"
      disableBackgroundClose
      showCloseButton={false}
    >
      <p className="add-series-modal__copy">
        Adding this episode will also add the series "{seriesTitle}"" to Access
        Admin.
      </p>

      <div className="add-series-modal__button-wrapper">
        <KiteButton
          type="outline"
          className="add-series-modal__button add-series-modal__button--cancel"
          onClick={() => onHide(false)}
        >
          Cancel
        </KiteButton>
        <KiteButton
          className="add-series-modal__button add-series-modal__button--remove"
          onClick={handleContinue}
        >
          Continue
        </KiteButton>
      </div>
    </KiteModal>
  );
};

export default AddSeriesModal;
