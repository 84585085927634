import React from 'react';

import { KiteIcon, KiteButton } from '@kite/react-kite';
import { Link } from 'react-router-dom';

import './SeriesResult.scss';

export interface ISeriesResultProps {
  series: ISeriesResult;
  onAdd: (id: string) => void;
}

export interface ISeriesResult {
  id: string;
  type: 'existing' | 'new';
  title: string;
  rating: string;
  tmsId: string;
  posterUrl: string;
}

const SeriesResult = ({
  series: { id, title, type, tmsId, posterUrl, rating },
  onAdd,
}: ISeriesResultProps) => {
  return (
    <article className="series-result">
      <img
        className="series-result__image"
        alt="Series Poster"
        src={posterUrl}
        width="110"
        height="165"
      />

      <div className="movie-result__content">
        <h3 className="title">{title}</h3>
        <div className="info">
          {/* <span className="dot"></span> */}
          <span className="label">{rating}</span>
        </div>
        <div className="info">
          <span className="label">TMSID </span>
          <p>{tmsId}</p>
        </div>
      </div>

      <div className="series-result__right">
        <span className={`series-result__badge series-result__badge--${type}`}>
          {type} Series
        </span>

        {type === 'existing' ? (
          <Link className="series-result__link" to={`/series/${id}`}>
            <span className="link__content">
              <KiteIcon name="plus-alt" />
              Update Series
            </span>
          </Link>
        ) : (
          <KiteButton
            className="series-result__button"
            type="outline"
            leftIcon="plus-alt"
            size="medium"
            onClick={() => onAdd(tmsId)}
          >
            Add Series
          </KiteButton>
        )}
      </div>
    </article>
  );
};

export default SeriesResult;
