import React, { useState } from 'react';

import './UnpublishedServices.scss';
import { KiteModal, KiteRadio } from '@kite/react-kite';

export interface IUnpublishedServicesProps {
  isOpen: boolean;
  onContinue: (publishServices: string) => void;
  onHide: (bool: boolean) => void;
}

const UnpublishedServices = ({
  isOpen,
  onContinue,
  onHide,
}: IUnpublishedServicesProps) => {
  const [option, setOption] = useState<string>('yes');

  const handleCancel = () => {
    onHide(false);
    setOption('onlyPublished');
  };
  const handleContinue = (publishServices: string) => {
    onContinue(publishServices);
    onHide(false);
  };

  return (
    <KiteModal
      className="unpublished-services"
      title="Would you like to move all services to Ready for Production?"
      canShow={isOpen}
      disableBackgroundClose
      showCloseButton={false}
      ctaCopy="Continue"
      ctaAction={() => handleContinue(option)}
      secondaryCtaCopy="Cancel"
      secondaryCtaAction={() => handleCancel()}
    >
      <KiteRadio
        buttonOrientation="column"
        name="publishOption"
        onChange={(e) => setOption(e.target.value)}
        radioButtons={[
          {
            id: 'yes',
            label:
              'Yes, all of these services can be marked Ready for Production.',
            value: 'yes',
            checked: option === 'yes',
          },
          {
            id: 'no',
            label:
              'No, I will individually publish the services once the movie is in production',
            value: 'no',
            checked: option === 'no',
          },
        ]}
      ></KiteRadio>
    </KiteModal>
  );
};

export default UnpublishedServices;
