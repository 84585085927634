import React, { useRef, useState } from 'react';
import {
  KiteButton,
  // KiteCheckbox,
  KiteCard,
  KiteRadio,
} from '@kite/react-kite';

// Hooks
import useClickOutside from '../../hooks/useClickOutside/useClickOutside';

import './FilterMenu.scss';

export interface IFilterMenuProps {
  type: 'Series' | 'Assets' | 'Episodes' | 'Movies';
  // filters: TFilters;
  // onSelect: (filter: TFilters) => void;
  statusFilter: string;
  onSelect: (filter: string) => void;
  statusCounts?: any[];
}

export type TFilters = { [key: string]: boolean };
type TFilterOptions = { [key: string]: string };

// const statusFilterOptions: TFilterOptions = {
//   new: 'New',
//   qualityControl: 'Quality Control',
//   readyForProduction: 'Ready for Production',
//   inProduction: 'In Production',
// };

// const servicesFilterOptions: TFilterOptions = {
//   audioDescription: 'Audio Description',
//   amplifiedAudio: 'Amplified Audio',
//   closedCaption: 'Closed Caption',
//   combinedAudio: 'Combined Audio',
//   signLanguage: 'Sign Language',
// };

const FilterMenu = ({
  type,
  statusFilter,
  onSelect,
  statusCounts,
}: IFilterMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<any>(null);

  useClickOutside(ref, () => {
    isOpen && setIsOpen(false);
  });

  // change handler for "ALL" checkboxes
  // const handleSelectAll = ({ checked }, options: TFilterOptions) => {
  //   const updatedFilters = getUpdatedFilters(checked, options);
  //   onSelect(updatedFilters);
  // };

  // helper function for "ALL" checkbox change handler
  // const getUpdatedFilters = (checked: boolean, options: TFilterOptions) => {
  //   return [...Object.keys(options)].reduce((acc, key) => {
  //     checked ? (acc[key] = true) : (acc[key] = false);
  //     return acc;
  //   }, {});
  // };

  // checks to see if every filter option is selected in the filters
  // const isAllSelected = (filterOptions: TFilterOptions, filters: TFilters) => {
  //   return [...Object.keys(filterOptions)].every((key) => filters[key]);
  // };

  // for checkboxes that are not "ALL"
  // const handleSelectFilter = ({ target }) => {
  //   const { name, checked } = target;
  //   onSelect({ [name]: checked });
  // };

  // const renderSection = (title, options) => (
  //   <section className="filter-menu__options">
  //     <h3>{title}</h3>
  //     <KiteCheckbox
  //       className="filter-menu__checkbox"
  //       disabled={false}
  //       id={`all${title}Selected`}
  //       label="All"
  //       name={`all${title}Selected`}
  //       checked={isAllSelected(options, filters)}
  //       onChange={({ target }) => handleSelectAll(target, options)}
  //     />
  //     <ul>
  //       {[...Object.keys(options)].map((key) => (
  //         <li key={key}>
  //           <KiteCheckbox
  //             className="filter-menu__checkbox"
  //             disabled={false}
  //             id={key}
  //             label={options[key]}
  //             name={key}
  //             checked={filters[key]}
  //             onChange={handleSelectFilter}
  //           />
  //         </li>
  //       ))}
  //     </ul>
  //   </section>
  // );

  const radioOptions = [
    {
      id: 'All',
      label: `All - ${statusCounts ? statusCounts[0].count : ''}`,
      value: 'All',
      checked: statusFilter === 'All',
    },
    {
      id: 'New',
      label: `New - ${statusCounts ? statusCounts[1].count : ''}`,
      value: 'New',
      checked: statusFilter === 'New',
    },
    {
      id: 'QualityControl',
      label: `Quality Control - ${statusCounts ? statusCounts[2].count : ''}`,
      value: 'QualityControl',
      checked: statusFilter === 'QualityControl',
    },
    {
      id: 'ReadyForProduction',
      label: `Ready for Production - ${
        statusCounts ? statusCounts[3].count : ''
      }`,
      value: 'ReadyForProduction',
      checked: statusFilter === 'ReadyForProduction',
    },
    {
      id: 'Production',
      label: `In Production - ${statusCounts ? statusCounts[4].count : ''}`,
      value: 'Production',
      checked: statusFilter === 'Production',
    },
  ];

  return (
    <div className="filter-menu">
      <KiteButton
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls={`${type}Filters`}
        className="filter-menu__button"
        onClick={() => setIsOpen(!isOpen)}
        size="medium"
        leftIcon="filter-f"
        type="outline"
      >
        Filter
      </KiteButton>
      {isOpen && (
        <div
          ref={ref}
          aria-labelledby={`${type}Filters`}
          className="filter-menu__dropdown-container"
        >
          <KiteCard className="filter-menu__dropdown">
            {/* {type === 'Series' || type === 'Episodes' || type === 'Movies'
              ? renderSection('Status', statusFilterOptions)
              : null}
            {type === 'Assets' || type === 'Episodes' || type === 'Movies'
              ? renderSection('Services', servicesFilterOptions)
              : null} */}
            <section className="filter-menu__options">
              <h3>Status</h3>
              <KiteRadio
                radioButtons={radioOptions}
                name="filters"
                onChange={(e) => onSelect(e.target.value)}
              />
            </section>
          </KiteCard>
        </div>
      )}
    </div>
  );
};

export default FilterMenu;
