import React from 'react';

import './CountriesCard.scss';
import { KiteCard } from '@kite/react-kite';
import { BasicPills } from '@kite/react-kite-plus';

export interface ICountriesCardProps {
  countries: string[];
}

const CountriesCard = ({ countries }: ICountriesCardProps) => {
  return (
    <KiteCard className="countries-card">
      <h3 className="countries-card__title">Countries Whitelist</h3>
      <BasicPills className="countries-card__countries" values={countries} />
    </KiteCard>
  );
};

export default CountriesCard;
