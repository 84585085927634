import React from 'react';
import { KiteButton, KiteIcon } from '@kite/react-kite';
import { SortableTable } from '@kite/react-kite-plus';

import { renderTableDate } from '../../utils/helpers';

import './NewAssetsTable.scss';
import { INewAsset } from '../../utils/models';

export interface INewAssetsTableProps {
  assetData: INewAsset[];
  onRowClick: (movie: any) => void;
  loading: boolean;
}

const NewAssetsTable = ({
  assetData,
  onRowClick,
  loading,
}: INewAssetsTableProps) => {
  const columns = [
    {
      label: 'Title',
      sortKey: 'assetTitle',
      size: 2,
    },
    {
      label: 'Processed',
      sortKey: 'updatedAt',
      render: ({ updatedAt }) => renderTableDate(updatedAt, true),
      size: 1.5,
    },
    {
      label: 'Files',
      sortKey: '',
      sortEnabled: false,
      render: ({ assetFiles }) => assetFiles.length,
      size: 0.3,
    },
    {
      label: '',
      sortKey: '',
      sortEnabled: false,
      render: (item) => (
        <KiteButton
          onClick={() => onRowClick(item)}
          className="new-assets-table__cta"
          type="outline"
          leftIcon="plus-alt"
          size="small"
        >
          {item.tmsId ? 'Update' : 'Add'} Asset
        </KiteButton>
      ),
      size: 1,
    },
  ];

  const expRender = (item) => (
    <div className="new-assets-table__exp">
      <p className="new-assets-table__exp-title">Files</p>
      <ul>
        {item.assetFiles.map((file) => (
          <li>{file}</li>
        ))}
      </ul>
    </div>
  );

  return (
    <SortableTable
      className="new-assets-table"
      columns={columns}
      tableData={assetData}
      initialSortHeader="updatedAt"
      loading={loading}
      expansionRender={(item) => expRender(item)}
    />
  );
};

export default NewAssetsTable;
