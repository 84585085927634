import axios, { AxiosRequestConfig } from 'axios';

const serviceValuesOut = {
  'Audio Description only': 'audio_description',
  'Audio Description + Full Audio': 'audio_description_and_full_audio',
  'Closed Captioning': 'closed_captions',
  'Amplified Audio': 'amplified_audio',
  'Sign Language': 'sign_language',
};

export const createContent = async (
  file,
  language,
  serviceType,
  tmsId,
  status
) => {
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: JSON.stringify({
      file,
      language,
      serviceType: serviceValuesOut[serviceType],
      tmsId,
      status,
    }),
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const res = await axios('/api/v1/contents', options);
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateService = async (id, fields) => {
  let formattedFields = { ...fields}
  if (fields.serviceType) {
    formattedFields = { ...fields, serviceType: serviceValuesOut[fields.serviceType]}
  }

  const options: AxiosRequestConfig = {
    method: 'PATCH',
    data: JSON.stringify(formattedFields),
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res = await axios(`/api/v1/contents/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const deleteService = async (id) => {
  const options: AxiosRequestConfig = {
    method: 'DELETE',
  };
  try {
    const res = await axios(`/api/v1/contents/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
