import React from 'react';
import { Link } from 'react-router-dom';
import { KiteIcon } from '@kite/react-kite';
import { SortableTable } from '@kite/react-kite-plus';

import ServiceIcon from '../ServiceIcon/ServiceIcon';
import TableStatus from '../TableStatus/TableStatus';

import { IEpisode } from '../../utils/models';
import { renderTableDate } from '../../utils/helpers';

import './EpisodesTable.scss';

export interface IEpisodesTableProps {
  episodeData: IEpisode[];
}

const EpisodesTable = ({ episodeData }: IEpisodesTableProps) => {
  const renderServiceIcons = (services) => {
    return services.map(({ type, status, language }) => (
      <ServiceIcon type={type} status={status} language={language} />
    ));
  };
  const columns = [
    {
      label: 'Title',
      sortKey: 'title',
    },
    {
      label: '#',
      sortKey: 'number',
      size: 0.2,
    },
    {
      label: 'Added',
      sortKey: 'addedOn',
      render: ({ addedOn }) => renderTableDate(addedOn),
    },
    {
      label: 'Updated',
      sortKey: 'updatedOn',
      render: ({ updatedOn }) => renderTableDate(updatedOn),
    },
    {
      label: 'Feature Status',
      sortKey: 'status',
      render: ({ status, errors }) => (
        <TableStatus status={status} errors={errors} />
      ),
    },
    {
      label: 'Services',
      sortKey: 'services',
      sortEnabled: false,
      render: (item) => renderServiceIcons(item.services),
      size: 0.5,
    },
    {
      label: '',
      sortKey: '',
      sortEnabled: false,
      render: (item) => (
        <Link
          to={`/series/${item.seriesId}/episodes/${item.id}`}
          className="movies-table__link"
        >
          View Episode
          <KiteIcon name="chevron-right" color="#0073d1" />
        </Link>
      ),
      size: 0.5,
    },
  ];
  return (
    <SortableTable
      className="episodes-table"
      columns={columns}
      tableData={episodeData}
      initialSortHeader="number"
    />
  );
};

export default EpisodesTable;
