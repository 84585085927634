import React, { Component } from 'react';
import { RouterComponentProps } from 'react-router-dom';
import {
  KiteButton,
  KiteIcon,
  KiteSwitch,
  KiteTooltip,
} from '@kite/react-kite';
import { SortableTable } from '@kite/react-kite-plus';

import {
  createCategory,
  getCategories,
  updateCategory,
} from '../../apiCalls/CategoryService';
import { renderTableDate } from '../../utils/helpers';
import './AllCategories.scss';
import { CreateCategory } from '../../components';

export interface IAllCategoriesState {
  categories: any[];
  loading: boolean;
  isToggleClick: boolean;
  showCreateModal: boolean;
}

class AllCategories extends Component<
  RouterComponentProps,
  IAllCategoriesState
> {
  state: IAllCategoriesState = {
    categories: [],
    loading: false,
    isToggleClick: false,
    showCreateModal: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.updateCatData();
  }

  updateCatData = () => {
    getCategories().then((categories) =>
      this.setState({
        categories,
        loading: false,
      })
    );
  };

  handleRowClick = (catData) => {
    setTimeout(() => {
      if (this.state.isToggleClick) {
        this.setState({ isToggleClick: false });
      } else {
        this.props.history.push(`/categories/${catData.id}`);
      }
    }, 0);
  };

  handleToggleClick = async (targetCat) => {
    this.setState({ isToggleClick: true });
    const { id, visible } = targetCat;
    await updateCategory(id, { visible: !visible });
    this.updateCatData();
  };

  handleCreate = async (name) => {
    await createCategory(name);
    this.updateCatData();
  };

  render() {
    const { categories, loading, showCreateModal } = this.state;

    const columns = [
      {
        label: 'Name',
        sortKey: 'name',
        size: 2,
      },
      {
        label: 'Added',
        sortKey: 'createdAt',
        render: ({ createdAt }) => renderTableDate(createdAt),
      },
      {
        label: 'Titles',
        sortKey: 'total',
        size: 0.5,
      },
      {
        label: (
          <span>
            Visible in Explore{' '}
            <KiteTooltip>
              This will make the category visible for users to view in the app.{' '}
            </KiteTooltip>
          </span>
        ),
        sortKey: '',
        sortEnabled: false,
        render: (cat) => (
          <KiteSwitch
            className="all-categories__switch"
            label="Visible"
            isOn={cat.visible}
            onClick={() => this.handleToggleClick(cat)}
          />
        ),
      },
      {
        label: '',
        sortKey: '',
        sortEnabled: false,
        render: () => <KiteIcon name="chevron-right" color="#0073d1" />,
        size: 0.1,
      },
    ];
    return (
      <main className="all-categories">
        <h2 className="all-categories__title">Categories</h2>
        <KiteButton
          className="all-categories__create-btn"
          type="outline"
          onClick={() => this.setState({ showCreateModal: true })}
        >
          Create Category
        </KiteButton>
        <p className="all-categories__copy">
          Categories determine what genres the movies and tv shows fall under
          and groups it with similar titles.
        </p>
        <SortableTable
          className="all-categories__table"
          columns={columns}
          tableData={categories}
          initialSortHeader="name"
          loading={loading}
          onRowClick={this.handleRowClick}
        />
        <CreateCategory
          isOpen={showCreateModal}
          onCreate={this.handleCreate}
          onHide={() => this.setState({ showCreateModal: false })}
        />
      </main>
    );
  }
}

export default AllCategories;
