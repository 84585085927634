/* eslint-disable eqeqeq */
import dayjs from 'dayjs';

export const renderTableDate = (date, showTime) => {
  if (showTime) {
    return dayjs(date).format('M/DD/YY - h:mm A');
  }

  return dayjs(date).format('M/DD/YY');
};

export const getStatusIcon = (status) => {
  switch (status) {
    case 'New':
      return 'plus-circle-f';
    case 'Quality Control':
      return 'edit-circle';
    case 'QualityControl':
      return 'edit-circle';
    case 'Ready for Production':
      return 'arrow-up-circle';
    case 'ReadyForProduction':
      return 'arrow-up-circle';
    case 'In Production':
      return 'checkmark-circle-f';
    case 'Production':
      return 'checkmark-circle-f';
    default:
      return '';
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'New':
      return '#003561';
    case 'Quality Control':
      return '#63738a';
    case 'QualityControl':
      return '#63738a';
    case 'ReadyForProduction':
      return '#31a2ff';
    case 'Ready for Production':
      return '#31a2ff';
    case 'In Production':
      return '#008516';
    case 'Production':
      return '#008516';
    default:
      return '';
  }
};

export const iconNames = {
  'Audio Description only': 'audio-description',
  'Amplified Audio': 'amplified-audio',
  'Audio Description + Full Audio': 'audio-description',
  'Closed Captioning': 'cc',
  'Sign Language': 'sign-language',
};

export const getOrdinalSuffix = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) {
    return `${i}st`;
  }
  if (j == 2 && k != 12) {
    return `${i}nd`;
  }
  if (j == 3 && k != 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

export const sortPriority = () => {
  return (a, b) => {
    // equal items sort equally
    if (a.priorityNumber === b.priorityNumber) {
      return 0;
    }
    // nulls sort after anything else
    if (a.priorityNumber === null) {
      return 1;
    }
    if (b.priorityNumber === null) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    return a.priorityNumber < b.priorityNumber ? -1 : 1;
  };
};

export const getUnixTime = (date) => {
  return dayjs(date).unix() * 1000;
};
