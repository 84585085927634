import React, { useState } from 'react';
import { IService } from '../../utils/models';
import './ServiceCard.scss';
import { KiteIcon, KiteButton } from '@kite/react-kite';
import { iconNames, getStatusColor, getStatusIcon } from '../../utils/helpers';
import { downloadFile } from '../../apiCalls/BucketService';

export interface IServiceCardProps {
  serviceData: IService;
  onButtonClick: (action: 'edit' | 'publish' | 'remove', id: string) => any;
  featureStatus:
    | 'New'
    | 'Quality Control'
    | 'Ready for Production'
    | 'In Production';
}

const ServiceCard = ({
  serviceData,
  onButtonClick,
  featureStatus,
}: IServiceCardProps) => {
  const [loading, setLoading] = useState(false);
  const { file, type, status } = serviceData;

  const handleDownload = async () => {
    setLoading(true);
    await downloadFile(file);
    setLoading(false);
  };

  const serviceFields = ['status', 'language', 'created', 'updated', 'file'];

  const displayServiceFields = serviceFields.map((field) =>
    serviceData[field] ? (
      <p className="service-card__field" key={`${field}+${serviceData.id}`}>
        <span className="service-card__field-title">{field}:</span>
        {field === 'status' && (
          <KiteIcon
            className="service-card__field-icon"
            name={getStatusIcon(serviceData[field])}
            color={getStatusColor(serviceData[field])}
            size="14px"
          />
        )}
        {field !== 'file' && (
          <span className="service-card__download-text">
            {serviceData[field]}
          </span>
        )}
        {field === 'file' && (
          <KiteButton
            className="service-card__download-btn"
            type="standalone-link"
            rightIcon="download"
            onClick={handleDownload}
            loading={loading}
          >
            ...{file.split('').slice(-25).join('')}
          </KiteButton>
        )}
      </p>
    ) : null
  );

  return (
    <div className="service-card">
      <div className="service-card__heading-wrapper">
        <KiteIcon name={iconNames[type]} color="#9ba9bd" size="40px" />
        <h3>{type}</h3>
      </div>
      {displayServiceFields}

      {status === 'Quality Control' && (
        <div className="service-card__button-wrapper">
          <KiteButton
            className="service-card__edit service-card__button"
            type="outline"
            size={featureStatus === 'In Production' ? 'medium' : 'full'}
            onClick={() => onButtonClick('edit', serviceData.id)}
          >
            Edit
          </KiteButton>
          {featureStatus === 'In Production' && (
            <KiteButton
              className="service-card__publish service-card__button"
              type="outline"
              onClick={() => onButtonClick('publish', serviceData.id)}
              size="medium"
            >
              Publish
            </KiteButton>
          )}
        </div>
      )}
      {status === 'In Production' && (
        <KiteButton
          className="service-card__remove service-card__button"
          type="outline"
          size="large"
          onClick={() => onButtonClick('remove', serviceData.id)}
        >
          Remove from Production
        </KiteButton>
      )}
    </div>
  );
};

export default ServiceCard;
