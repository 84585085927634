import React from 'react';

import './StatusCounter.scss';
import { KiteCard } from '@kite/react-kite';

export interface TStatusCounterProps {
  statusCounterData: TStatusCounterData[];
}

export interface TStatusCounterData {
  count: number;
  label: string;
}

const StatusCounter = ({ statusCounterData }: TStatusCounterProps) => {
  return (
    <KiteCard className="status-counter">
      {statusCounterData.map(({ count, label }) => {
        return (
          <article key={label} className="status-counter__status">
            <h2 className="status-counter__count">{count}</h2>
            <p className="status-counter__label">{label}</p>
          </article>
        );
      })}
    </KiteCard>
  );
};

export default StatusCounter;
