import React, { useState } from 'react';
import { KiteModal, KiteButton, KiteInput } from '@kite/react-kite';
import { MultiSelect } from '@kite/react-kite-plus';
import classNames from 'classnames';

import './RemoveFeature.scss';

export interface IRemoveFeatureProps {
  isOpen: boolean;
  assetType: 'movie' | 'series' | 'episode';
  onRemove: (selectedErrors: string[], errorDetails: string) => any;
  onHide: (bool: boolean) => any;
  errorOptions: string[];
}

const RemoveFeature = ({
  isOpen,
  assetType,
  onRemove,
  onHide,
  errorOptions,
}: IRemoveFeatureProps) => {
  const [selectedErrors, setSelectedErrors] = useState<any>([]);
  const [errorDetails, setErrorDetails] = useState('');

  const handleRemove = () => {
    onRemove(selectedErrors, errorDetails);
    onHide(false);
  };

  return (
    <KiteModal
      className="remove-feature"
      canShow={isOpen}
      title="Remove from Production"
      disableBackgroundClose
      showCloseButton={false}
    >
      <p className="remove-feature__copy">
        This will remove the {assetType} from the production App
      </p>
      <MultiSelect
        className={classNames({
          'remove-feature__select': true,
          'remove-feature__select--none': !selectedErrors.length,
        })}
        options={errorOptions}
        label="Select Error Type (if applicable)"
        placeholder="None"
        maxWidth="470px"
        onSelectionsChange={(errors) => setSelectedErrors(errors)}
      />
      {!!selectedErrors.length && (
        <KiteInput
          className="remove_feature__input"
          label="Other Error Details"
          value={errorDetails}
          onChange={({ target }) => setErrorDetails(target.value)}
          maxWidth="470px"
        />
      )}
      <div className="remove-feature__button-wrapper">
        <KiteButton
          type="outline"
          className="remove-feature__button remove-feature__button--cancel"
          onClick={() => onHide(false)}
        >
          Cancel
        </KiteButton>
        <KiteButton
          className="remove-feature__button remove-feature__button--remove"
          onClick={handleRemove}
        >
          Remove
        </KiteButton>
      </div>
    </KiteModal>
  );
};

export default RemoveFeature;
