import React from 'react';
import { KiteModal, KiteButton } from '@kite/react-kite';

import './ErrorModal.scss';

export interface IErrorModalProps {
  isOpen: boolean;
  onClose: () => any;
  type: 'category' | 'file-QC' | 'file-Delete' | 'primary' | '';
}

const ErrorModal = ({ isOpen, onClose, type }: IErrorModalProps) => {
  let title, copy, action;
  if (type === 'file-Delete' || type === 'file-QC') {
    title = 'Fingerprint Files';
    copy = 'fingerprint file';
    action = 'Add';
  } else {
    title = 'Categories';
    copy = 'category';
    action = 'Select';
  }

  let displayCopy;

  if (type === 'primary') {
    displayCopy = (
      <p className="error-modal__copy">
        You can not remove this category from the feature because it is the
        primary category. Please select another primary category before
        removing.
      </p>
    );
  } else if (type === 'file-QC') {
    displayCopy = (
      <p className="error-modal__copy">
        At least one {copy} is required for this feature. {action} a {copy}{' '}
        before moving to quality control.
      </p>
    );
  } else {
    displayCopy = (
      <p className="error-modal__copy">
        At least one {copy} is required for this feature. {action} another{' '}
        {copy} before deleting this one.
      </p>
    );
  }

  return (
    <KiteModal
      className="error-modal"
      canShow={isOpen}
      title={`${title} are Required`}
      disableBackgroundClose
      showCloseButton={false}
    >
      {displayCopy}
      <KiteButton
        type="outline"
        className="error-modal__button"
        onClick={onClose}
      >
        Ok
      </KiteButton>
    </KiteModal>
  );
};

export default ErrorModal;
