import React from 'react';
import { KiteModal, KiteButton } from '@kite/react-kite';

import './PublishFeature.scss';

export interface IPublishFeatureProps {
  isOpen: boolean;
  onPublish: () => any;
  onHide: (bool: boolean) => any;
  featureType: 'movie' | 'episode' | 'series';
}

const PublishFeature = ({
  isOpen,
  onPublish,
  onHide,
  featureType,
}: IPublishFeatureProps) => {
  const handlePublish = () => {
    onPublish();
    onHide(false);
  };

  return (
    <KiteModal
      className="publish-feature"
      canShow={isOpen}
      title="Publish to Production"
      disableBackgroundClose
      showCloseButton={false}
    >
      <p className="publish-feature__copy">
        {featureType === 'series'
          ? "This will allow external users to view this series and all of it's published episodes in the production app."
          : `Would you like to publish this ${featureType} and all Ready for
        Production services, allowing users to view it in the mobile app?`}
      </p>

      <div className="publish-feature__button-wrapper">
        <KiteButton
          type="outline"
          className="publish-feature__button publish-feature__button--cancel"
          onClick={() => onHide(false)}
        >
          Cancel
        </KiteButton>
        <KiteButton
          className="publish-feature__button publish-feature__button--publish"
          onClick={handlePublish}
        >
          Publish
        </KiteButton>
      </div>
    </KiteModal>
  );
};

export default PublishFeature;
