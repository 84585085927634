import axios, { AxiosRequestConfig } from 'axios';

const epAssetCleaner = (data) => {
  const { assetTitle } = data;
  const [seriesTitle, episodeNumber] = assetTitle.split('/');

  const cleanAsset = {
    ...data,
    seriesTitle,
    episodeNumber,
  };
  return cleanAsset;
};

export const getNewAssets = async () => {
  const res = await axios('/api/v1/assets?order=asc&page=0&size=-1&sortBy=id');
  const cleanFilters = res.data.content.filter((content) => content.newAsset);
  return cleanFilters;
};

export const getAssetData = async (id, isEp?) => {
  const res = await axios(`/api/v1/assets/${id}`);
  if (isEp) {
    return epAssetCleaner(res.data);
  }
  return res.data;
};

export const updateAsset = async (id, fields) => {
  const options: AxiosRequestConfig = {
    method: 'PATCH',
    data: JSON.stringify(fields),
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res = await axios(`/api/v1/assets/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
