import Helix from '@charter/helix/build/venona/portals.min';

import extensions from '@charter/helix/build/extensions';

const state = {
  analytics: null,
  defaultData: null,
  config: {},
};

const isBrowser = typeof window !== `undefined`;

export default class AnalyticsService {
  constructor(config, version) {
    try {
      if (config && version) {
        state.config = config;
        if (state.config && state.config.collector) {
          state.config.collector.appVersion = version;
        }
      } else {
        console.error(
          `Analytics Service | Error - No Config or Version provided.`
        );
        return;
      }
    } catch (e) {
      console.error(`Analytics Service | Error on Constructor : ${e}`);
      return;
    }

    this.init = this.init.bind(this);
    this.fetchDefaultData = this.fetchDefaultData.bind(this);
    this.track = this.track.bind(this);
    this.trackPageView = this.trackPageView.bind(this);
    this.init();
  }

  init() {
    if (!isBrowser) return null;

    try {
      Helix.useDefaultStorage();
      Object.entries(state.config.quantum || {}).forEach((quantumConfig) =>
        Helix.set(...quantumConfig)
      );

      let endpoint;
      if (window.location.host.split('.')[0] === 'spectrum-access') {
        endpoint =
          'https://collector.pi.spectrum.net/topics/internal_quantum?bulk=true';
      } else {
        endpoint =
          'https://collector.pi-charter.net/topics/internal_quantum?bulk=true';
      }

      Helix.set('endpoint', endpoint);

      Helix.init(
        Object.assign({}, state.config.helix, this.fetchDefaultData())
      ).then(() => {
        if (Helix.get('currentState') === 'blank') {
          Helix.trackStartSession(state.config.collector.startSession);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * @doc method
   * @name analytics#fetchDefaultData
   * @methodOf analytics
   * @returns {}
   * @description Uses the Default Config information (./analytics-config.json) and adds specific client-side details
   */
  fetchDefaultData() {
    if (!isBrowser) return null;
    if (!state.defaultData) {
      state.defaultData = Object.assign({}, state.config.collector, {
        appRefLink: extensions.getAppReferrer(),
        appEnvName:
          window.location.host.split('.')[0] === 'spectrum-access'
            ? 'prod'
            : 'staging',
        deviceFormFactor: extensions.getFormFactor(),
        deviceModel: extensions.getDeviceModel(),
        deviceOperatingSystem: extensions.getDeviceOperatingSystem(),
        deviceUuid: extensions.getUUIDV4(),
        // deviceType: 'webBrowser',
        uiResponsiveBreakpoint: extensions.getCurrentBreakpoint(),
      });
    }
    return state.defaultData;
  }

  /**
   * @doc method
   * @name analytics#getAnalytics
   * @methodOf analytics
   * @returns {AnalyticsService}
   * @description Gets/Inits the AnalyticsService
   */
  static getAnalytics(config, version) {
    if (!isBrowser) return null;
    if (!state.analytics) {
      state.analytics = new AnalyticsService(config, version);
    }
    return state.analytics;
  }

  /**
   * @doc method
   * @name analytics#getVisitId
   * @methodOf analytics
   * @returns {}
   * @description Gets the Current VisitId from the LocalStorage
   */
  static getVisitId() {
    if (!isBrowser) return null;
    let visitId = null;
    const libState = JSON.parse(window.localStorage.getItem('library-state'));
    if (libState && libState.hasOwnProperty('model')) {
      visitId = libState.model.visit.visitId; // eslint-disable-line
    }
    return visitId;
  }

  getDefaultAdHocData = () => {
    return { uiResponsiveBreakpoint: extensions.getCurrentBreakpoint() };
  };

  /**
   * @doc method
   * @name analytics#track
   * @methodOf analytics
   * @returns {}
   * @description Calls Helix Track
   */
  track(id, adHocData, options) {
    if (!isBrowser) return null;
    try {
      Helix.track(
        id,
        Object.assign({}, adHocData, this.getDefaultAdHocData()),
        options
      );
    } catch (err) {
      if (this.debugAppEvents) {
        console.error(err);
      }
    }
  }

  /**
   * @doc method
   * @name analytics#trackPageView
   * @methodOf analytics
   * @returns {}
   * @description Calls Helix Track with a Complete PageView Cycle (Init-Completed). To be used when no Page load time calculation is required.
   */
  trackPageView(pageName, adHocData, pageUrl) {
    if (!isBrowser) return null;
    try {
      for (const msgEventType of ['pageViewInit', 'pageViewCompleted']) {
        Helix.track(
          'pageView',
          Object.assign(
            {
              msgEventType,
              currPageUrl: pageUrl || window.location.href,
              currPageName: pageName,
              currPageAppSection: 'Spectrum Access Admin',
            },
            adHocData
          )
        );
      }
    } catch (err) {
      if (this.debugAppEvents) {
        console.error(err);
      }
    }
  }

  // trackApiCalls() {
  //   this.apiService.postRequest.pipe(takeUntil(this.destroy$)).subscribe((apiCallData) => {
  //     const trackData = this.apiDefaultService.processSuccess(apiCallData);
  //     this.track('apiCall', trackData);
  //   });

  //   this.apiService.errorRequest.pipe(takeUntil(this.destroy$)).subscribe((apiCallData) => {
  //     let trackData = this.apiDefaultService.processError(apiCallData);
  //     if (trackData.appApiResponseCode === HttpStatusCode.Ok.toString()) {
  //       this.track('apiCall', trackData);
  //     } else {
  //       this.track('apiCall', trackData);
  //     }
  //   });
  // }
}
