import React, { Component } from 'react';
import { RouterComponentProps, Link } from 'react-router-dom';

import './AllSeries.scss';
import { ISeries } from '../../utils/models';
import { SeriesTable, SearchBar, StatusCounter } from '../../components';

import { KitePagination } from '@kite/react-kite';
import { getAllSeries } from '../../apiCalls/SeriesService';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';

export interface IAllSeriesState {
  allSeries: ISeries[];
  statusFilter: string;
  currentPage: number;
  totalPages: number;
  ascending: boolean;
  sortBy: 'title' | 'createdAt' | 'updatedAt' | 'status';
  loading: boolean;
  totalSeries: number;
  totalNew: number;
  totalQC: number;
  totalRFP: number;
  totalProd: number;
}

class AllSeries extends Component<RouterComponentProps, IAllSeriesState> {
  state: IAllSeriesState = {
    allSeries: [],
    statusFilter: 'All',
    currentPage: 1,
    totalPages: 0,
    ascending: true,
    sortBy: 'title',
    loading: false,
    totalSeries: 0,
    totalNew: 0,
    totalQC: 0,
    totalRFP: 0,
    totalProd: 0,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    this.getSeriesData(true, 1, 'title');
    const [analytics] = this.context;
    analytics.trackPageView('All Movies');
  }

  getSeriesData = (ascending, currentPage, sortBy, title?, status?) => {
    this.setState({ loading: true });
    const order = ascending ? 'asc' : 'desc';
    const requestConfig = {
      order,
      page: currentPage - 1,
      sortBy,
      title,
      status,
      size: 20,
    };
    getAllSeries(requestConfig).then(
      ({
        allSeries,
        totalPages,
        totalSeries,
        totalNew,
        totalQC,
        totalRFP,
        totalProd,
      }) =>
        this.setState({
          allSeries,
          totalPages,
          loading: false,
          totalSeries,
          totalNew,
          totalQC,
          totalRFP,
          totalProd,
        })
    );
  };

  handleFilterChange = (newFilter) => {
    this.setState({ statusFilter: newFilter, currentPage: 1 });
    const { sortBy, ascending } = this.state;
    const statusFilter = newFilter === 'All' ? false : newFilter;
    this.getSeriesData(ascending, 1, sortBy, false, statusFilter);
  };

  handleSearch = (query) => {
    this.setState({ currentPage: 1 });
    const { sortBy, ascending } = this.state;
    this.getSeriesData(ascending, 1, sortBy, query);
  };

  handleNextPage = () => {
    const { sortBy, ascending, currentPage, statusFilter } = this.state;
    const newPage = currentPage + 1;
    this.setState({ currentPage: newPage, allSeries: [] });
    this.getSeriesData(ascending, newPage, sortBy, false, statusFilter);
  };

  handlePreviousPage = () => {
    const { sortBy, ascending, currentPage, statusFilter } = this.state;
    const newPage = currentPage - 1;
    this.setState({ currentPage: newPage, allSeries: [] });
    this.getSeriesData(ascending, newPage, sortBy, false, statusFilter);
  };

  handlePageSelect = (page) => {
    const { sortBy, ascending, statusFilter } = this.state;
    this.setState({ currentPage: page, allSeries: [] });
    this.getSeriesData(ascending, page, sortBy, false, statusFilter);
  };
  render() {
    const {
      allSeries,
      currentPage,
      totalPages,
      statusFilter,
      loading,
      totalSeries,
      totalNew,
      totalQC,
      totalRFP,
      totalProd,
    } = this.state;

    const statusCounts = [
      {
        label: 'Total Series',
        count: totalSeries,
      },
      {
        label: 'Newly Added',
        count: totalNew,
      },
      {
        label: 'In Quality Control',
        count: totalQC,
      },
      {
        label: 'Ready for Production',
        count: totalRFP,
      },
      {
        label: 'In Production',
        count: totalProd,
      },
    ];
    return (
      <div>
        <main className="all-series">
          <h2 className="all-series__title">Series</h2>
          <Link to="add-series" className="all-series__add">
            Add Series
          </Link>
          <SearchBar
            featureType="Series"
            statusFilter={statusFilter}
            onFilterSelect={this.handleFilterChange}
            onSearch={this.handleSearch}
            showShadow={false}
            statusCounts={statusCounts}
          />
          <StatusCounter statusCounterData={statusCounts} />
          <SeriesTable seriesData={allSeries} loading={loading} />
          {totalPages > 1 && (
            <KitePagination
              totalPages={totalPages}
              currentPage={currentPage}
              onNextPage={this.handleNextPage}
              onPrevPage={this.handlePreviousPage}
              onPageSelect={this.handlePageSelect}
            />
          )}
        </main>
      </div>
    );
  }
}

export default AllSeries;
