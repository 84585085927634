import { KiteAlert, KiteButton } from '@kite/react-kite';
import React, { Component } from 'react';
import { RouterComponentProps, Prompt } from 'react-router-dom';

import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';
import {
  deleteSwimlane,
  getSwimlane,
  updateSwimlane,
} from '../../apiCalls/SwimlaneService';
import {
  SwimlaneCollection,
  SwimlaneDrawer,
  SwimlaneReadyModal,
  SwimlaneScheduleModal,
  SwimlaneStatus,
  SwimlaneTiles,
  SwimlaneUnsavedChanges,
} from '../../components';
import SwimlanePublishedModal from '../../components/SwimlanePublishedModal';
import { sortPriority } from '../../utils/helpers';
import { ISwimlane } from '../../utils/models';
import './Swimlane.scss';

interface ISwimlanePageState {
  analytics: any;
  swimlaneData: ISwimlane | null;
  localSwimlaneData: ISwimlane | null;
  unsavedChanges: boolean;
  loadingButton:
    | ''
    | 'save'
    | 'delete'
    | 'itemDelete'
    | 'itemAdd'
    | 'statusRemove'
    | 'saveChanges';
  loadingId: string;
  showEditDrawer: boolean;
  showModal: '' | 'dates' | 'ready' | 'published';
  showAlert: string;
}

class Swimlane extends Component<RouterComponentProps, ISwimlanePageState> {
  state: ISwimlanePageState = {
    analytics: null,
    swimlaneData: null,
    loadingButton: '',
    loadingId: '',
    showEditDrawer: false,
    showModal: '',
    localSwimlaneData: null,
    unsavedChanges: false,
    showAlert: '',
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    const [analytics] = this.context;
    this.setState({ analytics });
    this.refreshSwimlaneData(true);
  }

  componentDidUpdate = () => {
    if (this.state.unsavedChanges) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  };

  refreshSwimlaneData = (trackPageView?) => {
    const {
      match: {
        params: { swimlane_id },
      },
    } = this.props;

    return getSwimlane(swimlane_id).then((swimlaneData) => {
      this.setState({
        swimlaneData,
        localSwimlaneData: swimlaneData,
        loadingButton: '',
        loadingId: '',
        unsavedChanges: false,
      });
      if (trackPageView) {
        this.state.analytics.trackPageView(`Swimlane - ${swimlaneData.name}`);
      }
    });
  };

  handleEditSave = async (newName) => {
    this.setState({ loadingButton: 'save' });
    const { swimlaneData } = this.state;

    if (swimlaneData) {
      await updateSwimlane(swimlaneData.id, { name: newName });
      this.refreshSwimlaneData();
      this.setState({ showEditDrawer: false, loadingButton: '' });
    }
  };

  handleDelete = async () => {
    const { swimlaneData } = this.state;
    this.setState({ loadingButton: 'delete' });

    if (swimlaneData) {
      const { id } = swimlaneData;
      await deleteSwimlane(id);
      this.props.history.push('/swimlanes');
    }
  };

  handleStatusClick = async (action) => {
    const { swimlaneData } = this.state;
    if (action === 'remove' || action === 'notReady') {
      this.setState({ loadingButton: 'statusRemove' });
      await updateSwimlane(swimlaneData?.id, {
        status: 'NotReady',
        startTime: null,
        endTime: null,
      });
      this.refreshSwimlaneData();
    } else if (action === 'schedule') {
      this.setState({ showModal: 'dates' });
    } else if (action === 'ready') {
      this.setState({ showModal: 'ready' });
    }
  };

  handleItemAdd = async (id) => {
    const { swimlaneData, localSwimlaneData, unsavedChanges } = this.state;
    this.setState({ loadingButton: 'itemAdd', loadingId: id });
    if (swimlaneData && localSwimlaneData) {
      const localData = unsavedChanges ? localSwimlaneData : swimlaneData;
      const newItems = localData.items
        .map((item) => ({
          ...item,
          priorityNumber: item.itemId === id ? 100 : item.priorityNumber,
        }))
        .sort(sortPriority())
        .map((item, i) => ({
          ...item,
          priorityNumber: item.priorityNumber ? i + 1 : null,
        }));

      this.handleSwimlaneChanges(newItems);
    }
  };

  handleItemDelete = async (id) => {
    const { swimlaneData, localSwimlaneData, unsavedChanges } = this.state;
    this.setState({ loadingButton: 'itemDelete', loadingId: id });
    if (swimlaneData && localSwimlaneData) {
      const localData = unsavedChanges ? localSwimlaneData : swimlaneData;
      const newItems = localData.items
        .filter((i) => i.itemId !== id)
        .map((item, i) => ({
          ...item,
          priorityNumber: item.priorityNumber ? i + 1 : null,
        }));

      this.handleSwimlaneChanges(newItems);
    }
  };

  handleSchedule = async (start, end) => {
    const { swimlaneData } = this.state;
    if (swimlaneData) {
      const { id } = swimlaneData;
      const fields = {
        startTime: start,
        endTime: end,
        status: 'Published',
      };
      await updateSwimlane(id, fields);
      this.refreshSwimlaneData();
    }
  };

  handleReadyClick = async (end?) => {
    const { swimlaneData } = this.state;

    const fields: any = {
      status: 'Ready',
    };

    if (end) {
      fields.endTime = end;
    }

    await updateSwimlane(swimlaneData?.id, fields);
    this.refreshSwimlaneData();
  };

  handleTileRemove = async (targetId) => {
    const { swimlaneData, localSwimlaneData, unsavedChanges } = this.state;
    if (swimlaneData && localSwimlaneData) {
      const localData = unsavedChanges ? localSwimlaneData : swimlaneData;
      const newItems = localData.items
        .map((item) =>
          item.itemId === targetId ? { ...item, priorityNumber: null } : item
        )
        .sort(sortPriority())
        .map((item, i) => ({
          ...item,
          priorityNumber: item.priorityNumber ? i + 1 : null,
        }));

      this.handleSwimlaneChanges(newItems);
    }
  };

  handleTileMove = async (targetId, action) => {
    const { swimlaneData, localSwimlaneData, unsavedChanges } = this.state;
    if (swimlaneData && localSwimlaneData) {
      const localData = unsavedChanges ? localSwimlaneData : swimlaneData;
      const { items } = localData;
      const newItems = localData.items
        .map((item) => {
          if (item.itemId === targetId) {
            let newPriorityNumber;
            if (action === 'top') {
              newPriorityNumber = 0.5;
            } else if (action === 'bottom') {
              newPriorityNumber = items.length + 10;
            } else if (action === 'up') {
              newPriorityNumber = item.priorityNumber
                ? item.priorityNumber - 1.5
                : null;
            } else if (action === 'down') {
              newPriorityNumber = item.priorityNumber
                ? item.priorityNumber + 1.5
                : null;
            }
            return { ...item, priorityNumber: newPriorityNumber };
          } else {
            return item;
          }
        })
        .sort(sortPriority())
        .map((item, i) => ({
          ...item,
          priorityNumber: item.priorityNumber ? i + 1 : null,
        }));

      this.handleSwimlaneChanges(newItems);
    }
  };

  handleChangeSortOrder = async (action) => {
    const { swimlaneData, localSwimlaneData, unsavedChanges } = this.state;
    if (swimlaneData && localSwimlaneData) {
      const localData = unsavedChanges ? localSwimlaneData : swimlaneData;
      const newItems = localData.items
        .sort((a, b) => {
          if (action === 'Alphabetical') {
            return a.data.title - b.data.title;
          } else if (action === 'Date Added') {
            return +new Date(b.data.createdAt) - +new Date(a.data.createdAt);
          } else {
            return 0;
          }
        })
        .map((item, i) => ({
          ...item,
          priorityNumber: item.priorityNumber ? i + 1 : null,
        }));

      this.handleSwimlaneChanges(newItems);
    }
  };

  handleSwimlaneChanges = async (newItems, statusBypass?) => {
    const { swimlaneData, localSwimlaneData } = this.state;
    if (
      swimlaneData &&
      localSwimlaneData &&
      swimlaneData.status === 'Published' &&
      !statusBypass
    ) {
      const newLocalData = { ...localSwimlaneData, items: newItems };
      this.setState({ localSwimlaneData: newLocalData, unsavedChanges: true });
    } else if (swimlaneData && localSwimlaneData && statusBypass) {
      await updateSwimlane(swimlaneData.id, { items: localSwimlaneData.items });
      await this.refreshSwimlaneData();
    } else if (swimlaneData) {
      await updateSwimlane(swimlaneData.id, { items: newItems });
      this.refreshSwimlaneData();
    }
  };

  handleSaveChanges = async () => {
    this.setState({ loadingButton: 'saveChanges' });
    await this.handleSwimlaneChanges(null, true);
    this.setState({ showAlert: 'confirm - page' });
  };

  handleEditDetailsClick = () => {
    const { swimlaneData } = this.state;
    if (swimlaneData) {
      const { startTime, status } = swimlaneData;
      const isScheduled = startTime && new Date(startTime) > new Date();

      if (status === 'Published' && !isScheduled) {
        this.setState({ showModal: 'published' });
      } else {
        this.setState({ showEditDrawer: true });
      }
    }
  };

  render() {
    const {
      swimlaneData,
      showEditDrawer,
      loadingButton,
      loadingId,
      showModal,
      localSwimlaneData,
      unsavedChanges,
      showAlert,
    } = this.state;

    let displayPage;
    const displayData = unsavedChanges ? localSwimlaneData : swimlaneData;

    if (displayData) {
      const { name, status } = displayData;

      displayPage = (
        <>
          <main>
            <div className="swimlane__subheader">
              <div className="swimlane__subheader-content">
                <div className="swimlane__subheader-right">
                  <h1>{name}</h1>
                  <KiteButton
                    className="swimlane__subheader-btn"
                    type="standalone-link"
                    leftIcon="edit-f"
                    size="small"
                    onClick={this.handleEditDetailsClick}
                  >
                    Edit Details
                  </KiteButton>
                </div>

                <p className="swimlane__subheader-status">
                  <span className="swimlane__subheader-status--bold">
                    Swimlane Status:
                  </span>{' '}
                  {status}
                </p>
              </div>
            </div>
            {showAlert === 'confirm - page' && (
              <KiteAlert
                className="swimlane__alert"
                description="Your Swimlane changes have been saved!"
                level="global"
                onClose={() => this.setState({ showAlert: '' })}
                type="confirm"
              />
            )}
            <div className="swimlane__content">
              <SwimlaneStatus
                swimlaneData={displayData}
                onEditClick={() => this.setState({ showModal: 'dates' })}
                onCtaClick={this.handleStatusClick}
                loadingButton={loadingButton}
              />
              {unsavedChanges && (
                <SwimlaneUnsavedChanges
                  swimlaneData={displayData}
                  onSave={this.handleSaveChanges}
                  loadingButton={loadingButton}
                />
              )}
              <div className="swimlane__sub-content">
                <SwimlaneCollection
                  swimlaneData={displayData}
                  onAdd={this.handleItemAdd}
                  onDelete={this.handleItemDelete}
                  loadingId={loadingId}
                  loadingButton={loadingButton}
                />
                <SwimlaneTiles
                  swimlaneData={displayData}
                  onMove={this.handleTileMove}
                  onRemove={this.handleTileRemove}
                  onSortChange={this.handleChangeSortOrder}
                />
              </div>
            </div>
          </main>
          <SwimlaneDrawer
            isOpen={showEditDrawer}
            swimlaneName={displayData.name}
            onClose={() => this.setState({ showEditDrawer: false })}
            onDelete={this.handleDelete}
            onSave={this.handleEditSave}
            loadingButton={loadingButton}
          />
          <SwimlaneScheduleModal
            isOpen={showModal === 'dates'}
            swimlaneData={displayData}
            onHide={() => this.setState({ showModal: '' })}
            onSchedule={this.handleSchedule}
          />
          <SwimlaneReadyModal
            isOpen={showModal === 'ready'}
            swimlaneData={displayData}
            onHide={() => this.setState({ showModal: '' })}
            onReady={this.handleReadyClick}
          />
          <SwimlanePublishedModal
            isOpen={showModal === 'published'}
            onHide={() => this.setState({ showModal: '' })}
            onClick={() => this.setState({ showModal: '' })}
            swimlaneData={displayData}
            action="prevent"
          />
          <Prompt
            when={unsavedChanges}
            message="You have unsaved changes. Are you sure you want to leave?"
          />
        </>
      );
    }
    return <div className="swimlane">{displayPage}</div>;
  }
}

export default Swimlane;
