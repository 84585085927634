import React from 'react';
import { KiteModal, KiteButton } from '@kite/react-kite';

import './DeleteCategoryUnable.scss';

export interface IDeleteCategoryUnableProps {
  isOpen: boolean;
  onClose: () => any;
}

const DeleteCategoryUnable = ({
  isOpen,
  onClose,
}: IDeleteCategoryUnableProps) => {
  return (
    <KiteModal
      className="delete-category-unable"
      canShow={isOpen}
      title="Unable to Delete Category"
      disableBackgroundClose
      showCloseButton={false}
    >
      <p className="delete-category-unable__copy">
        You cannot delete a category if it still has titles associated with it.
        Remove all titles from this category to delete it.
      </p>

      <KiteButton
        type="outline"
        className="delete-category-unable__button"
        onClick={onClose}
      >
        Ok
      </KiteButton>
    </KiteModal>
  );
};

export default DeleteCategoryUnable;
