import axios, { AxiosRequestConfig } from 'axios';
import fileDownload from 'js-file-download';

export const uploadFile = async (file, folder) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('folder', folder);

  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    data: formData,
  };

  try {
    const res = await axios('/api/v1/bucket/upload', requestOptions);
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const downloadFile = async (fileName, prefix = '') => {
  try {
    const res = await axios({
      url: `/api/v1/bucket/download/decrypt?fileName=${fileName}`,
      method: 'GET',
      responseType: 'blob',
    });
    fileDownload(res.data, fileName);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getFolderFiles = async (fileName) => {
  const splitFile = fileName.split('/');
  splitFile.pop();
  const folderKey = splitFile.join('/');
  try {
    const res = await axios(`/api/v1/bucket/files?folderKey=${folderKey}`);
    const fileNames = res.data.map((file) => file.key);
    return fileNames;
  } catch (error) {
    console.error(error);
    return null;
  }
};
