import React, { Component } from 'react';
import { RouterComponentProps, Link } from 'react-router-dom';
import * as SearchService from '../../apiCalls/SearchService';
import * as BucketService from '../../apiCalls/BucketService';
import * as ContentService from '../../apiCalls/ContentService';
import { getAssetData, updateAsset } from '../../apiCalls/AssetsService';
import {
  createEpisode,
  getEpisode,
  updateEpisode,
} from '../../apiCalls/EpisodesService';
import {
  getAllSeries,
  createSeries,
  updateSeries,
} from '../../apiCalls/SeriesService';

import {
  SearchBar,
  EpisodeResult,
  Metadata,
  CountriesCard,
  FeatureServices,
  AddDrawer,
  ServerError,
  AssetCards,
  FilesCard,
  AddSeriesModal,
  SeriesData,
  CategoriesCard,
  DuplicateServiceModal,
  RemoveFeatureUpdateModal,
  NetworkCard,
  ExpirationCard,
  ExpirationDateModal,
} from '../../components';
import {
  KiteIcon,
  KiteCard,
  KiteButton,
  KiteLoader,
  KiteInput,
} from '@kite/react-kite';

import { IEpisodeResult } from '../../components/EpisodeResult/EpisodeResult';
import {
  INewEpAsset,
  IFile,
  IService,
  INewFile,
  ICategory,
} from '../../utils/models';
import './AddEpisode.scss';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';
import dayjs from 'dayjs';

export interface IAddEpisodeState {
  results: IEpisodeResult[];
  suggestedResult: IEpisodeResult | null;
  currentStep: number;
  selectedEpisode: IEpisodeResult | null;
  files: any[];
  services: any[];
  serverError: boolean;
  loading: boolean;
  assetData: INewEpAsset | null;
  sortedFiles: any;
  formattedFiles: any;
  addDrawer: '' | 'Service' | 'File';
  selectedFile: IService | INewFile | null;
  showAddSeriesModal: boolean;
  selectedCategories: ICategory[];
  primaryCategory: ICategory | null;
  isNewSeason: boolean;
  filter: { title: string; season: string; episode: string };
  filteredResults: IEpisodeResult[];
  loadingAdd: boolean;
  showModal: 'duplicate' | 'remove' | 'expiration date' | '';
  duplicateFiles: IService[];
  network: any;
  endTime: string | null;
  customEndTime: string | null;
  useCmsExpiration: boolean;
}

class AddEpisode extends Component<RouterComponentProps, IAddEpisodeState> {
  state: IAddEpisodeState = {
    results: [],
    suggestedResult: null,
    currentStep: 0,
    selectedEpisode: null,
    files: [],
    services: [],
    serverError: false,
    loading: false,
    assetData: null,
    sortedFiles: {},
    formattedFiles: {},
    addDrawer: '',
    selectedFile: null,
    showAddSeriesModal: false,
    selectedCategories: [],
    primaryCategory: null,
    isNewSeason: false,
    filter: {
      title: '',
      season: '',
      episode: '',
    },
    filteredResults: [],
    loadingAdd: false,
    showModal: '',
    duplicateFiles: [],
    network: null,
    endTime: null,
    customEndTime: null,
    useCmsExpiration: true,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    const [analytics] = this.context;
    const {
      match: {
        params: { asset_id },
      },
    } = this.props;

    if (asset_id) {
      analytics.trackPageView('Add Episode - Automated');

      getAssetData(asset_id, true).then((assetData) => {
        if (assetData.tmsId && assetData.episode) {
          this.handleUpdatedAssets(assetData);
        } else {
          this.handleNewAssets(assetData);
        }
      });
    } else {
      analytics.trackPageView('Add Episode - Manual');
      this.setState({ currentStep: 1 });
    }
  }

  handleNewAssets = (assetData) => {
    const { seriesTitle, assetFiles, episodeNumber } = assetData;
    this.handleSearch(seriesTitle, episodeNumber);
    const sortedFiles = assetFiles ? this.generateSortedFiles(assetFiles) : {};
    this.setState({
      assetData,
      sortedFiles,
      currentStep: 1,
    });
  };

  handleUpdatedAssets = async (assetData) => {
    const { assetFiles, episode } = assetData;

    const episodeData = await getEpisode(episode.id);
    const newFiles = assetFiles.filter(
      (file) =>
        !episodeData.files.find((fpf) => fpf.file === file) &&
        !episodeData.services.find((fpf) => fpf.file === file)
    );
    const sortedFiles = await this.generateSortedFiles(newFiles);

    this.setState({
      assetData,
      selectedEpisode: {
        ...episodeData,
        type: 'existing',
        seriesType: 'existing',
        episodeTmsid: episodeData.tmsId,
        series: episodeData.seriesTitle,
        episode: episodeData.number,
      },
      currentStep: 2,
      sortedFiles,
    });
  };

  generateSortedFiles = (files) => {
    return files.reduce(
      (acc, file: string) => {
        const ext = file.split('.').pop();
        if (ext === 'actisync') {
          acc.fp[file] = { language: '' };
        } else if (ext === 'scc') {
          return acc;
        } else {
          acc.service[file] = { language: '', serviceType: '' };
        }
        return acc;
      },
      { fp: {}, service: {} }
    );
  };

  handleSearch = async (query, episodeNum?) => {
    this.setState({ loading: true, suggestedResult: null });
    const data = await SearchService.searchDS(query, ['episode']);
    if (episodeNum) {
      const episode = episodeNum.slice(-2);
      const season = episodeNum.split(episode)[0];
      const suggestedResult =
        data.new.find(
          (ep) => ep.season === +season && ep.episode === +episode
        ) || null;
      this.setState({ suggestedResult });
    }
    this.setState({ results: [...data.new], loading: false });
  };

  handleAddClick = async (id) => {
    const { results, assetData } = this.state;
    const targetEpisode = results.find((ep) => ep.episodeTmsid === id);
    const requestConfig = {
      order: 'asc',
      page: 0,
      sortBy: 'title',
      tmsId: targetEpisode?.seriesTmsid,
    };
    const { totalSeries, allSeries } = await getAllSeries(requestConfig);

    const targetSeries = allSeries[0];

    let nextStep, showAddSeriesModal, isNewSeason;

    if (targetEpisode && targetSeries) {
      nextStep = assetData ? 2 : 3;
      showAddSeriesModal = false;
      targetEpisode.seriesType = 'existing';
      targetEpisode.seriesId = targetSeries.id;
      const seasonNumbers: number[] = targetSeries.seasons.map(
        (season) => season[0].season
      );
      isNewSeason = !seasonNumbers.includes(targetEpisode?.season);
      window.scrollTo(0, 0);
    } else if (targetEpisode) {
      nextStep = 1;
      targetEpisode.seriesType = 'new';
      isNewSeason = true;
      showAddSeriesModal = true;
    }

    this.setState({
      selectedEpisode: targetEpisode || null,
      currentStep: nextStep,
      showAddSeriesModal,
      isNewSeason,
    });
  };

  handleFileRemove = (name) => {
    const { files } = this.state;
    const newFiles = files.filter((file) => file.serviceFile.name !== name);
    this.setState({ files: newFiles });
  };

  handleServicesClick = (action, name) => {
    if (action === 'add') {
      this.setState({ addDrawer: 'Service' });
    } else if (action === 'remove') {
      const { services } = this.state;
      const newServices = services.filter(
        (file) => file.serviceFile.name !== name
      );
      this.setState({ services: newServices });
    }
  };

  handleDrawerClose = () => {
    this.setState({ addDrawer: '', selectedFile: null });
  };

  handleFileSubmit = (action, newFile) => {
    const { services, files, addDrawer } = this.state;

    if (addDrawer === 'File') {
      if (action === 'Add') {
        this.setState({ files: [newFile, ...files] });
      } else {
        const updatedFiles = [
          ...files.filter(
            (file) => file.serviceFile.name !== newFile.serviceFile.name
          ),
          newFile,
        ];
        this.setState({ files: updatedFiles });
      }
    } else {
      this.setState({ services: [newFile, ...services] });
    }
  };

  handleFileDelete = (name) => {
    const { services, files, addDrawer } = this.state;

    if (addDrawer === 'File') {
      const newFiles = files.filter((file) => file.serviceFile.name !== name);
      this.setState({ files: newFiles });
    } else {
      const newServices = services.filter((file) => file.name !== name);
      this.setState({ services: newServices });
    }
  };

  handleBackClick = () => {
    const { currentStep, assetData } = this.state;
    const resetFiles = assetData
      ? this.generateSortedFiles(assetData.assetFiles)
      : {};
    this.setState({
      currentStep: currentStep - 1,
      selectedEpisode: null,
      files: [],
      sortedFiles: resetFiles,
    });

    window.scrollTo(0, 0);
  };

  handleAddEpisode = async () => {
    this.setState({ loadingAdd: true });
    const { assetData } = this.state;

    if (assetData) {
      this.addAutomatedEpisode();
    } else {
      this.addNewEpisode();
    }
  };

  addAutomatedEpisode = async () => {
    const {
      assetData,
      selectedEpisode,
      formattedFiles,
      selectedCategories,
      primaryCategory,
      isNewSeason,
      network,
      useCmsExpiration,
      endTime,
      customEndTime,
    } = this.state;
    const {
      episodeTmsid,
      title,
      seriesType,
      series,
      seriesTmsid,
      episode,
      season,
      seriesId: existingSeriesId,
    } = selectedEpisode || {};

    let seriesId;

    if (seriesType === 'new' || !existingSeriesId) {
      const seriesData = {
        categories: selectedCategories.map((cat) => cat.id),
        countries: ['United States'],
        primaryCategory: primaryCategory?.id,
        tmsId: seriesTmsid,
        title: series,
        network,
      };
      const data = await createSeries(seriesData);
      seriesId = data.id;
    } else {
      seriesId = existingSeriesId;
    }

    const episodeData = {
      fpFiles: formattedFiles.fp.map((file) => ({
        file: file.file,
        language: file.language,
      })),
      tmsId: episodeTmsid,
      title,
      episodeNo: episode,
      seasonNo: season,
      seriesId: seriesId,
      useCmsExpiration,
      endTime,
      customEndTime,
    };

    const episodeResponse = await createEpisode(episodeData);

    if (!episodeResponse) {
      this.setState({ serverError: true });
      return null;
    }

    const servicePromises = formattedFiles.service.map((service) => {
      const { file, language, type } = service;
      return ContentService.createContent(
        file,
        language,
        type,
        episodeTmsid,
        'New'
      );
    });
    const serviceResponses = await Promise.all(servicePromises);

    if (serviceResponses.every((res) => !res)) {
      this.setState({ serverError: true });
      return null;
    }

    const assetResponse = await updateAsset(assetData?.id, {
      tmsId: episodeTmsid,
      newAsset: false,
    });

    if (!assetResponse) {
      this.setState({ serverError: true });
      return null;
    }

    if (isNewSeason && season) {
      const updateSeriesResponse = await updateSeries(seriesId, {
        seasons: [season],
      });
      if (!updateSeriesResponse) {
        this.setState({ serverError: true });
        return null;
      }
    }

    this.props.history.push(
      `/series/${seriesId}/episodes/${episodeResponse.id}`
    );
  };

  addNewEpisode = async () => {
    const { services, files, selectedEpisode } = this.state;

    const { episodeTmsid, title } = selectedEpisode || {};
    const filePromises = [
      ...files.map((file) =>
        BucketService.uploadFile(file.serviceFile, `/assets/${episodeTmsid}`)
      ),
      ...services.map((service) =>
        BucketService.uploadFile(service.serviceFile, `/assets/${episodeTmsid}`)
      ),
    ];
    const uploadResponses = await Promise.all(filePromises);

    if (uploadResponses.every((res) => !res)) {
      this.setState({ serverError: true });
      return null;
    }

    const episodeData = {
      countries: ['United States'],
      fpFiles: files.map((file) => ({
        file: `/assets/${episodeTmsid}/${file.serviceFile.name}`,
        language: file.serviceLanguage,
      })),
      episodeTmsid,
      title,
    };

    const episodeResponse = await createEpisode(episodeData);

    if (!episodeResponse) {
      this.setState({ serverError: true });
      return null;
    }

    const servicePromises = services.map((service) => {
      const {
        serviceFile: file,
        serviceType: type,
        serviceLanguage: language,
      } = service;
      const filePath = `/assets/${episodeTmsid}/${file.name}`;
      return ContentService.createContent(
        filePath,
        language,
        type,
        episodeTmsid,
        'New'
      );
    });
    const serviceResponses = await Promise.all(servicePromises);

    if (serviceResponses.every((res) => !res)) {
      this.setState({ serverError: true });
      return null;
    }

    this.props.history.push(`/episodes/${episodeResponse.id}`);
  };

  handleFileSelect = (type, file, field, value) => {
    const newFileData = { ...this.state.sortedFiles };
    newFileData[type][file][field] = value;
    newFileData[type][file][`${field}Error`] = false;
    this.setState({ sortedFiles: newFileData });
  };

  handleFileClick = (action, name) => {
    const { files } = this.state;
    if (action === 'add') {
      this.setState({ addDrawer: 'File' });
    } else if (action === 'edit') {
      const targetFile = files.find((file) => file.serviceFile.name === name);
      this.setState({ addDrawer: 'File', selectedFile: targetFile });
    } else if (action === 'remove') {
      this.handleFileRemove(name);
    }
  };

  handleCategorySelect = (categories) => {
    this.setState({ selectedCategories: categories });
  };

  handlePrimaryCategorySelect = (category) => {
    this.setState({ primaryCategory: category });
  };

  handleCategoryWarning = () => {
    console.log('Category Warning');
  };

  handleConfirmDetails = () => {
    const { sortedFiles, selectedEpisode } = this.state;
    const newFiles = { ...sortedFiles };
    const formattedFiles: any = {
      fp: [],
      service: [],
    };
    let hasError = false;

    for (const file in sortedFiles.fp) {
      if (!sortedFiles.fp[file].language) {
        hasError = true;
        newFiles.fp[file].languageError = true;
      } else {
        const formattedFile: IFile = {
          id: file,
          language: sortedFiles.fp[file].language,
          file: file,
        };
        formattedFiles.fp.push(formattedFile);
      }
    }

    for (const file in sortedFiles.service) {
      if (!sortedFiles.service[file].language) {
        hasError = true;
        newFiles.service[file].languageError = true;
      }
      if (!sortedFiles.service[file].serviceType) {
        hasError = true;
        newFiles.service[file].serviceTypeError = true;
      }

      if (!hasError) {
        const formattedFile: IService = {
          id: file,
          type: sortedFiles.service[file].serviceType,
          language: sortedFiles.service[file].language,
          status: 'New',
          created: '',
          updated: '',
          file: file,
        };
        formattedFiles.service.push(formattedFile);
      }
    }

    if (hasError) {
      this.setState({ sortedFiles: newFiles });
    } else if (selectedEpisode && selectedEpisode.type === 'existing') {
      this.setState({ formattedFiles });
      this.handleAddNewAssets(formattedFiles);
    } else {
      this.setState({ currentStep: 3, formattedFiles });
    }
  };

  handleFilterChange = (e) => {
    const { filter, results } = this.state;
    const { name, value } = e.target;
    const newFilters = { ...filter, [name]: value };
    this.setState({ filter: newFilters });
    let filteredResults = [...results];

    if (newFilters.title) {
      filteredResults = filteredResults.filter((ep) =>
        ep.title.toUpperCase().includes(newFilters.title.toUpperCase())
      );
    }

    if (newFilters.season) {
      filteredResults = filteredResults.filter(
        (ep) => ep.season === +newFilters.season
      );
    }

    if (newFilters.episode) {
      filteredResults = filteredResults.filter(
        (ep) => ep.episode === +newFilters.episode
      );
    }

    this.setState({ filteredResults, suggestedResult: null });
  };

  handleClearFilters = () => {
    this.setState({
      filteredResults: [],
      filter: {
        title: '',
        season: '',
        episode: '',
      },
    });
  };

  handleAddNewAssets = async (formattedFiles, duplicatePass?, removePass?) => {
    this.setState({ loadingAdd: true });
    const { selectedEpisode, assetData } = this.state;
    const { episodeTmsid: tmsId, status } = selectedEpisode || {};

    if (selectedEpisode && assetData) {
      const duplicateFiles =
        selectedEpisode.services?.filter((service) =>
          formattedFiles.service.find((file) => file.type === service.type)
        ) || [];

      if (duplicateFiles.length && !duplicatePass) {
        this.setState({ showModal: 'duplicate', duplicateFiles });
      } else if (
        formattedFiles.fp.length &&
        status === 'In Production' &&
        !removePass
      ) {
        this.setState({ showModal: 'remove' });
      } else {
        const servicePromises = formattedFiles.service.map((service) => {
          const { file, language, type } = service;
          return ContentService.createContent(
            file,
            language,
            type,
            tmsId,
            'QualityControl'
          );
        });
        const serviceResponses = await Promise.all(servicePromises);

        if (serviceResponses.length && serviceResponses.every((res) => !res)) {
          this.setState({ serverError: true, loadingAdd: false });
          return null;
        }

        const formattedFP = [
          ...selectedEpisode.files,
          ...formattedFiles.fp,
        ].map(({ file, language }) => ({
          file,
          language,
        }));

        const episodeResponse = await updateEpisode(selectedEpisode?.id, {
          fingerprintFiles: formattedFP,
        });

        if (!episodeResponse) {
          this.setState({ serverError: true, loadingAdd: false });
          return null;
        }

        const assetResponse = await updateAsset(assetData.id, {
          newAsset: false,
        });
        if (!assetResponse) {
          this.setState({ serverError: true, loadingAdd: false });
          return null;
        }
        this.props.history.push(
          `/series/${selectedEpisode.seriesId}/episodes/${selectedEpisode.id}`
        );
      }
    }
  };

  handleDuplicateClick = async (type) => {
    const { selectedEpisode, duplicateFiles, formattedFiles } = this.state;
    if (type === 'replace' && selectedEpisode && selectedEpisode.services) {
      const deleteIds = duplicateFiles.map((file) => {
        const targetService = selectedEpisode.services?.find(
          (ser) => ser.type === file.type
        );

        return targetService ? targetService.id : '';
      });
      const deletePromises = deleteIds.map((id) =>
        ContentService.deleteService(id)
      );
      await Promise.all(deletePromises);
    }
    this.handleAddNewAssets(formattedFiles, true);
  };

  handleRemoveFeature = async () => {
    const { selectedEpisode, formattedFiles } = this.state;
    await updateEpisode(selectedEpisode?.id, {
      status: 'QualityControl',
    });
    this.handleAddNewAssets(formattedFiles, true, true);
  };

  handleNetworkSelect = (network) => {
    this.setState({ network });
  };

  toggleExpDateModal = (bool) => {
    this.setState({ showModal: bool ? 'expiration date' : '' });
  };

  handleChangeExpiration = async (radioValue, dateValue) => {
    const updateFields: any = {};

    if (radioValue === 'none') {
      updateFields.useCmsExpiration = false;
      updateFields.customEndTime = null;
    } else if (radioValue === 'manual') {
      const newDate = +dayjs(dateValue);
      updateFields.useCmsExpiration = false;
      updateFields.customEndTime = newDate;
    } else if (radioValue === 'eCMS') {
      updateFields.useCmsExpiration = true;
    }

    this.setState({ showModal: '', ...updateFields });
  };

  render() {
    const {
      results,
      suggestedResult,
      currentStep,
      selectedEpisode,
      files,
      services,
      serverError,
      loading,
      assetData,
      sortedFiles,
      formattedFiles,
      addDrawer,
      selectedFile,
      showAddSeriesModal,
      selectedCategories,
      primaryCategory,
      filter,
      filteredResults,
      loadingAdd,
      showModal,
      endTime,
      customEndTime,
      useCmsExpiration,
    } = this.state;

    const { seriesTitle, assetFiles, episodeNumber } = assetData || {};

    const isFiltered = !!filter.title || !!filter.season || !!filter.episode;

    const resultsToRender = isFiltered ? filteredResults : results;

    const displayEpisodeResults = resultsToRender.map((episode) => (
      <EpisodeResult
        key={episode.episodeTmsid}
        episode={episode}
        onAdd={this.handleAddClick}
        resultType="add"
      />
    ));

    const displaySuggestedResult = suggestedResult ? (
      <>
        <p className="add-episode__subheader">Suggested</p>
        <EpisodeResult
          key={suggestedResult.episodeTmsid}
          episode={suggestedResult}
          onAdd={this.handleAddClick}
          resultType="add"
        />
        <p className="add-episode__subheader">Other Results</p>
      </>
    ) : null;

    const displayFilters = (
      <div className="add-episode__filters">
        <div className="add-episode__filters-input-wrapper">
          <KiteInput
            className="add-episode__filters--title"
            id="add-episode-title"
            name="title"
            label="Filter by Title"
            value={filter.title}
            onChange={this.handleFilterChange}
          />
          <KiteInput
            type="number"
            id="add-episode-season"
            name="season"
            label="Filter by Season"
            value={filter.season}
            onChange={this.handleFilterChange}
          />
          <KiteInput
            type="number"
            id="add-episode-episode"
            name="episode"
            label="Filter by Number"
            value={filter.episode}
            onChange={this.handleFilterChange}
          />
        </div>
        {isFiltered && (
          <p className="add-episode__filtered-by">
            Filtered by:
            {!!filter.title && (
              <span className="add-episode__filtered-by-field">
                Title:{' '}
                <span className="add-episode__filtered-by-value">
                  {filter.title}
                </span>
              </span>
            )}
            {!!filter.season && (
              <span className="add-episode__filtered-by-field">
                Season:{' '}
                <span className="add-episode__filtered-by-value">
                  {filter.season}
                </span>
              </span>
            )}
            {!!filter.episode && (
              <span className="add-episode__filtered-by-field">
                Number:{' '}
                <span className="add-episode__filtered-by-value">
                  {filter.episode}
                </span>
              </span>
            )}
            <KiteButton
              className="add-episode__clear-filters"
              type="standalone-link"
              size="small"
              onClick={this.handleClearFilters}
            >
              Clear Filters
            </KiteButton>
          </p>
        )}
      </div>
    );

    const steps = {
      0: <KiteLoader />,
      1: (
        <>
          <p className="add-episode__step">
            Step 1:{' '}
            {assetData ? 'Choose a Episode' : 'Search for Episodes to Add'}
          </p>
          <KiteCard className="add-episode__results-card">
            <p className="add-episode__title">Search by Title</p>
            <SearchBar
              featureType="Titles"
              onSearch={this.handleSearch}
              startingQuery={seriesTitle}
              showShadow={!results.length}
            />
            {!!results.length && (
              <>
                <p className="add-episode__title">
                  Found Episodes ({results.length})
                </p>
                {displayFilters}
              </>
            )}
            {suggestedResult && displaySuggestedResult}
            {displayEpisodeResults}
            {!results.length && !loading && (
              <p className="add-episode__no-results">
                Search episodes by title to view available episodes
              </p>
            )}
            {loading && (
              <KiteLoader
                className="add-episode__loader"
                loaderTitle="Loading Episode Metadata"
                secondaryMessage="Searching for titles"
              />
            )}
          </KiteCard>
        </>
      ),
      2: assetData ? (
        <>
          <p className="add-episode__step">
            Step 2: Add and Confirm File Details
          </p>
          {assetFiles && (
            <AssetCards
              files={sortedFiles}
              onSelect={this.handleFileSelect}
              filePrefix="assets/series/"
            />
          )}
          <div className="add-episode__button-wrapper">
            <KiteButton type="outline" onClick={this.handleBackClick}>
              Back
            </KiteButton>
            <KiteButton
              onClick={this.handleConfirmDetails}
              loading={loadingAdd}
            >
              {selectedEpisode && selectedEpisode.type === 'existing'
                ? 'Add New Assets'
                : 'Confirm Details'}
            </KiteButton>
          </div>
        </>
      ) : null,

      3: selectedEpisode ? (
        <>
          <p className="add-episode__step">
            {assetData
              ? 'Step 3: Summary'
              : 'Step 2: Add Preferences and Files'}
          </p>
          <Metadata
            assetType="episode"
            data={selectedEpisode}
            showAlert={false}
            onUpdate={() => {}}
            isNew
          />
          <SeriesData
            title={selectedEpisode.series}
            tmsID={selectedEpisode.seriesTmsid}
            isNew={selectedEpisode.seriesType === 'new'}
          />
          <ExpirationCard
            endTime={endTime}
            customEndTime={customEndTime}
            useCmsExpiration={useCmsExpiration}
            onEdit={() => this.toggleExpDateModal(true)}
          />
          {selectedEpisode.seriesType === 'new' && (
            <CategoriesCard
              status="New"
              isSeries
              selectedCategories={selectedCategories}
              primaryCategory={primaryCategory}
              showMessage={false}
              onSelectCategories={this.handleCategorySelect}
              onSelectPrimary={this.handlePrimaryCategorySelect}
              onTriggerWarning={this.handleCategoryWarning}
            />
          )}
          {selectedEpisode.seriesType === 'new' && (
            <NetworkCard
              status="New"
              showMessage={false}
              network={null}
              onSelect={this.handleNetworkSelect}
            />
          )}
          <CountriesCard countries={['United States']} />
          {assetData ? (
            <FilesCard
              files={formattedFiles.fp}
              status="Add"
              onButtonClick={() => {}}
              showAlert={false}
            />
          ) : (
            <FilesCard
              files={files}
              status="New"
              onButtonClick={this.handleFileClick}
              showAlert={false}
            />
          )}

          <FeatureServices
            services={assetData ? formattedFiles.service : services || []}
            onButtonClick={this.handleServicesClick}
            status="New"
            showAlert={false}
          />
          <div className="add-episode__button-wrapper">
            <KiteButton type="outline" onClick={this.handleBackClick}>
              Back
            </KiteButton>
            <KiteButton onClick={this.handleAddEpisode} loading={loadingAdd}>
              Add Episode
            </KiteButton>
          </div>
        </>
      ) : null,
    };

    let backLink;

    if (assetData) {
      backLink = (
        <Link to="/new-assets" className="add-episode__back-link">
          <KiteIcon name="chevron-left" size="22px" /> Back to New Assets
        </Link>
      );
    } else {
      backLink = (
        <Link to="episodes" className="add-episode__back-link">
          <KiteIcon name="chevron-left" size="22px" /> Back to Episodes
        </Link>
      );
    }

    return (
      <div>
        <main className="add-episode">
          {backLink}
          <h2>
            Add{' '}
            {seriesTitle && episodeNumber
              ? `${seriesTitle} - ${episodeNumber}`
              : 'Episode'}
          </h2>
          {steps[currentStep]}
        </main>
        <AddDrawer
          type={addDrawer}
          isOpen={!!addDrawer}
          fileData={selectedFile}
          onClose={this.handleDrawerClose}
          onSubmit={this.handleFileSubmit}
          onDelete={this.handleFileDelete}
        />
        <ExpirationDateModal
          isOpen={showModal === 'expiration date'}
          onClose={() => this.toggleExpDateModal(false)}
          endTime={endTime}
          customEndTime={customEndTime}
          onSave={this.handleChangeExpiration}
          useCmsExpiration={useCmsExpiration}
        />
        <ServerError
          isOpen={serverError}
          onClose={() => this.setState({ serverError: false })}
        />
        <AddSeriesModal
          isOpen={showAddSeriesModal}
          seriesTitle={selectedEpisode ? selectedEpisode.series : ''}
          onContinue={() => this.setState({ currentStep: assetData ? 2 : 3 })}
          onHide={() => this.setState({ showAddSeriesModal: false })}
        />
        <DuplicateServiceModal
          isOpen={showModal === 'duplicate'}
          onClick={this.handleDuplicateClick}
          onHide={() => this.setState({ showModal: '', loadingAdd: false })}
          services={['Closed Captioning']}
        />
        <RemoveFeatureUpdateModal
          isOpen={showModal === 'remove'}
          onRemove={this.handleRemoveFeature}
          onHide={() => this.setState({ showModal: '', loadingAdd: false })}
        />
      </div>
    );
  }
}

export default AddEpisode;
