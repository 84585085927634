import React from 'react';
import { KiteCard, KiteButton } from '@kite/react-kite';

import './DeleteFeatureCard.scss';

export interface IDeleteFeatureCardProps {
  onDelete: () => any;
  loadingButton: string;
}

const DeleteFeatureCard = ({
  onDelete,
  loadingButton,
}: IDeleteFeatureCardProps) => {
  return (
    <KiteCard className="delete-feature-card">
      <div>
        <h3 className="delete-feature-card__title">Delete Feature</h3>
        <p className="delete-feature-card__copy">
          Delete a feature to permanently remove it from the system.
        </p>
      </div>
      <KiteButton
        className="delete-feature-card__button"
        type="outline"
        onClick={() => onDelete()}
        loading={loadingButton === 'deleteFeature'}
      >
        Delete Feature
      </KiteButton>
    </KiteCard>
  );
};
export default DeleteFeatureCard;
