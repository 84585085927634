import React from 'react';

import { ISwimlane } from '../../utils/models';
import { KiteButton, KiteIcon } from '@kite/react-kite';
import './SwimlaneUnsavedChanges.scss';

export interface ISwimlaneUnsavedChangesProps {
  swimlaneData: ISwimlane;
  onSave: () => void;
  loadingButton: string;
}

const SwimlaneUnsavedChanges = ({
  swimlaneData,
  onSave,
  loadingButton,
}: ISwimlaneUnsavedChangesProps) => {
  const { startTime } = swimlaneData;

  const isScheduled = startTime && new Date(startTime) > new Date();

  const localStatus = isScheduled ? 'Scheduled' : 'Deployed';

  return (
    <div className="swimlane-unsaved-changes">
      <h2>
        <KiteIcon
          name="caution-circle-f"
          color="#d6312b"
          size="24px"
          margin="0 16px 0 0"
        />
        Updates to {localStatus} Swimlane{' '}
      </h2>
      <p className="swimlane-unsaved-changes__copy">
        You have made changes to a {localStatus} swimlane. Save changes to
        update the production app.
      </p>
      <KiteButton
        className="swimlane-unsaved-changes__btn"
        onClick={onSave}
        loading={loadingButton === 'saveChanges'}
      >
        Save Changes
      </KiteButton>
    </div>
  );
};

export default SwimlaneUnsavedChanges;
