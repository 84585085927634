import React from 'react';

import './RemoveFeatureUpdateModal.scss';
import { KiteModal } from '@kite/react-kite';

export interface IRemoveFeatureUpdateModalProps {
  isOpen: boolean;
  onRemove: () => void;
  onHide: () => any;
}

const RemoveFeatureUpdateModal = ({
  isOpen,
  onRemove,
  onHide,
}: IRemoveFeatureUpdateModalProps) => {
  const handleClick = () => {
    onRemove();
    onHide();
  };
  return (
    <KiteModal
      className="remove-feature-update"
      canShow={isOpen}
      title="Remove Feature from Production"
      disableBackgroundClose
      showCloseButton={false}
      ctaAction={handleClick}
      ctaCopy="Remove"
      secondaryCtaAction={onHide}
      secondaryCtaCopy="Cancel"
    >
      <p>
        This update includes fingerprint files. The feature must be removed from
        production until the changes have gone through quality control
      </p>
    </KiteModal>
  );
};

export default RemoveFeatureUpdateModal;
