import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import { KiteCard, KiteAlert } from '@kite/react-kite';
import { BasicPills, AutoSuggest } from '@kite/react-kite-plus';

import { TStatus } from '../../utils/models';
import './NetworkCard.scss';
import networks from '../../utils/networks';

export interface INetworkCardProps {
  status: TStatus;
  network: any;
  onSelect: (network: any) => void;
  showMessage?: boolean;
}

const NetworkCard = ({
  status,
  showMessage,
  network,
  onSelect,
}: INetworkCardProps) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    network && network.displayName && setInputValue(network.displayName);
  }, [network]);

  const handleChange = (e) => setInputValue(e.target?.value);
  const handleSelect = (e, data) => {
    const { displayName, networkImgUrl } = data.suggestion;
    const formattedNetwork = { displayName, networkImgUrl };
    onSelect(formattedNetwork);
  };

  const localNetworks = Object.values(networks);

  return (
    <KiteCard className="network-card">
      <h3 className="network-card__title">
        Network
        <CSSTransition
          classNames="changes-message"
          in={showMessage}
          unmountOnExit
          timeout={400}
        >
          <>
            <KiteAlert
              level="inline"
              type="confirm"
              description="Changes Saved"
            />
          </>
        </CSSTransition>
      </h3>
      {status === 'New' || status === 'Quality Control' ? (
        <AutoSuggest
          label="Network Name"
          value={inputValue}
          name="networkName"
          id="networkName"
          initialData={localNetworks}
          suggestionKey="displayName"
          onChange={handleChange}
          onSuggestionSelected={handleSelect}
          showExpandButton
          placeholder="Search for networks"
        />
      ) : (
        <div className="network-card__categories-container">
          {network ? (
            <BasicPills
              className="network-card__categories"
              values={[network.displayName]}
            />
          ) : (
            <p>Please Remove from Production and add a Network</p>
          )}
        </div>
      )}
    </KiteCard>
  );
};

export default NetworkCard;
