import React, { useState } from 'react';
import { KiteModal, KiteButton, KiteInput } from '@kite/react-kite';

import './CreateCategory.scss';

export interface ICreateCategoryProps {
  isOpen: boolean;
  onCreate: (name: string) => any;
  onHide: () => any;
}

const CreateCategory = ({ isOpen, onHide, onCreate }: ICreateCategoryProps) => {
  const [nameValue, setNameValue] = useState('');
  const [showError, setShowError] = useState(false);

  const handleClose = () => {
    setNameValue('');
    setShowError(false);
    onHide();
  };

  const handleCreate = () => {
    if (nameValue.length) {
      onCreate(nameValue);
      handleClose();
    } else {
      setShowError(true);
    }
  };

  const handleChange = ({ target }) => {
    setNameValue(target.value);
    setShowError(false);
  };

  return (
    <KiteModal
      className="create-category"
      canShow={isOpen}
      title="Create Category"
      disableBackgroundClose
      showCloseButton
      onHide={onHide}
    >
      <KiteInput
        className="remove_feature__input"
        label="Category Name*"
        value={nameValue}
        onChange={handleChange}
        maxWidth="470px"
        errorMessage={showError ? 'A category name is required.' : ''}
      />
      <div className="create-category__button-wrapper">
        <KiteButton
          type="outline"
          className="create-category__button create-category__button--cancel"
          onClick={onHide}
        >
          Cancel
        </KiteButton>
        <KiteButton
          className="create-category__button create-category__button--create"
          onClick={handleCreate}
        >
          Create Category
        </KiteButton>
      </div>
    </KiteModal>
  );
};

export default CreateCategory;
