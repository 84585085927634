import React, { Component } from 'react';
import { RouterComponentProps, Link } from 'react-router-dom';
import { SortableTable } from '@kite/react-kite-plus';

import {
  getCategories,
  getCategoryById,
  updateCategory,
  deleteCategory,
} from '../../apiCalls/CategoryService';
import { renderTableDate } from '../../utils/helpers';
import { KiteButton, KiteIcon } from '@kite/react-kite';
import { ICategory } from '../../utils/models';
import {
  ErrorModal,
  CategoryDrawer,
  DeleteCategoryUnable,
  TableStatus,
} from '../../components';
import './Category.scss';
import { updateMovie } from '../../apiCalls/MoviesService';
import { updateSeries } from '../../apiCalls/SeriesService';

export interface ICategoryState {
  catData: ICategory | null;
  features: any[];
  loading: boolean;
  showDrawer: boolean;
  showDeleteWarning: boolean;
  showCategoryWarning: '' | 'category' | 'primary';
  loadingButtonId: string;
}

class Category extends Component<RouterComponentProps, ICategoryState> {
  state: ICategoryState = {
    catData: null,
    features: [],
    loading: false,
    showDrawer: false,
    showDeleteWarning: false,
    showCategoryWarning: '',
    loadingButtonId: '',
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.updateCatData();
  }

  updateCatData = async () => {
    const {
      match: {
        params: { cat_id },
      },
    } = this.props;
    const allCats = await getCategories();
    const targetCat = allCats.find((cat) => cat.id === cat_id);
    const features = await getCategoryById(cat_id);
    this.setState({
      catData: targetCat,
      features,
      loading: false,
      loadingButtonId: '',
    });
  };

  handleRowClick = (featureData) => {
    const { id, tmsId } = featureData;
    const featureType = tmsId.substring(0, 2) === 'SH' ? 'series' : 'movies';
    this.props.history.push(`/${featureType}/${id}`);
  };

  handleCategoryUpdate = async (name) => {
    const { catData } = this.state;
    await updateCategory(catData?.id, { name });
    this.updateCatData();
  };

  handleDeleteCategory = async () => {
    const { catData, features } = this.state;
    if (features.length) {
      this.setState({ showDeleteWarning: true, showDrawer: false });
    } else {
      await deleteCategory(catData?.id);
      this.props.history.push(`/categories`);
    }
  };

  handleRemoveFromCat = async (e, item) => {
    e.stopPropagation();

    const { id: targetId } = this.state.catData || {};
    const { categories, primaryCategoryId, id: featureId, tmsId } = item;
    const featureType = tmsId.substring(0, 2) === 'SH' ? 'series' : 'movie';

    if (categories.length < 2) {
      this.setState({ showCategoryWarning: 'category' });
    } else if (primaryCategoryId === targetId) {
      this.setState({ showCategoryWarning: 'primary' });
    } else {
      this.setState({ loadingButtonId: featureId });
      const newCategories = categories
        .filter(({ id }) => id !== targetId)
        .map((cat) => cat.id);
      if (featureType === 'movie') {
        await updateMovie(featureId, {
          allCategoryIds: newCategories,
        });
      } else {
        await updateSeries(featureId, {
          allCategoryIds: newCategories,
        });
      }
      this.updateCatData();
    }
  };

  render() {
    const {
      catData,
      features,
      loading,
      showDrawer,
      showDeleteWarning,
      showCategoryWarning,
      loadingButtonId,
    } = this.state;
    const columns = [
      {
        label: 'Title',
        sortKey: 'title',
        size: 2,
      },
      {
        label: 'Added',
        sortKey: 'addedOn',
        render: ({ addedOn }) => renderTableDate(addedOn),
      },
      {
        label: 'Updated',
        sortKey: 'updatedOn',
        render: ({ updatedOn }) => renderTableDate(updatedOn),
      },
      {
        label: 'Feature Status',
        sortKey: 'status',
        render: ({ status, errors }) => (
          <TableStatus status={status} errors={errors} />
        ),
        size: 0.9,
      },
      {
        label: '',
        sortKey: '',
        sortEnabled: false,
        render: (item) => (
          <KiteButton
            type="outline"
            className="category__remove-btn"
            onClick={(e) => this.handleRemoveFromCat(e, item)}
            loading={loadingButtonId === item.id}
          >
            Remove from Category
          </KiteButton>
        ),
        size: 1.2,
      },
      {
        label: '',
        sortKey: '',
        sortEnabled: false,
        render: () => <KiteIcon name="chevron-right" color="#0073d1" />,
        size: 0.1,
      },
    ];

    return (
      <main className="category">
        <Link to="/categories" className="category__back-link">
          <KiteIcon name="chevron-left" size="22px" /> Back to Categories
        </Link>
        <div className="category__button-wrapper">
          <KiteButton
            type="outline"
            onClick={() => this.setState({ showDrawer: true })}
          >
            Edit Category Details
          </KiteButton>
          <KiteButton
            type="outline"
            onClick={() =>
              this.props.history.push(`/categories/${catData?.id}/add`)
            }
          >
            Add Titles
          </KiteButton>
        </div>
        <h2 className="category__title">{catData?.name}</h2>
        <p className="category__copy">
          Categories determine what genres the movies and tv shows fall under
          and groups it with similar titles.
        </p>
        <SortableTable
          className="category__table"
          columns={columns}
          tableData={features}
          initialSortHeader="title"
          loading={loading}
          onRowClick={this.handleRowClick}
        />
        <CategoryDrawer
          isOpen={showDrawer}
          catData={catData}
          onClose={() => this.setState({ showDrawer: false })}
          onSubmit={this.handleCategoryUpdate}
          onDelete={this.handleDeleteCategory}
        />
        <DeleteCategoryUnable
          isOpen={showDeleteWarning}
          onClose={() => this.setState({ showDeleteWarning: false })}
        />
        <ErrorModal
          isOpen={!!showCategoryWarning}
          type={showCategoryWarning}
          onClose={() => this.setState({ showCategoryWarning: '' })}
        />
      </main>
    );
  }
}

export default Category;
