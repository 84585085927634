import React from 'react';
import { KiteCard, KiteButton, KiteAlert } from '@kite/react-kite';

import { IService } from '../../utils/models';
import ServiceCard from '../ServiceCard/ServiceCard';
import { CSSTransition } from 'react-transition-group';
import NewServiceCard from '../NewServiceCard';
import { INewServiceFile } from '../NewServiceCard/NewServiceCard';

import './FeatureServices.scss';

export interface IFeatureServicesProps {
  services: IService[] | INewServiceFile[];
  onButtonClick: (
    action: 'edit' | 'publish' | 'remove' | 'add' | 'publishAll',
    id?: string
  ) => void;
  status: 'New' | 'Quality Control' | 'Ready for Production' | 'In Production';
  showAlert: boolean;
}

const FeatureServices = ({
  services,
  onButtonClick,
  status,
  showAlert,
}: IFeatureServicesProps) => {
  const isNewService = (services: any): services is INewServiceFile[] => {
    if (services.length) {
      return 'serviceFile' in services[0];
    } else {
      return true;
    }
  };

  let displayServiceCards;

  if (isNewService(services)) {
    displayServiceCards = services.map((service) => (
      <NewServiceCard
        key={service.serviceFile.name}
        serviceData={service}
        onRemove={(name) => onButtonClick('remove', name)}
      />
    ));
  } else {
    displayServiceCards = services.map((service) => (
      <ServiceCard
        featureStatus={status}
        key={service.id}
        serviceData={service}
        onButtonClick={onButtonClick}
      />
    ));
  }

  return (
    <KiteCard className="feature-services">
      <div className="feature-services__heading-wrapper">
        <h3 className="feature-services__title">Feature Services</h3>
        <CSSTransition
          classNames="changes-message"
          in={showAlert}
          unmountOnExit
          timeout={400}
        >
          <>
            <KiteAlert
              level="inline"
              type="confirm"
              description="Changes Saved"
            />
          </>
        </CSSTransition>
      </div>

      <div className="feature-services__button-wrapper">
        {status !== 'Ready for Production' &&
          services.some(
            (service) => service.serviceType === 'Ready for Production'
          ) && (
            <KiteButton
              type="outline"
              className="feature-services__button feature-services__button--publish"
              onClick={() => onButtonClick('publishAll')}
            >
              Publish All Services
            </KiteButton>
          )}
        {status !== 'Ready for Production' && (
          <KiteButton
            type="outline"
            className="feature-services__button"
            onClick={() => onButtonClick('add')}
          >
            Add Service
          </KiteButton>
        )}
      </div>
      <div className="feature-services__card-wrapper">
        {displayServiceCards.length ? (
          displayServiceCards
        ) : (
          <p className="feature-services__none">
            Click “Add Service” to upload service files for this feature.
          </p>
        )}
      </div>
    </KiteCard>
  );
};

export default FeatureServices;
