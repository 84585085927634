import React, { useState } from 'react';

import './FilesCard.scss';
import { KiteCard, KiteButton, KiteAlert } from '@kite/react-kite';
import { IFile, INewFile } from '../../utils/models';
import { CSSTransition } from 'react-transition-group';
import { downloadFile } from '../../apiCalls/BucketService';

export interface IFilesCardProps {
  files: IFile[] | INewFile[];
  status:
    | 'New'
    | 'Quality Control'
    | 'Ready for Production'
    | 'In Production'
    | 'Add';
  onButtonClick: (action: 'add' | 'edit' | 'remove', id?: string) => void;
  showAlert: boolean;
}

const FilesCard = ({
  files,
  status,
  onButtonClick,
  showAlert,
}: IFilesCardProps) => {
  const [loadingFile, setLoadingFile] = useState('');
  const handleDownload = async (fileName) => {
    setLoadingFile(fileName);
    await downloadFile(fileName);
    setLoadingFile('');
  };

  const isNewFiles = (files: any): files is INewFile[] => {
    if (files.length) {
      return 'serviceFile' in files[0];
    } else {
      return true;
    }
  };

  const editable = status === 'New' || status === 'Quality Control';

  let fileElements;

  if (isNewFiles(files)) {
    fileElements = files.map((file) => (
      <div className="files-card__file-details" key={file.serviceFile.name}>
        <p className="files-card__file-name">{file.serviceFile.name}</p>
        <div className="files-card__button-wrapper">
          <KiteButton
            className="files-card__edit-button"
            type="standalone-link"
            size="small"
            onClick={() => onButtonClick('edit', file.serviceFile.name)}
          >
            Edit
          </KiteButton>
          <KiteButton
            className="files-card__remove-button"
            type="standalone-link"
            size="small"
            onClick={() => onButtonClick('remove', file.serviceFile.name)}
          >
            Remove
          </KiteButton>
        </div>

        <p className="files-card__file-field">
          <span className="files-card__file-title">Language:</span>
          {file.serviceLanguage === 'en'
            ? 'English'
            : file.serviceLanguage === 'es'
            ? 'Spanish'
            : file.serviceLanguage}
        </p>
        <p className="files-card__file-field">
          <span className="files-card__file-title">File:</span>
          ...{file.serviceFile.name.split('').slice(-25).join('')}
        </p>
      </div>
    ));
  } else {
    fileElements = files.map((file) => (
      <div className="files-card__file-details" key={file.id}>
        <p className="files-card__file-name">
          ...{file.file.split('').slice(-60).join('')}
        </p>
        {editable && (
          <div className="files-card__button-wrapper">
            <KiteButton
              className="files-card__edit-button"
              type="standalone-link"
              onClick={() => onButtonClick('edit', file.id)}
            >
              Edit
            </KiteButton>
          </div>
        )}
        <p className="files-card__file-field">
          <span className="files-card__file-title">Language:</span>
          {file.language === 'en'
            ? 'English'
            : file.language === 'es'
            ? 'Spanish'
            : file.language}
        </p>
        <p className="files-card__file-field">
          <span className="files-card__file-title">File:</span>
          <KiteButton
            type="standalone-link"
            rightIcon="download"
            onClick={() => handleDownload(file.file)}
            loading={file.file === loadingFile}
          >
            {file.file.split('').slice(-70).join('')}
          </KiteButton>
        </p>
      </div>
    ));
  }

  let fileDisplay;

  if (files.length) {
    fileDisplay = fileElements;
  } else if (status === 'Add') {
    fileDisplay = (
      <p className="files-card__none">
        This asset does not have a fingerprint file. One should be added after
        the feature is created.
      </p>
    );
  } else {
    fileDisplay = (
      <p className="files-card__none">
        Click “Add File” to upload fingerprint files for this feature.
      </p>
    );
  }

  return (
    <KiteCard className="files-card">
      <div className="files-card__heading-wrapper">
        <h3 className="files-card__title">Files</h3>
        <CSSTransition
          classNames="changes-message"
          in={showAlert}
          unmountOnExit
          timeout={400}
        >
          <>
            <KiteAlert
              level="inline"
              type="confirm"
              description="Changes Saved"
            />
          </>
        </CSSTransition>
      </div>

      {editable && (
        <KiteButton
          type="outline"
          className="files-card__add-button"
          onClick={() => onButtonClick('add')}
        >
          Add File
        </KiteButton>
      )}
      {fileDisplay}
    </KiteCard>
  );
};

export default FilesCard;
