import * as React from 'react';
import { Drawer } from '@kite/react-kite-plus';
import { KiteButton, KiteInput } from '@kite/react-kite';

import './SwimlaneDrawer.scss';

export interface ISwimlaneDrawerPops {
  swimlaneName: string;
  isOpen: boolean;
  onClose: () => any;
  onSave: (name) => any;
  onDelete: () => void;
  loadingButton: string;
}

export interface ISwimlaneDrawerState {
  inputValue: string;
}

class SwimlaneDrawer extends React.Component<
  ISwimlaneDrawerPops,
  ISwimlaneDrawerState
> {
  state = {
    inputValue: '',
  };

  componentDidMount() {
    this.setState({ inputValue: this.props.swimlaneName });
  }

  componentDidUpdate(prevProps) {
    if (this.props.swimlaneName !== prevProps.swimlaneName) {
      this.setState({ inputValue: this.props.swimlaneName });
    }
  }

  handleClose = () => {
    this.setState({ inputValue: this.props.swimlaneName });
    this.props.onClose();
  };

  handleChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleSave = () => {
    this.props.onSave(this.state.inputValue);
  };

  render() {
    const { isOpen, loadingButton } = this.props;
    const { inputValue } = this.state;

    const deleteCard = (
      <div className="swimlane-drawer__delete-card">
        <p className="swimlane-drawer__delete-title">Delete Swimlane</p>
        <p className="swimlane-drawer__delete-copy">
          Delete a swimlane to permanently remove it from the system.
        </p>
        <KiteButton
          className="swimlane-drawer__delete-button"
          type="outline"
          onClick={this.props.onDelete}
          loading={loadingButton === 'delete'}
        >
          Delete Swimlane
        </KiteButton>
      </div>
    );

    return (
      <Drawer
        isOpen={isOpen}
        disableBackgroundClose
        className="swimlane-drawer"
        onClose={this.handleClose}
      >
        <h2>Edit Swimlane Details</h2>
        <KiteInput
          label="Swimlane Name"
          value={inputValue}
          onChange={this.handleChange}
          id="swimlane-drawer-name-input"
        />
        <KiteButton
          className="swimlane-drawer__submit"
          onClick={() => this.handleSave()}
          loading={loadingButton === 'save'}
        >
          Save
        </KiteButton>
        {deleteCard}
      </Drawer>
    );
  }
}

export default SwimlaneDrawer;
