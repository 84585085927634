import React from 'react';
import { AnalyticsContextProvider } from './analytics/AnalyticsContextProvider';

import './App.css';
import { KeyCloak } from './components';

const App = () => {
  return (
    <AnalyticsContextProvider>
      <div className="App">
        <KeyCloak />
      </div>
    </AnalyticsContextProvider>
  );
};

export default App;
