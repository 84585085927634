import React from 'react';
import { KiteModal, KiteButton, KiteList } from '@kite/react-kite';

import './UnresolvedErrors.scss';

export interface IUnresolvedErrorsProps {
  isOpen: boolean;
  onClose: () => any;
  errors: string[];
}

const UnresolvedErrors = ({
  isOpen,
  onClose,
  errors,
}: IUnresolvedErrorsProps) => {
  return (
    <KiteModal
      className="unresolved-errors"
      canShow={isOpen}
      title="Unresolved Errors"
      disableBackgroundClose
      showCloseButton={false}
    >
      <p className="unresolved-errors__copy">
        Please confirm that you have resolved the following errors before moving
        this feature to Ready for Production.
      </p>
      <KiteList>
        {errors.map((err) => (
          <li>{err}</li>
        ))}
      </KiteList>

      <KiteButton
        type="outline"
        className="unresolved-errors__button"
        onClick={onClose}
      >
        Ok
      </KiteButton>
    </KiteModal>
  );
};

export default UnresolvedErrors;
