const networks = {
  AEHD: {
    displayName: 'A&E',
    description:
      'Find all of your favorite A&E shows, including Live PD, The First 48, Wahlburgers, and more with Spectrum On Demand!',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=AEHD&productId=ENTOD',
    providerId: 'aetv.com',
    seoTitleFormat: 3,
    providerName: 'AEHD',
  },
  ABC_HD: {
    displayName: 'ABC',
    description:
      'Find the latest episodes of your favorite ABC shows, including Grey’s Anatomy, the Bachelor, and more with Spectrum On Demand! ',
    providerId: 'abc.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=ABC_HD&productId=PTOD',
    seoTitleFormat: 3,
    providerName: 'ABC_HD',
  },
  ACORNTV_SVOD: {
    displayName: 'Acorn TV',
    description:
      'Find the latest episodes of your favorite Acorn TV shows with Spectrum On Demand! ',
    providerId: 'acorn.tv',
    seoTitleFormat: 3,
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=ACORNTV_SVOD&productId=ACSS',
    providerName: 'ACORNTV_SVOD',
  },
  'Adult Swim': {
    displayName: 'Adult Swim',
    description:
      'Find all of your favorite Adult Swim shows, including American Dad, The Eric Andre Show, and more with Spectrum On Demand!',
    providerId: 'adultswim.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=Adult Swim&productId=CEOD',
    seoTitleFormat: 3,
    providerName: 'ADULTSWIM_HD',
  },
  AMBIENT_HD: {
    displayName: 'Ambient.tv',
    description:
      'Find the latest episodes of your favorite Ambient Channel shows with Spectrum On Demand!',
    providerId: 'tvn.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=AMBIENT_HD&productId=LSOD',
    seoTitleFormat: 3,
    providerName: 'AMBIENT_HD',
  },
  AMC_HD: {
    displayName: 'AMC',
    description:
      'Find your favorite AMC shows, including Dispatches from Elsewhere and more with Spectrum On Demand!',
    providerId: 'amctv.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=AMC_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'AMC_HD',
  },
  AMCPREMIERE_HD_MEZZ: {
    displayName: 'AMC Premiere',
    description:
      'Get early access to your favorite hit AMC Premiere shows and exclusives with Spectrum On Demand!',
    providerId: 'amcpremiere.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=AMCPREMIERE_HD_MEZZ&productId=APSS',
    seoTitleFormat: 3,
    providerName: 'AMCPREMIERE_HD_MEZZ',
  },
  AMERICAN_HEROES_HD: {
    displayName: 'American Heroes',
    description:
      'Find all of your favorite American Heroes Channel (AHC) shows, including America: Facts vs Fiction, What History Forgot, and more with Spectrum On Demand!',
    providerId: 'americanheroes.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=AMERICAN_HEROES_HD&productId=NWOD',
    seoTitleFormat: 3,
    providerName: 'AMERICAN_HEROES_HD',
  },
  Animal_Planet_HD: {
    displayName: 'Animal Planet',
    description:
      'Find your favorite Animal Planet shows, including Pit Bulls & Parolees, Treehouse Masters, and more with Spectrum On Demand!',
    providerId: 'animalplanet.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=Animal_Planet_HD&productId=NWOD',
    seoTitleFormat: 3,
    providerName: 'Animal_Planet_HD',
  },
  'Anime Network': {
    displayName: 'Anime Network',
    description:
      'Find the latest episodes of your favorite Anime Network shows with Spectrum On Demand! ',
    providerId: 'theanimenetwork.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=Anime Network&productId=CEOD',
    seoTitleFormat: 3,
    providerName: '%22Anime%20Network%22',
  },
  BANDAMAX: {
    displayName: 'Bandamax',
    description:
      'Find the latest episodes of your favorite Bandamax shows with Spectrum On Demand!',
    providerId: 'univision.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=LMOD&providerId=BANDAMAX',
    seoTitleFormat: 3,
    providerName: 'BANDAMAX',
  },
  BBCHD: {
    displayName: 'BBC America',
    description:
      'Find your favorite BBC America series, including Top Gear, Killing Eve, and more with Spectrum On Demand!',
    providerId: 'bbcamerica.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=BBCHD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'BBCHD',
  },
  BBCWORLDNEWS_HD: {
    displayName: 'BBC World News',
    description: 'Find the latest BBC World News with Spectrum On Demand!',
    providerId: 'bbcamerica.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=NWOD&providerId=BBCWORLDNEWS_HD',
    seoTitleFormat: 2,
    providerName: 'BBCWORLDNEWS_HD',
  },
  BEIN_SPORTS: {
    displayName: 'beIN Sports',
    description:
      'Access all of the action beIN Sports has to offer with Spectrum On Demand! ',
    providerId: 'beinsport.tv',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=BEIN_SPORTS&productId=SPOD',
    seoTitleFormat: 1,
    providerName: 'BEIN_SPORTS',
  },
  BEIN_SPORTS_ESP: {
    displayName: 'beIN Sports Español',
    description:
      'Access all  of the action beIN Sports Español has to offer with Spectrum On Demand!',
    providerId: 'beinsport.tv',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=LMOD&providerId=BEIN_SPORTS_ESP',
    seoTitleFormat: 1,
    providerName: 'BEIN_SPORTS_ESP',
  },
  BET_HD: {
    displayName: 'BET',
    description:
      'Find your favorite BET shows, including In Contempt, Hit the Floor, Real Husbands of Hollywood, and more with Spectrum On Demand!',
    providerId: 'bet.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=BET_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'BET_HD',
  },
  Boomerang: {
    displayName: 'Boomerang',
    description:
      'Find the latest episodes of your favorite Boomerang shows with Spectrum On Demand!',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=PBSK&providerId=Boomerang',
    providerId: 'cartoonnetwork.com',
    seoTitleFormat: 3,
    providerName: 'Boomerang',
  },
  BRAVO_HD: {
    displayName: 'Bravo',
    description:
      'Find your favorite Bravo shows, including The Real Housewives, Family Karma, Below Deck, and more with Spectrum On Demand!',
    providerId: 'bravotv.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=BRAVO_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'BRAVO_HD',
  },
  CARTOONNETWORK_HD: {
    displayName: 'Cartoon Network',
    description:
      'Find your favorite hit Cartoon Network shows, including Teen Titans Go!, Total Drama Island, Chowder, & more with Spectrum On Demand!',
    providerId: 'cartoonnetwork.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=CARTOONNETWORK_HD&productId=PBSK',
    seoTitleFormat: 3,
    providerName: 'CARTOONNETWORK_HD',
  },
  CBSHD: {
    displayName: 'CBS',
    description:
      'Find your favorite CBS shows including The Young and the Restless and CBS Original Series like The Good Fight with Spectrum On Demand!',
    providerId: 'cbs.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=CBSHD&productId=PTOD',
    seoTitleFormat: 3,
    providerName: 'CBSHD',
  },
  MAX_HD_EXP: {
    displayName: 'Cinemax',
    description:
      'Find the best in exclusive Hollywood premieres & the largest variety of hit movies on Cinemax with Spectrum On Demand!',
    providerId: 'cinemax.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=MAX_HD_EXP&productId=SVOD',
    seoTitleFormat: 3,
    providerName: 'MAX_HD_EXP',
  },
  CMT_HD: {
    displayName: 'CMT',
    description:
      'Enjoy Country Music Channel (CMT) series and specials and watch anywhere with Spectrum On Demand!',
    providerId: 'cmt.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=CMT_HD&productId=MUSOD',
    seoTitleFormat: 3,
    providerName: 'CMT_HD',
  },
  CNBC_HD: {
    displayName: 'CNBC',
    description:
      'Access the latest episodes your favorite CNBC shows with Spectrum On Demand!',
    providerId: 'cnbc.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=CNBC_HD&productId=NWOD',
    seoTitleFormat: 3,
    providerName: 'CNBC_HD',
  },
  CNN_HD: {
    displayName: 'CNN',
    description:
      'Find news, documentaries, shows and special reports from CNN with Spectrum On Demand!',
    providerId: 'cnn.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=CNN_HD&productId=NWOD',
    seoTitleFormat: 2,
    providerName: 'CNN_HD',
  },
  CNN_ESP: {
    displayName: 'CNN en Español',
    description:
      'Find news, documentaries, shows and special reports from CNN en Español with Spectrum On Demand!',
    providerId: 'cnn.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=NWOD&providerId=CNN_ESP',
    seoTitleFormat: 2,
    providerName: 'CNN_ESP',
  },
  COMEDYCENTRAL_HD: {
    displayName: 'Comedy Central',
    description:
      'Find your favorite Comedy Central shows, including South Park, Detroiters, and more with Spectrum On Demand!',
    providerId: 'comedycentral.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=COMEDYCENTRAL_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'COMEDYCENTRAL_HD',
  },
  'Common Sense HD': {
    displayName: 'Common Sense Media',
    description:
      'Find the latest educational entertainment and technology recommendations for families on Common Sense Media with Spectrum On Demand! ',
    providerId: 'commonsensemedia.org',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=Common Sense HD&productId=FMOD',
    seoTitleFormat: 3,
    providerName: '%22Common%20Sense%20HD%22',
  },
  COOKINGCHANNEL_HD: {
    displayName: 'Cooking Channel',
    description:
      'Find your favorite Cooking Channel shows including Man Fire Food, Carnival Eats, and more with Spectrum On Demand!',
    providerId: 'cookingchanneltv.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=COOKINGCHANNEL_HD&productId=LSOD',
    seoTitleFormat: 3,
    providerName: 'COOKINGCHANNEL_HD',
  },
  CI: {
    displayName: 'Crime Investigation',
    description:
      'Find the latest episodes of your favorite Crime Investigation shows with Spectrum On Demand! ',
    providerId: 'ci.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=CI&productId=NWOD',
    seoTitleFormat: 3,
    providerName: 'CI',
  },
  CW_HD: {
    displayName: 'The CW',
    description:
      'Find and watch your favorite The CW shows with Spectrum On Demand!',
    providerId: 'cwtv.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=CW_HD&productId=PTHOD',
    seoTitleFormat: 3,
    providerName: 'CW_HD',
  },
  DESTINATION_AMERICA_HD: {
    displayName: 'Destination America',
    description:
      'Find your favorite Destination America shows, including A Haunting, Ghost Asylum, and more with Spectrum On Demand!',
    providerId: 'destinationameri.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=DESTINATION_AMERICA&productId=LSOD',
    seoTitleFormat: 3,
    providerName: 'DESTINATION_AMERICA_HD',
  },
  Discovery_Channel_HD: {
    displayName: 'Discovery Channel',
    description:
      'Find the latest episodes of your favorite Discovery Channel shows with Spectrum On Demand! ',
    providerId: 'discovery.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=Discovery_Channel_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'Discovery_Channel_HD',
  },
  DISCOVERY_ESP: {
    displayName: 'Discovery en Español',
    description:
      'Find the latest episodes of your favorite Discovery en Español shows with Spectrum On Demand! ',
    providerId: 'discoveryespanol.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=DISCOVERY_ESP&productId=LMOD',
    seoTitleFormat: 3,
    providerName: 'DISCOVERY_ESP',
  },
  DISCFAMILIA: {
    displayName: 'Familia Discovery',
    description:
      'Find the latest episodes of your favorite Familia Discovery shows with Spectrum On Demand! ',
    providerId: 'discoveryfamilia.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=DISCFAMILIA&productId=LMOD',
    seoTitleFormat: 3,
    providerName: 'DISCFAMILIA',
  },
  DISCOVERY_FAMILY_HD: {
    displayName: 'Discovery Family',
    description:
      'Find your favorite Discovery Family shows with Spectrum On Demand!',
    providerId: 'discoveryfamily.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=PBSK&providerId=DISCOVERY_FAMILY',
    seoTitleFormat: 3,
    providerName: 'DISCOVERY_FAMILY_HD',
  },
  DISCOVERY_LIFE_HD: {
    displayName: 'Discovery Life',
    description:
      'Find your favorite Discovery Life shows, including Shock Trauma and more, with Spectrum On Demand!',
    providerId: 'discoverylife.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=DISCOVERY_LIFE_HD&productId=LSOD',
    seoTitleFormat: 3,
    providerName: 'DISCOVERY_LIFE_HD',
  },
  DISNEY_CHANNEL_DAI_HD: {
    displayName: 'Disney Channel',
    description:
      "Find  your favorite Disney Channel shows, including Andi Mack, Raven's Home, and more with Spectrum On Demand!",
    providerId: 'disneychannel.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=DISNEY_CHANNEL_DAI_HD&productId=PBSK',
    seoTitleFormat: 3,
    providerName: 'DISNEY_CHANNEL_DAI_HD',
  },
  DISNEY_JUNIOR_DAI_HD: {
    displayName: 'Disney Junior',
    description:
      'Find and watch your favorite Disney Junior shows with Spectrum On Demand!',
    providerId: 'disneyjunior.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=DISNEY_JUNIOR_HD&productId=PBSK',
    seoTitleFormat: 3,
    providerName: 'DISNEY_JUNIOR_DAI_HD',
  },
  DISNEY_XD_DAI_HD: {
    displayName: 'Disney XD',
    description:
      'Find and watch your favorite Disney XD shows with Spectrum On Demand!',
    providerId: 'disneyxd.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=DISNEY_XD_DAI_HD&productId=PBSK',
    seoTitleFormat: 3,
    providerName: 'DISNEY_XD_DAI_HD',
  },
  DIY_HD: {
    displayName: 'DIY Network',
    description:
      'Find your favorite DIY Network shows, including House Hunters, Barnwood Builders, and more with Spectrum On Demand!',
    providerId: 'diynet.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=DIY_HD&productId=LSOD',
    seoTitleFormat: 3,
    providerName: 'DIY_HD',
  },
  E_CHANNEL_C3R_HD: {
    displayName: 'E!',
    description:
      'Find your favorite E! shows, including Botched, Keeping up with the Kardashians, and more with Spectrum On Demand!',
    providerId: 'eonline.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=E_CHANNEL_C3R_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'E_CHANNEL_C3R_HD',
  },
  EPIX_HD: {
    displayName: 'EPIX',
    description:
      'Find your favorite EPIX original series and hit movies like Berlin Station, A Quiet Place, and Star Trek, and more with Spectrum On Demand!',
    providerId: 'epixhd.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=EPIX_HD&productId=EPXOD',
    seoTitleFormat: 3,
    providerName: 'EPIX_HD',
  },
  ESPN_HD: {
    displayName: 'ESPN',
    description:
      'Access sports series, game coverage, and more on ESPN with Spectrum On Demand!',
    providerId: 'espn.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=ESPN_HD&productId=SPOD',
    seoTitleFormat: 1,
    providerName: 'ESPN_HD',
  },
  ESPN_DEPORTES_HD: {
    displayName: 'ESPN Deportes',
    description:
      'Access sports series, game coverage, and more on ESPN Deportes with Spectrum On Demand!',
    providerId: 'espn.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=LMOD&providerId=ESPN_DEPORTES',
    seoTitleFormat: 1,
    providerName: 'ESPN_DEPORTES_HD',
  },
  FILIPINO: {
    displayName: 'TFC',
    description:
      'Find and watch  your favorite The Filipino Channel (TFC) shows and movies with Spectrum On Demand!',
    providerId: 'comcast.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=FILOD&providerId=FILIPINO',
    seoTitleFormat: 3,
    providerName: 'FILIPINO',
  },
  FOODHD: {
    displayName: 'Food Network',
    description:
      'Find your favorite Food Network shows, including Chopped, Beat Bobby Flay, Barefoot Contessa, and more with Spectrum On Demand!',
    providerId: 'foodnetwork.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=FOODHD&productId=LSOD',
    seoTitleFormat: 3,
    providerName: 'FOODHD',
  },
  FOX_NETSHOWS_HD: {
    displayName: 'Fox',
    description:
      'Find your favorite FOX shows, including Empire, 9-1-1, The Resident, Gotham, and more with Spectrum On Demand!',
    providerId: 'fox.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=FOX_NETSHOWS_HD&productId=PTOD',
    seoTitleFormat: 2,
    providerName: 'FOX_NETSHOWS_HD',
  },
  FOXDEPORTES_HD: {
    displayName: 'Fox Deportes',
    description:
      'Find and watch Fox Deportes online including game coverage, sports series, and more with Spectrum On Demand! ',
    providerId: 'foxdeportes.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=FOXDEPORTES_HD&productId=LMOD',
    seoTitleFormat: 1,
    providerName: 'FOXDEPORTES_HD',
  },
  FOXSPORTSONE_HD: {
    displayName: 'FS1',
    description:
      'Find and watch FS1 online including game coverage, sports series, and more with Spectrum On Demand!',
    providerId: 'foxsports1.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=FOXSPORTSONE_HD&productId=SPOD',
    seoTitleFormat: 1,
    providerName: 'FOXSPORTSONE_HD',
  },
  FREEFORM_HD: {
    displayName: 'Freeform',
    description:
      'Find your favorite Freeform shows like Grown-ish, Good Trouble, Siren, Marvel’s Cloak & Dagger, and more with Spectrum On Demand!',
    providerId: 'freeform.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=FREEFORM_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'FREEFORM_HD',
  },
  FUSE_HD: {
    displayName: 'Fuse',
    description:
      'Find your favorite Fuse shows and movies with Spectrum On Demand!',
    providerId: 'fuse.tv',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=FUSE_HD&productId=MUSOD',
    seoTitleFormat: 3,
    providerName: 'FUSE_HD',
  },
  FXHD: {
    displayName: 'FX',
    description:
      "Enjoy FX's lineup of hit dramas, hilarious comedies, and blockbuster movies with Spectrum On Demand!",
    providerId: 'fxnetworks.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=FXHD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'FXHD',
  },
  FXM_HD: {
    displayName: 'FXM',
    description:
      'Find  and watch your favorite FX Movies (FXM) with Spectrum On Demand!',
    providerId: 'fxm.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=FXM_HD&productId=FMOD',
    seoTitleFormat: 3,
    providerName: 'FXM_HD',
  },
  FXX_HD: {
    displayName: 'FXX',
    description:
      'Find  and watch your favorite comedy originals, movies, and the latest episodes of your favorite FXX shows with Spectrum On Demand!',
    providerId: 'fxx.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=FXX_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'FXX_HD',
  },
  FYI_HD: {
    displayName: 'FYI',
    description:
      'Find your favorite FYI shows, including Tiny House Nation, Arranged, Teenage Newlyweds, and more with Spectrum On Demand!',
    providerId: 'fyi.tv',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=FYI_HD&productId=LSOD',
    seoTitleFormat: 3,
    providerName: 'FYI_HD',
  },
  GAIAMTV_SVOD_HD: {
    displayName: 'GAIAM TV',
    description:
      'Access Gaiam TV workout videos, including yoga, cardio, and more with Spectrum On Demand! ',
    providerId: 'gaia.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=GAIAMTV_SVOD&productId=GSS',
    seoTitleFormat: 3,
    providerName: 'GAIAMTV_SVOD_HD',
  },
  GALAVISION: {
    displayName: 'Galavision',
    description:
      'Find and watch your favorite Galavision shows with Spectrum On Demand!',
    providerId: 'univision.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/guide/18350?&sourceType=colorHybrid',
    seoTitleFormat: 3,
    providerName: 'GALAVISION',
  },
  GOLFCHANNEL_HD: {
    displayName: 'Golf Channel',
    description:
      'Access Golf Channel series and instructional videos with Spectrum On Demand!',
    providerId: 'thegolfchannel.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=GOLFCHANNEL_HD&productId=SPOD',
    seoTitleFormat: 1,
    providerName: 'GOLFCHANNEL_HD',
  },
  GSN_HD: {
    displayName: 'Game Show Network',
    description:
      'Find your favorite Game Show Network shows, including Master Minds, America Says, and more with Spectrum On Demand!',
    providerId: 'gsn.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=GSN_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'GSN_HD',
  },
  HALLMARK_HD_PLUS: {
    displayName: 'Hallmark Channel',
    description:
      'Find your favorite Hallmark Channel original shows and movies, including Good Witch, When Hope Calls, and more with Spectrum On Demand! ',
    providerId: 'hallmarkchannel.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=HALLMARK_HD_PLUS&productId=FMOD',
    seoTitleFormat: 3,
    providerName: 'HALLMARK_HD_PLUS',
  },
  HBO_HD_EXP: {
    displayName: 'HBO',
    description:
      'Access your favorite HBO Max originals and classics, including Game of Thrones, Westworld, and more with Spectrum On Demand!',
    providerId: 'hbo-hd.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=HBO_HD_EXP&productId=HOD',
    seoTitleFormat: 3,
    providerName: 'HBO_HD_EXP',
  },
  HGTVHD: {
    displayName: 'HGTV',
    description:
      'Find your favorite HGTV shows, including Fixer Upper and more, with Spectrum On Demand!',
    providerId: 'hgtv.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=HGTVHD&productId=LSOD',
    seoTitleFormat: 3,
    providerName: 'HGTVHD',
  },
  HISTORYHD: {
    displayName: 'History',
    description:
      'Find your favorite History Channel shows, including The Curse of Oak Island, Ancient Aliens, and more with Spectrum On Demand!',
    providerId: 'historychannel.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=HISTORYHD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'HISTORYHD',
  },
  HISTORYESP: {
    displayName: 'History en Español',
    description:
      'Find your favorite History en Español shows, including American Pickers, Pawn Stars, and more with Spectrum On Demand!',
    providerId: 'historychannel.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=LMOD&providerId=HISTORYESP',
    seoTitleFormat: 3,
    providerName: 'HISTORYESP',
  },
  HLN_HD: {
    displayName: 'HLN',
    description: 'Watch CNN Headline News (HLN) shows with Spectrum On Demand!',
    providerId: 'cnn.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=NWOD&providerId=HLN',
    seoTitleFormat: 3,
    providerName: 'HLN_HD',
  },
  IFC_FREE_HD: {
    displayName: 'IFC',
    description:
      'Enjoy Independent Film Channel (IFC) fan-favorite movies and keep up-to-date with access to new episodes with Spectrum On Demand!',
    providerId: 'ifctv.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=IFC_FREE_HD&productId=CEOD',
    seoTitleFormat: 3,
    providerName: 'IFC_FREE_HD',
  },
  ID_DISCOVERY_HD: {
    displayName: 'Investigation Discovery',
    description:
      'Find your favorite Investigation Discovery crime shows with Spectrum On Demand!',
    providerId: 'investigationdsc.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=ID_Discovery&productId=NWOD',
    seoTitleFormat: 3,
    providerName: 'ID_DISCOVERY_HD',
  },
  KPOP: {
    displayName: 'K Pop TV',
    description:
      'Find and watch your favorite K Pop shows with Spectrum On Demand!',
    providerId: 'comcast.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=MUSOD&providerId=KPOP',
    seoTitleFormat: 3,
    providerName: 'KPOP',
  },
  KABILLION_HD: {
    displayName: 'Kabillion',
    description:
      'Find and watch your favorite Kabillion shows with Spectrum On Demand!',
    providerId: 'kabillion.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=KABILLION_HD&productId=PBSK',
    seoTitleFormat: 3,
    providerName: 'KABILLION_HD',
  },
  KABILLIONGIRLS_HD: {
    displayName: 'Kabillion Girls Rule',
    description:
      'Find and watch your favorite Kabillion Girls Rule shows with Spectrum On Demand!',
    providerId: 'kabillion.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=PBSK&providerId=KABILLIONGIRLS',
    seoTitleFormat: 3,
    providerName: 'KABILLIONGIRLS_HD',
  },
  KBFD: {
    displayName: 'KLIFE',
    description:
      'Find and watch your favorite KLIFE shows with Spectrum On Demand!',
    providerId: '2017',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=KBFD&productId=KLIFE',
    seoTitleFormat: 3,
    providerName: 'KBFD',
  },
  LIFETIME_HD: {
    displayName: 'Lifetime',
    description:
      'Access all of the reality shows, drama series, and top rated movies Lifetime has to offer with Spectrum On Demand!',
    providerId: 'mylifetime.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=LIFETIME_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'LIFETIME_HD',
  },
  LMN: {
    displayName: 'LMN',
    description:
      'Find and watch your favorite Lifetime Movie Network (LMN) movies with Spectrum On Demand!',
    providerId: 'lmn.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=FMOD&providerId=LMN',
    seoTitleFormat: 3,
    providerName: 'LMN',
  },
  Logo: {
    displayName: 'Logo',
    description:
      'Find your favorite Logo shows, full episodes, and more with Spectrum On Demand!',
    providerId: 'logoonline.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=Logo&productId=LSOD',
    seoTitleFormat: 3,
    providerName: 'Logo',
  },
  MILITARY_HISTORY_CHANNEL: {
    displayName: 'Military History',
    description:
      'Find and watch your favorite Military History shows with Spectrum On Demand!',
    providerId: 'history.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=MILITARY_HISTORY_CHANNEL&productId=NWOD',
    seoTitleFormat: 3,
    providerName: 'MILITARY_HISTORY_CHANNEL',
  },
  MLBN_HD: {
    displayName: 'MLB Network',
    description: 'Access MLB documentaries and more with Spectrum On Demand! ',
    providerId: 'mlbnetwork.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=MLBN_HD&productId=SPOD',
    seoTitleFormat: 1,
    providerName: 'MLBN_HD',
  },
  MOTORTREND_HD: {
    displayName: 'MotorTrend',
    description:
      'Find full episodes of your favorite shows from MotorTrend anytime, anywhere with Spectrum On Demand!',
    providerId: 'motortrend.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=MOTORTREND_HD&productId=CEOD',
    seoTitleFormat: 3,
    providerName: 'MOTORTREND_HD',
  },
  MOVIEPLEX_HD: {
    displayName: 'Movieplex',
    description:
      'Find your favorite movies with Movieplex to watch and stream from anywhere with Spectrum On Demand!',
    providerId: 'starzencore.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=MPXOD&providerId=MOVIEPLEX_HD',
    seoTitleFormat: 3,
    providerName: 'MOVIEPLEX_HD',
  },
  MSNBC_HD: {
    displayName: 'MSNBC',
    description:
      'Find full episodes of your favorite MSNBC shows with Spectrum On Demand!',
    providerId: 'msnbc.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=MSNBC_HD&productId=NWOD',
    seoTitleFormat: 3,
    providerName: 'MSNBC_HD',
  },
  MTV_HD: {
    displayName: 'MTV',
    description:
      'Find your favorite MTV shows, including Double Shot at Love, Catfish, and more with Spectrum On Demand!',
    providerId: 'mtv.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=MTV_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'MTV_HD',
  },
  NATGEOHD: {
    displayName: 'National Geographic',
    description:
      'Find and watch your favorite National Geographic Wild shows with Spectrum On Demand!',
    providerId: 'natgeochannel.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=NATGEOHD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'NATGEOHD',
  },
  NATGEOWILD_HD: {
    displayName: 'National Geographic Wild',
    description:
      'Find and watch your favorite National Geographic Wild shows with Spectrum On Demand!',
    providerId: 'natgeowild.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=NATGEOWILD_HD&productId=NEWOD',
    seoTitleFormat: 3,
    providerName: 'NATGEOWILD_HD',
  },
  NBC_NETSHOWS_HD: {
    displayName: 'NBC',
    description:
      'Find your favorite hit NBC shows, including New Amsterdam, Chicago Med, and more with Spectrum On Demand!',
    providerId: 'nbc.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=NBC_NETSHOWS_HD&productId=PTOD',
    seoTitleFormat: 3,
    providerName: 'NBC_NETSHOWS_HD',
  },
  NBC_NEWS_HD: {
    displayName: 'NBC News',
    description: 'Find the latest NBC News with Spectrum On Demand!',
    providerId: 'nbcnews.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=NBC_NEWS_HD&productId=PTOD',
    seoTitleFormat: 2,
    providerName: 'NBC_NEWS_HD',
  },
  NBC_SPORTS_HD: {
    displayName: 'NBCSN',
    description:
      'Access sporting events,  games, and series through NBCSN with Spectrum On Demand!',
    providerId: 'nbcsports.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=NBC_SPORTS_HD&productId=SPOD',
    seoTitleFormat: 1,
    providerName: 'NBC_SPORTS_HD',
  },
  NBC_UNIVERSO_HD: {
    displayName: 'NBC Universo',
    description:
      'Find your favorite hit NBC Universo shows with Spectrum On Demand!',
    providerId: 'nbcuniverso.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=NBC_UNIVERSO&productId=LMOD',
    seoTitleFormat: 3,
    providerName: 'NBC_UNIVERSO_HD',
  },
  NFLNETWORK_HD: {
    displayName: 'NFL Network',
    description: 'Access NFL documentaries and more with Spectrum On Demand! ',
    providerId: 'nfl.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=NFLNETWORK_HD&productId=SPOD',
    seoTitleFormat: 1,
    providerName: 'NFLNETWORK_HD',
  },
  NICKNITE_HD: {
    displayName: 'Nick at Night',
    description:
      'Find and watch your favorite Nick at Night shows with Spectrum On Demand!',
    providerId: 'nickatnite.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=NICKNITE_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'NICKNITE_HD',
  },
  NICKELODEON_HD: {
    displayName: 'Nickelodeon',
    description:
      'Find your kids’ favorite Nickelodeon shows with Spectrum On Demand!',
    providerId: 'nickelodeon.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=NICKELODEON_HD&productId=PBSK',
    seoTitleFormat: 3,
    providerName: 'NICKELODEON_HD',
  },
  NICKESP: {
    displayName: 'Nick En Español',
    description:
      'Find your kids’ favorite Nick En Español shows with Spectrum On Demand!',
    providerId: 'nickelodeon.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=NICKESP&productId=PBSK',
    seoTitleFormat: 3,
    providerName: 'NICKESP',
  },
  NOGGIN_HD: {
    displayName: 'Nick Jr.',
    description:
      'Find and watch your favorite Nick Jr. shows with Spectrum On Demand!',
    providerId: 'noggin.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=PBSK&providerId=Noggin',
    seoTitleFormat: 3,
    providerName: 'NOGGIN_HD',
  },
  OUTDOORCHANNEL_HD: {
    displayName: 'Outdoor Channel',
    description:
      'Find and watch your favorite Outdoor Channel shows with Spectrum On Demand!',
    providerId: 'outdoorchannel.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=OUTDOORCHANNEL_HD&productId=SPOD',
    seoTitleFormat: 3,
    providerName: 'OUTDOORCHANNEL_HD',
  },
  OVATION_HD: {
    displayName: 'Ovation',
    description:
      'Find and watch your favorite Ovation shows with Spectrum On Demand!',
    providerId: 'ovation.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=OVATION_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'OVATION_HD',
  },
  OWN_HD: {
    displayName: 'OWN',
    description:
      'Catch up on full episodes of your favorite OWN shows with Spectrum On Demand!',
    providerId: 'oprah.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=OWN_HD&productId=LFOD',
    seoTitleFormat: 3,
    providerName: 'OWN_HD',
  },
  OXYGEN_HD: {
    displayName: 'Oxygen',
    description:
      'Find your favorite Oxygen shows, including Snapped, It Takes a Killer, and more with Spectrum On Demand!',
    providerId: 'oxygen.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=OXYGEN_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'OXYGEN_HD',
  },
  PARAMOUNTTV_HD: {
    displayName: 'Paramount Network',
    description:
      'Find your favorite Paramount Network shows, including 68 Whiskey, Bar Rescue, and more with Spectrum On Demand!',
    providerId: 'paramountnetwork.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=PARAMOUNTTV_HD&productId=CEOD',
    seoTitleFormat: 3,
    providerName: 'PARAMOUNTTV_HD',
  },
  PBS: {
    displayName: 'PBS',
    description: 'Find your favorite PBS shows with Spectrum On Demand!',
    providerId: 'pbs.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=PBS_HD&productId=PTOD',
    seoTitleFormat: 3,
    providerName: 'PBS',
  },
  POP_HD: {
    displayName: 'Pop',
    description: 'Find your favorite Pop shows with Spectrum On Demand!',
    providerId: 'poptv.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=POP_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'POP_HD',
  },
  REELZCHANNEL_HD: {
    displayName: 'Reelz',
    description:
      'Find and watch your favorite Reelz shows with Spectrum On Demand! ',
    providerId: 'reelzchannel.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=MOD&providerId=REELZCHANNEL',
    seoTitleFormat: 3,
    providerName: 'REELZCHANNEL_HD',
  },
  REVOLT_TV_HD: {
    displayName: 'Revolt',
    description:
      'Find and watch your favorite Revolt shows with Spectrum On Demand! ',
    providerId: 'revolt.tv',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=REVOLT_TV_HD&productId=MUSOD',
    seoTitleFormat: 3,
    providerName: 'REVOLT_TV_HD',
  },
  Science_Channel_HD: {
    displayName: 'Science Channel',
    description:
      'Find your favorite Science Channel shows, including Above and Beyond, How It’s Made, and more with Spectrum On Demand!',
    providerId: 'sciencechannel.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=Science_Channel_HD&productId=NWOD',
    seoTitleFormat: 3,
    providerName: 'Science_Channel_HD',
  },
  SHOWTIME_HD_EXP: {
    displayName: 'Showtime',
    description:
      'Find your favorite Showtime movies, Original Series, & more with Spectrum On Demand!',
    providerId: 'sho.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=SHOWTIME_HD_EXP&productId=SOD',
    seoTitleFormat: 3,
    providerName: 'SHOWTIME_HD_EXP',
  },
  SHUDDER_SVOD: {
    displayName: 'Shudder',
    description:
      'Watch your favorite horror, thriller, and suspense movies from Shudder with Spectrum On Demand!',
    providerId: 'shudder.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=SHUDDER_SVOD&productId=SDSS',
    seoTitleFormat: 3,
    providerName: 'SHUDDER_SVOD',
  },
  SMITH_HD: {
    displayName: 'Smithsonian Channel',
    description:
      'Find your favorite Smithsonian Channel series and documentaries online with Spectrum On Demand!',
    providerId: 'sho.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=NWOD&providerId=SMITH_HD',
    seoTitleFormat: 3,
    providerName: 'SMITH_HD',
  },
  SPECTRUMORIGINALS_HD: {
    displayName: 'Spectrum Originals',
    description:
      'Watch your favorite Spectrum Originals with Spectrum On Demand! ',
    providerId: 'spectrum.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=SPECTRUMORIGINALS_HD&productId=SOOD',
    seoTitleFormat: 3,
    providerName: 'SPECTRUMORIGINALS_HD',
  },
  SPORTSKOOL: {
    displayName: 'Sportskool',
    description: 'Watch your favorite Sportskool with Spectrum On Demand!',
    providerId: 'sportskool.tv',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=SPORTSKOOL&productId=SPOD',
    seoTitleFormat: 3,
    providerName: 'SPORTSKOOL',
  },
  STARZ_HD_EXP_MPEG4: {
    displayName: 'STARZ',
    description:
      'Access your favorite STARZ movies and shows and watch them anytime, anywhere with Spectrum On Demand!',
    providerId: 'starzencore.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=STARZ_HD&productId=STZOD',
    seoTitleFormat: 3,
    providerName: 'STARZ_HD_EXP_MPEG4',
  },
  ENCORE_HD_EXP_MPEG4: {
    displayName: 'STARZ ENCORE',
    description:
      'Access your favorite STARZ ENCORE movies and shows and watch them anytime, anywhere with Spectrum On Demand!',
    providerId: 'starzencore.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=ENCOD&providerId=ENCORE',
    seoTitleFormat: 3,
    providerName: 'ENCORE_HD_EXP_MPEG4',
  },
  SUNDANCENOW_SVOD: {
    displayName: 'Sundance Now',
    description:
      'Watch your favorite Sundance shows and movies with Spectrum On Demand!',
    providerId: 'sundancenow.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=SUNDANCENOW_SVOD&productId=SNSS',
    seoTitleFormat: 3,
    providerName: 'SUNDANCENOW_SVOD',
  },
  SUNDANCEHD: {
    displayName: 'Sundance TV',
    description:
      'Access the latest Sundance TV in-season episodes, iconic movies, and more with Spectrum On Demand!',
    providerId: 'sundancechannel.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=SUNDANCEHD&productId=FMOD',
    seoTitleFormat: 3,
    providerName: 'SUNDANCEHD',
  },
  SYFY_HD: {
    displayName: 'SYFY',
    description:
      'Catch the most recent season of your favorite SYFY shows and watch top rated movies with Spectrum On Demand!',
    providerId: 'syfy.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=SYFY_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'SYFY_HD',
  },
  TBS_HD: {
    displayName: 'TBS',
    description:
      "Find your favorite TBS favorite shows, including The Big Bang Theory, Bob's Burgers, and more with Spectrum On Demand!",
    providerId: 'tbs.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=TBS_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'TBS_HD',
  },
  'TCM On Demand': {
    displayName: 'TCM',
    description:
      'Enjoy unlimited access to the best of Turner Classic Movies (TMC) with Spectrum On Demand!',
    providerId: 'tcm.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=TCM On Demand&productId=FMOD',
    seoTitleFormat: 3,
    providerName: '%22TCM%20On%20Demand%22',
  },
  TELEMUNDO_HD: {
    displayName: 'Telemundo',
    description:
      'Capítulos Completos es el mejor lugar para ponerse al corriente con la temporada más reciente de sus programas favoritos de Telemundo, vea televisión en vivo y reprodúzcala donde sea!',
    providerId: 'telemundo.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=TELEMUNDO_HD&productId=LMOD',
    seoTitleFormat: 3,
    providerName: 'TELEMUNDO_HD',
  },
  TLC_HD: {
    displayName: 'TLC',
    description:
      'Find your favorite TLC shows, including Counting On, 90 Day Fiancé, and more with Spectrum On Demand!',
    providerId: 'tlc.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=TLC_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'TLC_HD',
  },
  TNT_HD: {
    displayName: 'TNT',
    description:
      'Find your favorite TNT shows, including Animal Kingdom, Claws, and more with Spectrum On Demand!!',
    providerId: 'tntdrama.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=TNT_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'TNT_HD',
  },
  TR3S: {
    displayName: 'tr3s',
    description:
      'Find and watch your favorite Tr3s shows and movies with Spectrum On Demand!',
    providerId: 'mtvtres.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=TR3S&productId=LMOD',
    seoTitleFormat: 3,
    providerName: 'TR3S',
  },
  TRAVEL_CHANNEL_HD: {
    displayName: 'Travel Channel',
    description:
      'Find your favorite Travel Channel shows, including Ghost Adventures, The Dead Files, and more with Spectrum On Demand!',
    providerId: 'travelchannel.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=TRAVEL_CHANNEL_HD&productId=LSOD',
    seoTitleFormat: 3,
    providerName: 'TRAVEL_CHANNEL_HD',
  },
  TRUTV_HD: {
    displayName: 'truTV',
    description:
      'Enjoy your favorite truTV shows, including Impractical Jokers, Adam Ruins Everything, The Carbonaro Effect, and more with Spectrum On Demand!',
    providerId: 'trutv.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=TRUTV_HD&productId=CEOD',
    seoTitleFormat: 3,
    providerName: 'TRUTV_HD',
  },
  TUDN: {
    displayName: 'TUDN',
    description: 'Find TUND soccer coverage with Spectrum On Demand!',
    providerId: 'tudn.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=LMOD&providerId=TUDN',
    seoTitleFormat: 1,
    providerName: 'TUDN',
  },
  TVLAND_HD: {
    displayName: 'TV Land',
    description:
      'Find your favorite TV Land classic sitcoms and original comedy series with Spectrum On Demand!',
    providerId: 'tvland.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=TVLAND_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'TVLAND_HD',
  },
  TVONE_HD: {
    displayName: 'TV One',
    description:
      'Keep up with your favorite shows on TV One with Spectrum On Demand!',
    providerId: 'comcast.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=ENTOD&providerId=TVONE',
    seoTitleFormat: 3,
    providerName: 'TVONE_HD',
  },
  UNIMAS: {
    displayName: 'Unimas',
    description:
      'Find and watch your favorite Unimas shows and movies with Spectrum On Demand!',
    providerId: 'univision.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=UNIMAS&productId=LMOD',
    seoTitleFormat: 3,
    providerName: 'UNIMAS',
  },
  UNIVERSALKIDS_HD: {
    displayName: 'Universal Kids',
    description:
      'Catch your favorite Universal Kids shows, like Top Chef Junior, Super Wings, and more with Spectrum On Demand!',
    providerId: 'universalkids.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=UNIVERSALKIDS_HD&productId=PBSK',
    seoTitleFormat: 3,
    providerName: 'UNIVERSALKIDS_HD',
  },
  UNIVERSALKIDS_ESP: {
    displayName: 'Universal Kids En Español',
    description:
      "Catch your favorite Universal Kids En Español shows, like Nina's World, Chica Show, and more with Spectrum On Demand!",
    providerId: 'universalkids.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=LMOD&providerId=UNIVERSALKIDS_ESP',
    seoTitleFormat: 3,
    providerName: 'UNIVERSALKIDS_ESP',
  },
  UNIVISION_HD: {
    displayName: 'Univision',
    description:
      'Watch your favorite Univision, Galavision, and UniMas shows with Spectrum On Demand!',
    providerId: 'univision.net',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=UNIVISION_HD&productId=LMOD',
    seoTitleFormat: 3,
    providerName: 'UNIVISION_HD',
  },
  UMC_SVOD: {
    displayName: 'UMC',
    description:
      'Find and watch your favorite Urban Movie Channel (UMC) shows, documentaries, original series, and more with Spectrum On Demand!',
    providerId: 'umc.tv',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=UMC_SVOD&productId=USS',
    seoTitleFormat: 3,
    providerName: 'UMC_SVOD',
  },
  UP_TV_HD: {
    displayName: 'Up',
    description:
      'Find and watch your favorite UPtv shows and movies with Spectrum On Demand!',
    providerId: 'comcast.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?productId=PORTAL&providerId=UP_TV_HD',
    seoTitleFormat: 3,
    providerName: 'UP_TV_HD',
  },
  USA_HD: {
    displayName: 'USA',
    description:
      'Catch up on the most recent season of your favorite USA shows with Spectrum On Demand!',
    providerId: 'usanetwork.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=USA_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'USA_HD',
  },
  VH1_HD: {
    displayName: 'VH1',
    description:
      'Find full episodes of your favorite VH1 shows anywhere with Spectrum On Demand!',
    providerId: 'vh1.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=VH1_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'VH1_HD',
  },
  VICELAND_HD: {
    displayName: 'Vice',
    description:
      'Find your favorite VICE shows and full episodes with Spectrum On Demand!',
    providerId: 'viceland.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=VICELAND_HD&productId=CEOD',
    seoTitleFormat: 3,
    providerName: 'VICELAND_HD',
  },
  VIENDOMOVIES: {
    displayName: 'Viendo Movies',
    description:
      'Find and watch your favorite Viendo movies with Spectrum On Demand!',
    providerId: 'viendomovies.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=VIENDOMOVIES&productId=LMOD',
    seoTitleFormat: 3,
    providerName: 'VIENDOMOVIES',
  },
  WETV_HD: {
    displayName: 'WE TV',
    description:
      'Find the latest episodes of your favorite WE TV shows and original series with Spectrum On Demand!',
    providerId: 'we.tv',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=WETV_HD&productId=ENTOD',
    seoTitleFormat: 3,
    providerName: 'WETV_HD',
  },
  WEATHER_CHANNEL: {
    displayName: 'The Weather Channel',
    description:
      'Find accurate weather forecast reports that can help you plan up to 15 days in advance through The Weather Channel with Spectrum On Demand!',
    providerId: 'weather.com',
    networkImgUrl:
      'https://cdnimg.spectrum.net/imageserver/image/default?providerId=WEATHER_CHANNEL_HD&productId=NWOD',
    seoTitleFormat: 3,
    providerName: 'WEATHER_CHANNEL',
  },
  WGNA_HD: {
    displayName: 'WGN America',
    description:
      'Find and watch your favorite WGN America movies and shows with Spectrum On Demand!',
    providerId: 'wgnamerica.com',
    networkImgUrl: 'https://cdnimg.spectrum.net/imageserver/guide/91097?',
    seoTitleFormat: 3,
    providerName: 'WGNA_HD',
  },
};

export default networks;
