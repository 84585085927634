import React, { Component } from 'react';
import { RouterComponentProps, Link } from 'react-router-dom';
import {
  KiteButton,
  KiteCheckbox,
  KiteIcon,
  KiteSelect,
} from '@kite/react-kite';
import { SortableTable } from '@kite/react-kite-plus';

import { renderTableDate } from '../../utils/helpers';
import { getAllMovies } from '../../apiCalls/MoviesService';
import { getAllSeries } from '../../apiCalls/SeriesService';
import './AddTitles.scss';
import {
  addFeaturesToCat,
  getCategories,
} from '../../apiCalls/CategoryService';
import {
  addFeaturesToSwimlane,
  getSwimlane,
} from '../../apiCalls/SwimlaneService';
import { ICategory, ISwimlane } from '../../utils/models';
import { SearchBar } from '../../components';

export interface IAddTitlesState {
  allFeatures: any[];
  filteredFeatures: any[];
  selectedFeatures: any[];
  loading: boolean;
  targetId: string;
  type: 'categories' | 'swimlanes' | '';
  allCategories: ICategory[];
  selectedCategory: string;
  selectedDate: string;
  selectedType: string;
  titleQuery: string;
  targetSwimlane: ISwimlane | null;
}

class AddTitles extends Component<RouterComponentProps, IAddTitlesState> {
  state: IAddTitlesState = {
    allFeatures: [],
    filteredFeatures: [],
    selectedFeatures: [],
    loading: false,
    targetId: '',
    type: '',
    allCategories: [],
    selectedCategory: 'All',
    selectedDate: 'Any',
    selectedType: 'both',
    titleQuery: '',
    targetSwimlane: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { cat_id: targetId, type },
      },
    } = this.props;
    this.setState({ loading: true, targetId, type });
    getCategories().then((cats) => this.setState({ allCategories: cats }));
    if (type === 'swimlanes') {
      getSwimlane(targetId).then((sl) => {
        this.setState({ targetSwimlane: sl });
        this.updateFeatureData(null, sl);
      });
    } else {
      this.updateFeatureData(targetId, null);
    }
  }

  updateFeatureData = async (targetCatId, swimlaneData) => {
    const requestConfig = {
      order: 'asc',
      page: 0,
      size: -1,
      sortBy: 'title',
    };
    const { allMovies } = await getAllMovies(requestConfig);
    const { allSeries } = await getAllSeries(requestConfig);
    let allFeatures;

    if (targetCatId) {
      allFeatures = [...allMovies, ...allSeries].filter(
        (f) => !f.categories.find((cat) => cat.id === targetCatId)
      );
    } else {
      allFeatures = [...allMovies, ...allSeries].filter(
        (f) => !swimlaneData.items.find((item) => item.itemId === f.id)
      );
    }

    this.setState({
      allFeatures,
      filteredFeatures: allFeatures,
      loading: false,
    });
  };

  handleCheckAll = () => {
    const { filteredFeatures, selectedFeatures } = this.state;
    const newSelected =
      selectedFeatures.length < filteredFeatures.length ? filteredFeatures : [];
    this.setState({ selectedFeatures: newSelected });
  };

  handleCheckOne = (id) => {
    const { filteredFeatures, selectedFeatures } = this.state;
    const targetFeature = filteredFeatures.find((f) => f.id === id);
    if (selectedFeatures.includes(targetFeature)) {
      this.setState({
        selectedFeatures: selectedFeatures.filter((f) => f !== targetFeature),
      });
    } else {
      this.setState({ selectedFeatures: [...selectedFeatures, targetFeature] });
    }
  };

  handleFilterSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = e;
    switch (name) {
      case 'selectedCategory':
        this.setState({ selectedCategory: value });
        break;
      case 'selectedDate':
        this.setState({ selectedDate: value });
        break;
      case 'selectedType':
        this.setState({ selectedType: value });
        break;
      default:
        break;
    }
    this.updateFilteredData({ [name]: value });
  };

  updateFilteredData = (newFilter) => {
    const {
      selectedCategory,
      selectedDate,
      selectedType,
      allFeatures,
      titleQuery,
    } = this.state;
    const filters = {
      ...{ selectedCategory, selectedDate, selectedType, titleQuery },
      ...newFilter,
    };

    const filteredFeatures = allFeatures.filter((f, i) => {
      const { categories, addedOn, tmsId } = f;
      let matches: boolean[] = [];
      matches.push(
        !!categories.find((cat) => cat.name === filters.selectedCategory) ||
          filters.selectedCategory === 'All'
      );
      const today = new Date();
      const addedDate = +new Date(addedOn);
      const dateValues = {
        Any: () => true,
        'one week': (date, addedDate) =>
          addedDate > +date.setDate(date.getDate() - 7),
        'one month': (date, addedDate) =>
          addedDate > +date.setMonth(date.getMonth() - 1),
        'three months': (date, addedDate) =>
          addedDate > +date.setMonth(date.getMonth() - 3),
        'six months': (date, addedDate) =>
          addedDate > +date.setMonth(date.getMonth() - 6),
      };

      matches.push(dateValues[filters.selectedDate](today, addedDate));

      const featureType = tmsId.substring(0, 2) === 'SH' ? 'series' : 'movies';
      matches.push(
        featureType === filters.selectedType || filters.selectedType === 'both'
      );

      if (filters.titleQuery) {
        const formatString = (s) =>
          s
            .replace(/[.,/#!$%^&*;:{}=\-_`'~()]/g, '')
            .replace(/\s/g, '')
            .toLowerCase();
        const formattedQuery = formatString(filters.titleQuery);
        const formattedTitle = formatString(f.title);

        matches.push(formattedTitle.includes(formattedQuery));
      } else {
        matches.push(true);
      }

      return matches.every((val) => val);
    });
    this.setState({ filteredFeatures });
  };

  handleInputChange = (titleQuery) => {
    this.setState({ titleQuery });
    this.updateFilteredData({ titleQuery });
  };

  addTitles = async () => {
    const { selectedFeatures, targetId, type } = this.state;

    const formattedFeatures = selectedFeatures.reduce(
      (acc, f) => {
        const { tmsId, id } = f;
        const type = tmsId.substring(0, 2) === 'SH' ? 'series' : 'movies';
        acc[type].push(id);
        return acc;
      },
      { movies: [], series: [] }
    );

    if (type === 'categories') {
      await addFeaturesToCat(targetId, formattedFeatures);
    } else {
      await addFeaturesToSwimlane(targetId, formattedFeatures);
    }

    this.props.history.push(`/${type}/${targetId}`);
  };

  render() {
    const {
      filteredFeatures,
      selectedFeatures,
      loading,
      targetId,
      allCategories,
      selectedCategory,
      selectedDate,
      selectedType,
      type,
      targetSwimlane,
    } = this.state;

    const columns = [
      {
        label: (
          <KiteCheckbox
            label=""
            name="check-all"
            id="check-all"
            checked={
              !!filteredFeatures.length &&
              filteredFeatures.length === selectedFeatures.length &&
              !loading
            }
            onChange={this.handleCheckAll}
            className="add-titles__checkbox"
            disabled={!filteredFeatures.length}
          />
        ),
        sortKey: '',
        sortEnabled: false,
        render: ({ id }) => (
          <KiteCheckbox
            label=""
            name={`check-${id}`}
            id={`check-${id}`}
            checked={selectedFeatures.find((f) => f.id === id)}
            onChange={() => this.handleCheckOne(id)}
            className="add-titles__checkbox"
          />
        ),
        size: 0.1,
      },
      {
        label: 'Title',
        sortKey: 'title',
        render: ({ title, posterUrl }) => (
          <>
            <img
              className="add-titles__poster"
              src={posterUrl}
              alt={`${title} poster`}
            />
            <span className="add-titles__title">{title}</span>
          </>
        ),
      },
      {
        label: 'Added',
        sortKey: 'addedOn',
        render: ({ addedOn }) => renderTableDate(addedOn),
      },
      {
        label: 'Categories',
        sortKey: '',
        sortEnabled: false,
        render: ({ categories }) =>
          categories.map((cat) => cat.name).join(', '),
      },
    ];

    const targetCatName = allCategories.find((cat) => cat.id === targetId)
      ?.name;

    const categoryOptions = allCategories
      .filter((cat) => cat.name !== targetCatName)
      .map(({ name }) => <option value={name}>{name}</option>);

    return (
      <main className="add-titles">
        <Link to={`/${type}/${targetId}`} className="add-titles__back-link">
          <KiteIcon name="chevron-left" size="22px" /> Back to Edit {type}
        </Link>
        <h2>Add Titles to {targetCatName || targetSwimlane?.name}</h2>
        <div className="add-titles__filter-wrapper">
          <KiteSelect
            onChange={this.handleFilterSelect}
            id="category-select"
            name="selectedCategory"
            value={selectedCategory}
            label="Categories"
          >
            <option value="All">All</option>
            {categoryOptions}
          </KiteSelect>
          <KiteSelect
            onChange={this.handleFilterSelect}
            id="date-select"
            name="selectedDate"
            value={selectedDate}
            label="Added Date"
          >
            <option value="Any">Any</option>
            <option value="one week">Last Week</option>
            <option value="one month">Last Month</option>
            <option value="three months">Last Three Months</option>
            <option value="six months">Last Six Months</option>
          </KiteSelect>
          <KiteSelect
            onChange={this.handleFilterSelect}
            id="type-select"
            name="selectedType"
            value={selectedType}
            label="Content Type"
          >
            <option value="both">Movies and Series</option>
            <option value="movies">Movies Only</option>
            <option value="series">Series Only</option>
          </KiteSelect>
        </div>

        <SearchBar
          onSearch={this.handleInputChange}
          featureType="Titles"
          showShadow={false}
        />
        <SortableTable
          className="add-titles__table"
          columns={columns}
          tableData={filteredFeatures}
          initialSortHeader="name"
          loading={loading}
          fixedHeight="400px"
        />
        <div className="add-titles__button-wrapper">
          <p>{selectedFeatures.length} Selected</p>
          <KiteButton
            onClick={() => this.props.history.push(`/categories/${targetId}`)}
            type="outline"
          >
            Cancel
          </KiteButton>
          <KiteButton onClick={this.addTitles}>Add Titles</KiteButton>
        </div>
      </main>
    );
  }
}

export default AddTitles;
