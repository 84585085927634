import React from 'react';
import { Link } from 'react-router-dom';
import { KiteButton, KiteIcon, KiteLoader } from '@kite/react-kite';

import { getStatusColor, getStatusIcon } from '../../utils/helpers';
import { ISwimlane } from '../../utils/models';
import './SwimlaneCollection.scss';

export interface ISwimlaneCollectionProps {
  swimlaneData: ISwimlane;
  onAdd: (id: string) => void;
  onDelete: (id: string) => void;
  loadingId: string;
  loadingButton: string;
}

const SwimlaneCollection = ({
  swimlaneData,
  onAdd,
  onDelete,
  loadingId,
  loadingButton,
}: ISwimlaneCollectionProps) => {
  const { items, id } = swimlaneData;
  const movieCount = items.filter((m) => m.type === 'movie').length;
  const seriesCount = items.filter((m) => m.type === 'series').length;

  const tiles = items
    .sort((a, b) => (a.data.title > b.data.title ? 1 : -1))
    .map(({ data, type, itemId, priorityNumber }) => {
      const { title, tmsId, allCategories, status } = data;
      const posterUrl = `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Poster&width=50`;
      const path =
        type === 'movie'
          ? `${window.location.origin}/movies/${itemId}`
          : `${window.location.origin}/series/${itemId}`;
      const typeIcon = (
        <KiteIcon
          name={type === 'movie' ? 'ticket-f' : 'tv-f'}
          color="#9ba9bd"
          margin="0 8px 0 0"
        />
      );

      const categories = allCategories.map((c) => c.name).join(', ');
      const statusValues = {
        New: 'New (Not visible in Production)',
        QualityControl: 'Quality Control (Not visible in Production)',
        ReadyForProduction: 'Ready for Production (Not visible in Production)',
        Production: 'In Production',
      };
      return (
        <div className="swimlane-collection__tile" key={itemId}>
          <img src={posterUrl} alt={`${title} poster`} />
          <div className="swimlane-collection__tile-details">
            <a
              className="swimlane-collection__tile-link"
              href={path}
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}{' '}
              <KiteIcon
                name="chevron-right"
                color="#0073d8"
                size="12px"
                margin="0 0 0 4px"
              />
            </a>
            <p className="swimlane-collection__tile-genre">
              {typeIcon}
              {type} • {categories}
            </p>
            <p className="swimlane-collection__tile-status">
              <KiteIcon
                name={getStatusIcon(status)}
                color={getStatusColor(status)}
                margin="0 8px 0 0"
              />{' '}
              {statusValues[status]}
            </p>
          </div>
          <div className="swimlane-collection__tile-btn-wrapper">
            {priorityNumber ? (
              <p className="swimlane-collection__tile-btn--in">In Swimlane</p>
            ) : (
              <KiteButton
                className="swimlane-collection__tile-btn"
                type="standalone-link"
                size="small"
                onClick={() => onAdd(itemId)}
                loading={loadingButton === 'itemAdd' && loadingId === itemId}
              >
                Add to Swimlane
              </KiteButton>
            )}
            {loadingButton === 'itemDelete' && loadingId === itemId ? (
              <KiteLoader size="16px" />
            ) : (
              <KiteIcon
                name="trash-f"
                color="#d6312b"
                onClick={() => onDelete(itemId)}
              />
            )}
          </div>
        </div>
      );
    });

  const emptyWatermark = (
    <div className="swimlane-collection__empty">
      <KiteIcon
        className="swimlane-collection__empty-icon"
        name="plus-circle-f"
        color="#5db8fc"
        size="114px"
      />
      <p className="swimlane-collection__empty-copy">
        Add New Titles to build Collection
      </p>
    </div>
  );
  return (
    <div className="swimlane-collection">
      <h2>Swimlane Collection</h2>
      <Link
        className="kite-btn kite-btn-lg swimlane-collection__add-btn"
        to={`${id}/add`}
      >
        <span
          tabIndex={-1}
          className="kite-btn__content kite-btn-outline-primary"
        >
          Add Titles
        </span>
      </Link>
      <p className="swimlane-collection__copy">
        These are all of the titles that will be available in the swimlane and
        in alphabetical order in the view all section.
      </p>
      {!!tiles.length && (
        <p className="swimlane-collection__totals">
          {seriesCount} Shows • {movieCount} Movies
        </p>
      )}

      {tiles.length ? (
        <div className="swimlane-collection__tile-wrapper">{tiles}</div>
      ) : (
        emptyWatermark
      )}
    </div>
  );
};

export default SwimlaneCollection;
