import React from 'react';
import { KiteButton, KiteIcon } from '@kite/react-kite';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './MovieResult.scss';
import { IService } from '../../utils/models';

export interface IMovieResultProps {
  movie: IMovieResult;
  onAdd: (id: string) => void;
  onUpdate?: (tsmId: string, title: string) => void;

  resultType: 'add' | 'search' | 'update';
}

export interface IMovieResult {
  type: 'existing' | 'new';
  title: string;
  rating: string;
  tmsId: string;
  posterUrl: string;
  language: string;
  description: string;
  duration: number;
  year: number;
  id?: string;
  services?: IService[];
  status?: string;
  files?: any;
}

const MovieResult = ({
  movie: {
    type,
    title,
    language,
    tmsId,
    posterUrl,
    duration,
    description,
    year,
  },
  onAdd,
  onUpdate,
  resultType,
}: IMovieResultProps) => {
  return (
    <article
      className={classNames({
        'movie-result': true,
        'movie-result--search': resultType === 'search',
      })}
    >
      <img
        className="movie-result__image"
        alt="Movie Poster"
        src={posterUrl}
        width="110"
        height="165"
      />

      <div className="movie-result__content">
        <h3 className="title">
          {title} {year && `- ${year}`}
        </h3>
        <div className="info">
          {language && <span className="label">{language}</span>}
          {language && !!duration && <span className="dot"></span>}
          {!!duration && <span className="label">{duration} min</span>}
        </div>
        <div className="info">
          <span className="label">TMSID </span>
          <p>{tmsId}</p>
        </div>
        {description && (
          <div className="info">
            <span className="label">Description </span>
            <p>{description}</p>
          </div>
        )}
      </div>

      {resultType === 'add' && (
        <div className="movie-result__right">
          <span className={`movie-result__badge movie-result__badge--${type}`}>
            {type} Movie
          </span>

          {type === 'existing' ? (
            <Link className="movie-result__link" to={`/movies/${tmsId}`}>
              <span className="link__content">
                <KiteIcon name="plus-alt" />
                Update Movie
              </span>
            </Link>
          ) : (
            <KiteButton
              className="movie-result__button"
              type="outline"
              leftIcon="plus-alt"
              size="medium"
              onClick={() => onAdd(tmsId)}
            >
              Add Movie
            </KiteButton>
          )}
        </div>
      )}

      {resultType === 'update' && (
        <div className="episode-result__right">
          <KiteButton
            className="episode-result__button"
            type="outline"
            leftIcon="plus-alt"
            size="medium"
            onClick={() => onUpdate && onUpdate(tmsId, title)}
          >
            Update TMSID
          </KiteButton>
        </div>
      )}
    </article>
  );
};

export default MovieResult;
