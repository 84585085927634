import React from 'react';
import { KiteModal } from '@kite/react-kite';

import './SwimlanePublishedModal.scss';
import { ISwimlane } from '../../utils/models';

export interface ISwimlanePublishedModalProps {
  isOpen: boolean;
  swimlaneData: ISwimlane;
  onClick: () => void;
  onHide: () => any;
  action: 'prevent' | 'confirm';
}

const SwimlanePublishedModal = ({
  isOpen,
  swimlaneData,
  onClick,
  onHide,
  action,
}: ISwimlanePublishedModalProps) => {
  const { startTime } = swimlaneData;
  const isScheduled = startTime && new Date(startTime) > new Date();

  const localStatus = isScheduled ? 'Scheduled' : 'Published';

  let copy;

  if (action === 'prevent') {
    copy = `This swimlane is ${localStatus} and cannot be edited. Please remove it from production to change the name or delete.`;
  } else {
    copy = `This swimlane is ${localStatus}. Please confirm that you would like to make these changes to the production app.`;
  }

  const handleClick = () => {
    onClick();
    onHide();
  };

  return (
    <KiteModal
      className="swimlane-published-modal"
      canShow={isOpen}
      title={`Swimlane is ${localStatus}`}
      disableBackgroundClose
      ctaAction={handleClick}
      ctaCopy={action === 'confirm' ? 'Confirm' : 'Ok'}
      secondaryCtaAction={onHide}
      secondaryCtaCopy="Cancel"
    >
      <p>{copy}</p>
    </KiteModal>
  );
};

export default SwimlanePublishedModal;
