import React from 'react';

import './TableStatus.scss';
import { KiteIcon } from '@kite/react-kite';
import { getStatusIcon, getStatusColor } from '../../utils/helpers';

export interface ITableStatusProps {
  status: string;
  errors: string[];
}

const TableStatus = ({ status, errors }: ITableStatusProps) => (
  <span className="table-status">
    <span className="table-status__status">
      <KiteIcon
        className="table-status__status-icon"
        name={getStatusIcon(status)}
        color={!!errors.length ? '#d6312b' : getStatusColor(status)}
      />
      {status}
    </span>

    {!!errors.length && (
      <span className="table-status__status-err">with Errors</span>
    )}
  </span>
);

export default TableStatus;
