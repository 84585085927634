import React, { useEffect, useState } from 'react';
import { Drawer } from '@kite/react-kite-plus';
import { KiteButton, KiteInput } from '@kite/react-kite';

import './CategoryDrawer.scss';
import { ICategory } from '../../utils/models';

export interface ICategoryDrawerPops {
  isOpen: boolean;
  catData: ICategory | null;
  onClose: () => any;
  onSubmit: (data) => any;
  onDelete: () => void;
}

const CategoryDrawer = ({
  isOpen,
  catData,
  onClose,
  onSubmit,
  onDelete,
}: ICategoryDrawerPops) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(catData?.name || '');
  }, [catData]);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSubmit(inputValue);
    onClose();
  };

  const deleteCard = (
    <div className="category-drawer__delete-card">
      <p className="category-drawer__delete-title">Delete Category</p>
      <p className="category-drawer__delete-copy">
        Delete a category to permanently remove it from the system.
      </p>
      <KiteButton
        className="category-drawer__delete-button"
        type="outline"
        onClick={onDelete}
      >
        Delete Category
      </KiteButton>
    </div>
  );

  return (
    <Drawer
      isOpen={isOpen}
      disableBackgroundClose
      className="category-drawer"
      onClose={handleClose}
    >
      <h2>Edit Category Details</h2>
      <KiteInput
        className="category-drawer__input"
        id="category-name"
        label="Category Name"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <KiteButton className="category-drawer__submit" onClick={handleSave}>
        Save
      </KiteButton>
      {deleteCard}
    </Drawer>
  );
};

export default CategoryDrawer;
