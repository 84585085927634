import React from 'react';

import './Subheader.scss';
import { KiteTabs, KiteIcon, KiteButton } from '@kite/react-kite';
import { getStatusIcon } from '../../utils/helpers';
import { TStatus } from '../../utils/models';

export interface ISubheaderProps {
  featureTitle: string;
  featureType: 'movie' | 'series' | 'episode';
  currentTab?: string;
  seasonCount?: number;
  status?: TStatus;
  onSelectTab?: (tab: string) => void;
  seriesTitle?: string;
  onButtonClick?: () => void;
  seasonNumber?: number;
  episodeNumber?: number;
}

const Subheader = ({
  featureTitle,
  featureType,
  currentTab,
  seasonCount,
  onSelectTab,
  status,
  seriesTitle,
  onButtonClick,
  seasonNumber,
  episodeNumber,
}: ISubheaderProps) => {
  const seriesTabs = (
    <KiteTabs
      className="subheader__tabs"
      tabs={['Details', `Seasons (${seasonCount})`]}
      currentTab={currentTab}
      onSelectTab={onSelectTab}
    />
  );

  let statusIcon = getStatusIcon(status);

  const seriesStatus = (
    <div className="subheader__status">
      <span className="subheader__status-title">Series Status:</span>{' '}
      <KiteIcon
        className="subheader__status-icon"
        color="white"
        name={statusIcon}
      />
      {status}
    </div>
  );

  const backButton = (
    <KiteButton
      className="subheader__back"
      leftIcon="chevron-left"
      type="standalone-link"
      size="small"
      onClick={onButtonClick}
    >
      Back to {seriesTitle} Seasons
    </KiteButton>
  );

  const episodeData = (
    <p className="subheader__episode-data">
      Season {seasonNumber} • Episode {episodeNumber}
    </p>
  );
  return (
    <div className="subheader">
      <div className="subheader__content">
        {featureType === 'episode' && backButton}
        <h2 className="subheader__title">{featureTitle}</h2>
        {featureType === 'series' && seriesTabs}
        {featureType !== 'movie' && seriesStatus}
        {featureType === 'episode' && episodeData}
      </div>
    </div>
  );
};

export default Subheader;
