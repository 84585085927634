import React from 'react';
import { KiteModal, KiteButton } from '@kite/react-kite';

import './ServerError.scss';

export interface IServerErrorProps {
  isOpen: boolean;
  onClose: () => any;
}

const ServerError = ({ isOpen, onClose }: IServerErrorProps) => {
  return (
    <KiteModal
      className="server-error"
      canShow={isOpen}
      title="Server Error"
      disableBackgroundClose
      showCloseButton={false}
    >
      <p className="server-error__copy">
        Please try again. If the error persists, contact the dev team.
      </p>

      <KiteButton
        type="outline"
        className="server-error__button"
        onClick={onClose}
      >
        Ok
      </KiteButton>
    </KiteModal>
  );
};

export default ServerError;
