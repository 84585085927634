import React, { Component } from 'react';
import { RouterComponentProps, Link } from 'react-router-dom';
import * as SearchService from '../../apiCalls/SearchService';
import * as BucketService from '../../apiCalls/BucketService';
import * as ContentService from '../../apiCalls/ContentService';
import * as MoviesService from '../../apiCalls/MoviesService';
import { getAssetData, updateAsset } from '../../apiCalls/AssetsService';

import {
  SearchBar,
  MovieResult,
  Metadata,
  CountriesCard,
  CategoriesCard,
  FeatureServices,
  AddDrawer,
  ServerError,
  AssetCards,
  FilesCard,
  DuplicateServiceModal,
  RemoveFeatureUpdateModal,
  ExpirationCard,
  ExpirationDateModal,
} from '../../components';
import { KiteIcon, KiteCard, KiteButton, KiteLoader } from '@kite/react-kite';

import './AddMovie.scss';
import { IMovieResult } from '../../components/MovieResult/MovieResult';
import {
  ICategory,
  INewAsset,
  IFile,
  IService,
  INewFile,
} from '../../utils/models';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';
import dayjs from 'dayjs';

export interface IAddMovieState {
  results: IMovieResult[];
  currentStep: number;
  selectedMovie: IMovieResult | null;
  selectedCategories: ICategory[];
  primaryCategory: ICategory | null;
  files: any[];
  services: any[];
  serverError: boolean;
  loading: boolean;
  assetData: INewAsset | null;
  sortedFiles: any;
  formattedFiles: any;
  addDrawer: '' | 'Service' | 'File';
  selectedFile: IService | INewFile | null;
  loadingAdd: boolean;
  showModal: 'duplicate' | 'remove' | 'expiration date' | '';
  duplicateFiles: IService[];
  endTime: string | null;
  customEndTime: string | null;
  useCmsExpiration: boolean;
}

class AddMovie extends Component<RouterComponentProps, IAddMovieState> {
  state: IAddMovieState = {
    results: [],
    currentStep: 0,
    selectedMovie: null,
    selectedCategories: [],
    primaryCategory: null,
    files: [],
    services: [],
    serverError: false,
    loading: false,
    assetData: null,
    sortedFiles: {},
    formattedFiles: {},
    addDrawer: '',
    selectedFile: null,
    loadingAdd: false,
    showModal: '',
    duplicateFiles: [],
    endTime: null,
    customEndTime: null,
    useCmsExpiration: true,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    const [analytics] = this.context;
    const {
      match: {
        params: { asset_id },
      },
    } = this.props;

    if (asset_id) {
      analytics.trackPageView('Add Movie - Automated');
      getAssetData(asset_id).then((assetData) => {
        if (assetData.tmsId) {
          this.handleUpdatedAssets(assetData);
        } else {
          this.handleNewAssets(assetData);
        }
      });
    } else {
      analytics.trackPageView('Add Movie - Manual');
      this.setState({ currentStep: 1 });
    }
  }

  handleNewAssets = (assetData) => {
    const { assetTitle, assetFiles } = assetData;
    if (assetTitle.includes('_')) {
      const title = assetTitle
        .split('_')[1]
        .split(/(?=[A-Z])/)
        .join(' ');
      this.handleSearch(title);
    }
    const sortedFiles = assetFiles ? this.generateSortedFiles(assetFiles) : {};
    this.setState({
      assetData,
      sortedFiles,
      currentStep: 1,
    });
  };

  handleUpdatedAssets = async (assetData) => {
    const { assetFiles, movie } = assetData;

    const movieData = await MoviesService.getMovie(movie.id);
    const newFiles = assetFiles.filter(
      (file) =>
        !movieData.files.find((fpf) => fpf.file === file) &&
        !movieData.services.find((fpf) => fpf.file === file)
    );
    const sortedFiles = await this.generateSortedFiles(newFiles);

    this.setState({
      assetData,
      selectedMovie: {
        ...movieData,
        language:
          movieData.files[0]?.language || movieData.services[0]?.language || '',
        type: 'existing',
      },
      currentStep: 2,
      sortedFiles,
    });
  };

  generateSortedFiles = (files) => {
    return files.reduce(
      (acc, file: string) => {
        const ext = file.split('.').pop();
        if (ext === 'actisync') {
          acc.fp[file] = { language: '' };
        } else if (ext === 'scc') {
          return acc;
        } else {
          acc.service[file] = { language: '', serviceType: '' };
        }
        return acc;
      },
      { fp: {}, service: {} }
    );
  };

  handleSearch = async (query) => {
    this.setState({ loading: true });
    const data = await SearchService.searchDS(query, ['movie']);
    this.setState({ results: [...data.new], loading: false });
  };

  handleAddClick = (id) => {
    const { results, assetData } = this.state;
    const targetMovie = results.find((movie) => movie.tmsId === id);
    const nextStep = assetData ? 2 : 3;
    // const { categories, primaryCategory } = targetMovie || {};

    this.setState({
      selectedMovie: targetMovie || null,
      currentStep: nextStep,
      // selectedCategories: categories || [],
      // primaryCategory: primaryCategory || '',
    });
    window.scrollTo(0, 0);
  };

  handleCategorySelect = (categories) => {
    this.setState({ selectedCategories: categories });
  };

  handlePrimaryCategorySelect = (category) => {
    this.setState({ primaryCategory: category });
  };

  handleCategoryWarning = () => {
    console.log('Category Warning');
  };

  handleFileRemove = (name) => {
    const { files } = this.state;
    const newFiles = files.filter((file) => file.serviceFile.name !== name);
    this.setState({ files: newFiles });
  };

  handleServicesClick = (action, name) => {
    if (action === 'add') {
      this.setState({ addDrawer: 'Service' });
    } else if (action === 'remove') {
      const { services } = this.state;
      const newServices = services.filter(
        (file) => file.serviceFile.name !== name
      );
      this.setState({ services: newServices });
    }
  };

  handleDrawerClose = () => {
    this.setState({ addDrawer: '', selectedFile: null });
  };

  handleFileSubmit = (action, newFile) => {
    const { services, files, addDrawer } = this.state;

    if (addDrawer === 'File') {
      if (action === 'Add') {
        this.setState({ files: [newFile, ...files] });
      } else {
        const updatedFiles = [
          ...files.filter(
            (file) => file.serviceFile.name !== newFile.serviceFile.name
          ),
          newFile,
        ];
        this.setState({ files: updatedFiles });
      }
    } else {
      this.setState({ services: [newFile, ...services] });
    }
  };

  handleFileDelete = (name) => {
    const { services, files, addDrawer } = this.state;

    if (addDrawer === 'File') {
      const newFiles = files.filter((file) => file.serviceFile.name !== name);
      this.setState({ files: newFiles });
    } else {
      const newServices = services.filter((file) => file.name !== name);
      this.setState({ services: newServices });
    }
  };

  handleBackClick = () => {
    const { currentStep, assetData } = this.state;
    const resetFiles = assetData
      ? this.generateSortedFiles(assetData.assetFiles)
      : {};
    this.setState({
      currentStep: currentStep - 1,
      selectedMovie: null,
      selectedCategories: [],
      primaryCategory: null,
      files: [],
      sortedFiles: resetFiles,
    });

    window.scrollTo(0, 0);
  };

  handleAddMovie = async () => {
    this.setState({ loadingAdd: true });
    const { assetData } = this.state;

    if (assetData) {
      this.addAutomatedMovie();
    } else {
      this.addNewMovie();
    }
  };

  addAutomatedMovie = async () => {
    const {
      assetData,
      selectedMovie,
      selectedCategories,
      primaryCategory,
      formattedFiles,
      useCmsExpiration,
      endTime,
      customEndTime,
    } = this.state;
    const { tmsId, title } = selectedMovie || {};

    const movieData = {
      categories: selectedCategories.map((cat) => cat.id),
      countries: ['United States'],
      fpFiles: formattedFiles.fp.map((file) => ({
        file: file.file,
        language: file.language,
      })),
      primaryCategory: primaryCategory ? primaryCategory.id : '',
      tmsId,
      title,
      useCmsExpiration,
      endTime,
      customEndTime,
    };

    const movieResponse = await MoviesService.createMovie(movieData);

    if (!movieResponse) {
      this.setState({ serverError: true });
      return null;
    }

    const servicePromises = formattedFiles.service.map((service) => {
      const { file, language, type } = service;
      return ContentService.createContent(file, language, type, tmsId, 'New');
    });
    const serviceResponses = await Promise.all(servicePromises);

    if (serviceResponses.every((res) => !res)) {
      this.setState({ serverError: true });
      return null;
    }

    const assetResponse = await updateAsset(assetData?.id, {
      tmsId,
      newAsset: false,
    });
    if (!assetResponse) {
      this.setState({ serverError: true });
      return null;
    }

    this.props.history.push(`/movies/${movieResponse.id}`);
  };

  addNewMovie = async () => {
    const {
      services,
      files,
      selectedMovie,
      selectedCategories,
      primaryCategory,
      useCmsExpiration,
      endTime,
      customEndTime,
    } = this.state;

    const { tmsId, title } = selectedMovie || {};
    const filePromises = [
      ...files.map((file) =>
        BucketService.uploadFile(file.serviceFile, `/assets/${tmsId}`)
      ),
      ...services.map((service) =>
        BucketService.uploadFile(service.serviceFile, `/assets/${tmsId}`)
      ),
    ];
    const uploadResponses = await Promise.all(filePromises);

    if (uploadResponses.every((res) => !res)) {
      this.setState({ serverError: true });
      return null;
    }

    const movieData = {
      categories: selectedCategories.map((cat) => cat.id),
      countries: ['United States'],
      fpFiles: files.map((file) => ({
        file: `/assets/${tmsId}/${file.serviceFile.name}`,
        language: file.serviceLanguage,
      })),
      primaryCategory: primaryCategory ? primaryCategory.id : '',
      tmsId,
      title,
      useCmsExpiration,
      endTime,
      customEndTime,
    };

    const movieResponse = await MoviesService.createMovie(movieData);

    if (!movieResponse) {
      this.setState({ serverError: true });
      return null;
    }

    const servicePromises = services.map((service) => {
      const {
        serviceFile: file,
        serviceType: type,
        serviceLanguage: language,
      } = service;
      const filePath = `/assets/${tmsId}/${file.name}`;
      return ContentService.createContent(
        filePath,
        language,
        type,
        tmsId,
        'New'
      );
    });
    const serviceResponses = await Promise.all(servicePromises);

    if (serviceResponses.every((res) => !res)) {
      this.setState({ serverError: true });
      return null;
    }

    this.props.history.push(`/movies/${movieResponse.id}`);
  };

  handleFileSelect = (type, file, field, value) => {
    const newFileData = { ...this.state.sortedFiles };
    newFileData[type][file][field] = value;
    newFileData[type][file][`${field}Error`] = false;
    this.setState({ sortedFiles: newFileData });
  };

  handleFileClick = (action, name) => {
    const { files } = this.state;
    if (action === 'add') {
      this.setState({ addDrawer: 'File' });
    } else if (action === 'edit') {
      const targetFile = files.find((file) => file.serviceFile.name === name);
      this.setState({ addDrawer: 'File', selectedFile: targetFile });
    } else if (action === 'remove') {
      this.handleFileRemove(name);
    }
  };

  handleConfirmDetails = () => {
    const { sortedFiles, selectedMovie } = this.state;
    const newFiles = { ...sortedFiles };
    const formattedFiles: any = {
      fp: [],
      service: [],
    };
    let hasError = false;

    for (const file in sortedFiles.fp) {
      if (!sortedFiles.fp[file].language) {
        hasError = true;
        newFiles.fp[file].languageError = true;
      } else {
        const formattedFile: IFile = {
          id: file,
          language: sortedFiles.fp[file].language,
          file: file,
        };
        formattedFiles.fp.push(formattedFile);
      }
    }

    for (const file in sortedFiles.service) {
      if (!sortedFiles.service[file].language) {
        hasError = true;
        newFiles.service[file].languageError = true;
      }
      if (!sortedFiles.service[file].serviceType) {
        hasError = true;
        newFiles.service[file].serviceTypeError = true;
      }

      if (!hasError) {
        const formattedFile: IService = {
          id: file,
          type: sortedFiles.service[file].serviceType,
          language: sortedFiles.service[file].language,
          status: 'New',
          created: '',
          updated: '',
          file: file,
        };
        formattedFiles.service.push(formattedFile);
      }
    }

    if (hasError) {
      this.setState({ sortedFiles: newFiles });
    } else if (selectedMovie && selectedMovie.type === 'existing') {
      this.setState({ formattedFiles });
      this.handleAddNewAssets(formattedFiles);
    } else {
      this.setState({ currentStep: 3, formattedFiles });
    }
  };

  handleAddNewAssets = async (formattedFiles, duplicatePass?, removePass?) => {
    this.setState({ loadingAdd: true });
    const { selectedMovie, assetData } = this.state;
    const { tmsId, status } = selectedMovie || {};

    if (selectedMovie && assetData) {
      const duplicateFiles =
        selectedMovie.services?.filter((service) =>
          formattedFiles.service.find((file) => file.type === service.type)
        ) || [];

      if (duplicateFiles.length && !duplicatePass) {
        this.setState({ showModal: 'duplicate', duplicateFiles });
      } else if (
        formattedFiles.fp.length &&
        status === 'In Production' &&
        !removePass
      ) {
        this.setState({ showModal: 'remove' });
      } else {
        const servicePromises = formattedFiles.service.map((service) => {
          const { file, language, type } = service;
          return ContentService.createContent(
            file,
            language,
            type,
            tmsId,
            'QualityControl'
          );
        });
        const serviceResponses = await Promise.all(servicePromises);

        if (serviceResponses.length && serviceResponses.every((res) => !res)) {
          this.setState({ serverError: true, loadingAdd: false });
          return null;
        }

        const formattedFP = [...selectedMovie.files, ...formattedFiles.fp].map(
          ({ file, language }) => ({
            file,
            language,
          })
        );

        const movieResponse = await MoviesService.updateMovie(
          selectedMovie?.id,
          { fingerprintFiles: formattedFP }
        );

        if (!movieResponse) {
          this.setState({ serverError: true, loadingAdd: false });
          return null;
        }

        const assetResponse = await updateAsset(assetData.id, {
          newAsset: false,
        });
        if (!assetResponse) {
          this.setState({ serverError: true, loadingAdd: false });
          return null;
        }
        this.props.history.push(`/movies/${selectedMovie.id}`);
      }
    }
  };

  handleDuplicateClick = async (type) => {
    const { selectedMovie, duplicateFiles, formattedFiles } = this.state;
    if (type === 'replace' && selectedMovie && selectedMovie.services) {
      const deleteIds = duplicateFiles.map((file) => {
        const targetService = selectedMovie.services?.find(
          (ser) => ser.type === file.type
        );

        return targetService ? targetService.id : '';
      });
      const deletePromises = deleteIds.map((id) =>
        ContentService.deleteService(id)
      );
      await Promise.all(deletePromises);
    }
    this.handleAddNewAssets(formattedFiles, true);
  };

  handleRemoveFeature = async () => {
    const { selectedMovie, formattedFiles } = this.state;
    await MoviesService.updateMovie(selectedMovie?.id, {
      status: 'QualityControl',
    });
    this.handleAddNewAssets(formattedFiles, true, true);
  };

  toggleExpDateModal = (bool) => {
    this.setState({ showModal: bool ? 'expiration date' : '' });
  };

  handleChangeExpiration = async (radioValue, dateValue) => {
    const updateFields: any = {};

    if (radioValue === 'none') {
      updateFields.useCmsExpiration = false;
      updateFields.customEndTime = null;
    } else if (radioValue === 'manual') {
      const newDate = +dayjs(dateValue);
      updateFields.useCmsExpiration = false;
      updateFields.customEndTime = newDate;
    } else if (radioValue === 'eCMS') {
      updateFields.useCmsExpiration = true;
    }

    this.setState({ showModal: '', ...updateFields });
  };

  render() {
    const {
      results,
      currentStep,
      selectedMovie,
      selectedCategories,
      primaryCategory,
      files,
      services,
      serverError,
      loading,
      assetData,
      sortedFiles,
      formattedFiles,
      addDrawer,
      selectedFile,
      loadingAdd,
      showModal,
      endTime,
      customEndTime,
      useCmsExpiration,
    } = this.state;

    const { assetTitle, assetFiles } = assetData || {};
    const simpleTitle = assetTitle?.includes('_')
      ? assetTitle
          .split('_')[1]
          .split(/(?=[A-Z])/)
          .join(' ')
      : assetTitle;

    const displayMovieResults = results.map((movie) => (
      <MovieResult
        key={movie.tmsId}
        movie={movie}
        onAdd={this.handleAddClick}
        resultType="add"
      />
    ));

    const steps = {
      0: <KiteLoader />,
      1: (
        <>
          <p className="add-movie__step">
            Step 1: {assetData ? 'Choose a Movie' : 'Search for Movies to Add'}
          </p>
          <KiteCard className="add-movie__results-card">
            <p className="add-movie__title">Search by Title</p>
            <SearchBar
              featureType="Titles"
              onSearch={this.handleSearch}
              startingQuery={simpleTitle}
              showShadow={!results.length}
            />
            {!!results.length && (
              <p className="add-movie__title">
                Found Movies ({results.length})
              </p>
            )}
            {displayMovieResults}
            {!results.length && !loading && (
              <p className="add-movie__no-results">
                Search movies by title to view available movies
              </p>
            )}
            {loading && (
              <KiteLoader
                className="add-movie__loader"
                loaderTitle="Loading Movie Metadata"
                secondaryMessage="Searching for titles"
              />
            )}
          </KiteCard>
        </>
      ),
      2: assetData ? (
        <>
          <p className="add-movie__step">
            Step 2: Add and Confirm File Details
          </p>
          {assetFiles && (
            <AssetCards
              files={sortedFiles}
              onSelect={this.handleFileSelect}
              filePrefix="assets/movies/"
            />
          )}
          <div className="add-movie__button-wrapper">
            <KiteButton type="outline" onClick={this.handleBackClick}>
              Back
            </KiteButton>
            <KiteButton
              onClick={this.handleConfirmDetails}
              loading={loadingAdd}
            >
              {selectedMovie && selectedMovie.type === 'existing'
                ? 'Add New Assets'
                : 'Confirm Details'}
            </KiteButton>
          </div>
        </>
      ) : null,

      3: selectedMovie ? (
        <>
          <p className="add-movie__step">
            {assetData
              ? 'Step 3: Summary'
              : 'Step 2: Add Preferences and Files'}
          </p>
          <Metadata
            assetType="movie"
            data={selectedMovie}
            showAlert={false}
            onUpdate={() => {}}
            isNew
          />
          <ExpirationCard
            endTime={endTime}
            customEndTime={customEndTime}
            useCmsExpiration={useCmsExpiration}
            onEdit={() => this.toggleExpDateModal(true)}
          />
          <CountriesCard countries={['United States']} />
          <CategoriesCard
            status="New"
            selectedCategories={selectedCategories}
            primaryCategory={primaryCategory}
            showMessage={false}
            onSelectCategories={this.handleCategorySelect}
            onSelectPrimary={this.handlePrimaryCategorySelect}
            onTriggerWarning={this.handleCategoryWarning}
          />
          {assetData ? (
            <FilesCard
              files={formattedFiles.fp}
              status="Add"
              onButtonClick={() => {}}
              showAlert={false}
            />
          ) : (
            <FilesCard
              files={files}
              status="New"
              onButtonClick={this.handleFileClick}
              showAlert={false}
            />
          )}

          <FeatureServices
            services={assetData ? formattedFiles.service : services || []}
            onButtonClick={this.handleServicesClick}
            status="New"
            showAlert={false}
          />
          <div className="add-movie__button-wrapper">
            <KiteButton type="outline" onClick={this.handleBackClick}>
              Back
            </KiteButton>
            <KiteButton onClick={this.handleAddMovie} loading={loadingAdd}>
              Add Movie
            </KiteButton>
          </div>
        </>
      ) : null,
    };

    let backLink;

    if (assetData) {
      backLink = (
        <Link to="/new-assets" className="add-movie__back-link">
          <KiteIcon name="chevron-left" size="22px" /> Back to New Assets
        </Link>
      );
    } else {
      backLink = (
        <Link to="movies" className="add-movie__back-link">
          <KiteIcon name="chevron-left" size="22px" /> Back to Movies
        </Link>
      );
    }

    return (
      <div>
        <main className="add-movie">
          {backLink}
          <h2>
            {selectedMovie?.type === 'existing' ? 'Update' : 'Add'}{' '}
            {assetTitle || 'Movie'}
          </h2>
          {steps[currentStep]}
        </main>
        <AddDrawer
          type={addDrawer}
          isOpen={!!addDrawer}
          fileData={selectedFile}
          onClose={this.handleDrawerClose}
          onSubmit={this.handleFileSubmit}
          onDelete={this.handleFileDelete}
        />
        <ExpirationDateModal
          isOpen={showModal === 'expiration date'}
          onClose={() => this.toggleExpDateModal(false)}
          endTime={endTime}
          customEndTime={customEndTime}
          onSave={this.handleChangeExpiration}
          useCmsExpiration={useCmsExpiration}
        />
        <ServerError
          isOpen={serverError}
          onClose={() => this.setState({ serverError: false })}
        />
        <DuplicateServiceModal
          isOpen={showModal === 'duplicate'}
          onClick={this.handleDuplicateClick}
          onHide={() => this.setState({ showModal: '', loadingAdd: false })}
          services={['Closed Captioning']}
        />
        <RemoveFeatureUpdateModal
          isOpen={showModal === 'remove'}
          onRemove={this.handleRemoveFeature}
          onHide={() => this.setState({ showModal: '', loadingAdd: false })}
        />
      </div>
    );
  }
}

export default AddMovie;
