import axios, { AxiosRequestConfig } from 'axios';
import { ISeries } from '../utils/models';
import { INotesCardData } from '../components/NotesCard/NotesCard';
import { episodeCleaner } from './EpisodesService';

export const seriesCleaner = (series, useMetadata) => {
  const {
    id,
    createdAt,
    updatedAt,
    tmsId,
    title,
    status,
    notes,
    metadata,
    error,
    primaryCategory,
    primaryCategoryId,
    allCategories,
    seasons,
    episodes,
    network,
  } = series;

  const notesObject: INotesCardData = {
    updatedTimestamp: notes ? notes.split('---')[0] : '',
    notes: notes ? notes.split('---')[1] : '',
  };

  const posterUrl = `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Poster&width=250`;
  const epicUrl = `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Iconic&width=400`;

  const statusValues = {
    New: 'New',
    QualityControl: 'Quality Control',
    ReadyForProduction: 'Ready for Production',
    Production: 'In Production',
  };

  const cleanErrors = error ? error.split('---') : [];

  const cleanSeasons =
    seasons && episodes
      ? seasons.map((season) =>
          episodes
            .filter((ep) => ep.seasonNo === season)
            .map((ep) => episodeCleaner(ep, false, { title, tmsId }))
        )
      : [];

  const cleanSeries: ISeries = {
    id,
    tmsId,
    title,
    addedOn: createdAt,
    updatedOn: updatedAt,
    status: statusValues[status],
    categories: allCategories,
    primaryCategory,
    primaryCategoryId,
    errors: cleanErrors,
    rating: '',
    description: '',
    posterUrl,
    epicUrl,
    notes: notesObject,
    seasons: cleanSeasons,
    genre: '',
    network,
    unsyncedMetadata: false,
  };

  if (useMetadata && metadata) {
    const {
      description,
      ratings,
      genres,
      title: { en: updatedTitle },
      metadata: { groupTitle },
    } = metadata;

    const cleanGenres = genres.map((genre) => genre.name.en).join(', ');
    const newTitle = updatedTitle || groupTitle;

    if (newTitle !== title) {
      cleanSeries.unsyncedMetadata = true;
    }
    if (description) {
      cleanSeries.description = description.en;
    } else {
      cleanSeries.description = 'No description available';
    }

    cleanSeries.genre = cleanGenres;

    if (ratings && ratings.mpaa) {
      cleanSeries.rating = ratings.mpaa.value;
    } else if (ratings && ratings.usTv) {
      cleanSeries.rating = ratings.usTv.value;
    }
  } else if (useMetadata && !metadata) {
    cleanSeries.badMetadata = true;
  }

  return cleanSeries;
};

export const getAllSeries = async (config): Promise<any> => {
  const { order, page, sortBy, title, status, tmsId, size } = config;
  const headers = {
    title: 'title',
    status: 'status',
    addedOn: 'createdAt',
    updatedOn: 'updatedAt',
  };
  const formattedQueryWithNoSplChars = title
    ? title.replaceAll(/[:&?*!]/gm, '')
    : '';
  const titleQuery = title ? `&title=${formattedQueryWithNoSplChars}` : '';
  const statusQuery = status && status !== 'All' ? `&status=${status}` : '';
  const tmsIdQuery = tmsId ? `&tmsId=${tmsId}` : '';
  const sizeQuery = size ? `&size=${size}` : '';
  const res = await axios(
    `/api/v1/series?order=${order}&page=${page}${sizeQuery}&sortBy=${headers[sortBy]}${titleQuery}${statusQuery}${tmsIdQuery}`
  );
  const { content, totalPages, totalElements, numberByStatus } = res.data;

  const {
    New: totalNew,
    QualityControl: totalQC,
    ReadyForProduction: totalRFP,
    Production: totalProd,
  } = numberByStatus;

  const cleanSeries = content.map((movie) => seriesCleaner(movie, false));

  const totalSeries = totalNew + totalQC + totalRFP + totalProd;

  return {
    allSeries: cleanSeries,
    totalPages,
    totalSeries,
    totalNew,
    totalQC,
    totalRFP,
    totalProd,
  };
};

export const createSeries = async (seriesData) => {
  const {
    categories,
    countries,
    primaryCategory,
    tmsId,
    title,
    network,
  } = seriesData;
  const bodyData = {
    allCategoryIds: categories,
    countryWhitelist: countries,
    primaryCategoryId: primaryCategory,
    status: 'New',
    available: 'Yes',
    tmsId,
    title,
    network,
  };
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: JSON.stringify(bodyData),
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const res = await axios('/api/v1/series', options);
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getSeries = async (id) => {
  const res = await axios(`/api/v1/series/${id}?metadata=true`);
  return seriesCleaner(res.data, true);
};

export const getNewSeriesMetadata = async (id) => {
  const res = await axios(`/api/v1/series/${id}?metadata=true`);
  const {
    title,
    id: tmsId,
    metadata: { groupTitle },
  } = res.data.metadata;

  return {
    title: title.en || groupTitle,
    tmsId,
  };
};

export const updateSeries = async (id, fields) => {
  const updatedFields = { ...fields, available: 'Yes' };
  const options: AxiosRequestConfig = {
    method: 'PATCH',
    data: JSON.stringify(updatedFields),
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res = await axios(`/api/v1/series/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const deleteSeries = async (id) => {
  const options: AxiosRequestConfig = {
    method: 'DELETE',
  };
  try {
    const res = await axios(`/api/v1/series/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
