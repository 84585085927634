import axios, { AxiosRequestConfig } from 'axios';

const swimlaneCleaner = (swimlanes) => swimlanes.map(sl => {
  const isStatic = (sl.name === 'Continue Watching' || sl.name === 'My Movies and TV')
  return { ...sl, isStatic}
})

export const getAllSwimlanes = async () => {
  const res = await axios(
    '/api/v1/swimlanes?order=asc&page=0&size=-1&sortBy=priorityNumber'
  );
  return swimlaneCleaner(res.data.content);
};

export const createSwimlane = async (name: string, priorityNumber: number) => {
  const bodyData = {
    name,
    items: [],
    priorityNumber,
    status: "NotReady"
  };
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: JSON.stringify(bodyData),
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const res = await axios('/api/v1/swimlanes', options);
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteSwimlane = async (id) => {
  const options: AxiosRequestConfig = {
    method: 'DELETE',
  };
  try {
    const res = await axios(`/api/v1/swimlanes/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const updateSwimlane = async (id, fields) => {
  const options: AxiosRequestConfig = {
    method: 'PATCH',
    data: JSON.stringify(fields),
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res = await axios(`/api/v1/swimlanes/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getSwimlane = async (id) => {
  try {
    const res = await axios(`/api/v1/swimlanes/${id}`);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const addFeaturesToSwimlane = async (id, items) => {
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: JSON.stringify(items),
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const res = await axios(`/api/v1/swimlanes/${id}/bulk`, options);
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};