import { useEffect, useCallback } from 'react';

// Used for handling clicking and ESC keypress to close dropdown menus
const useClickOutside = (ref, callback) => {
  const handleClick = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    },
    [ref, callback]
  );

  const handleKeypress = useCallback(
    (e) => {
      if (ref.current && e.key === 'Escape') {
        callback();
      }
    },
    [ref, callback]
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('keydown', handleKeypress);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keydown', handleKeypress);
    };
  }, [handleClick, handleKeypress]);
};

export default useClickOutside;
