import React, { useState } from 'react';
import { KiteContextualMenu, KiteIcon, KiteSelect } from '@kite/react-kite';

import {
  getOrdinalSuffix,
  getStatusColor,
  getStatusIcon,
  sortPriority,
} from '../../utils/helpers';
import { ISwimlane } from '../../utils/models';
import './SwimlaneTiles.scss';

export interface ISwimlaneTilesProps {
  swimlaneData: ISwimlane;
  onMove: (id: string, action: 'up' | 'down' | 'bottom' | 'top') => void;
  onRemove: (id: string) => void;
  onSortChange: (order: 'Custom' | 'Alphabetical' | 'Date Added') => void;
}

const SwimlaneTiles = ({
  swimlaneData,
  onMove,
  onRemove,
  onSortChange,
}: ISwimlaneTilesProps) => {
  const [order, setOrder] = useState('Custom');

  const setSortOrder = (e) => {
    const newOrder = e.target.value;
    setOrder(newOrder);
    newOrder !== 'Custom' && onSortChange(newOrder);
  };

  const handleMenuClick = (action, id) => {
    if (action.value === 'remove') {
      onRemove(id);
    } else {
      setOrder('Custom');
      onMove(id, action.value);
    }
  };

  const handleChevronClick = (id, action) => {
    setOrder('Custom');
    onMove(id, action);
  };

  const { items } = swimlaneData;

  const menuItems = [
    {
      label: 'Move to Top',
      value: 'top',
    },
    {
      label: 'Move to Bottom',
      value: 'bottom',
    },
    {
      label: 'Remove from Swimlane',
      value: 'remove',
      render: <span style={{ color: '#d6312b' }}>Remove from Swimlane</span>,
    },
  ];

  const itemsToRender = items
    .filter((i) => i.priorityNumber)
    .sort(sortPriority());

  const tiles = itemsToRender.map(({ data, type, itemId }, i) => {
    const { title, tmsId, allCategories, status } = data;
    const posterUrl = `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Poster&width=50`;
    const path =
      type === 'movie'
        ? `${window.location.origin}/movies/${itemId}`
        : `${window.location.origin}/series/${itemId}`;
    const typeIcon = (
      <KiteIcon
        name={type === 'movie' ? 'ticket-f' : 'tv-f'}
        color="#9ba9bd"
        margin="0 8px 0 0"
      />
    );

    const categories = allCategories.map((c) => c.name).join(', ');

    return (
      <div className="swimlane-tiles__tile-wrapper" key={tmsId}>
        <p className="swimlane-tiles__tile-order">{getOrdinalSuffix(i + 1)}</p>
        <div className="swimlane-tiles__tile">
          <img src={posterUrl} alt={`${title} poster`} />
          <div className="swimlane-tiles__tile-details">
            <a
              className="swimlane-tiles__tile-link"
              href={path}
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}{' '}
              <KiteIcon
                name="chevron-right"
                color="#0073d8"
                size="12px"
                margin="0 0 0 4px"
              />
            </a>
            <p className="swimlane-tiles__tile-genre">
              {typeIcon}
              {type} • {categories}
            </p>
            <p className="swimlane-tiles__tile-status">
              <KiteIcon
                name={getStatusIcon(status)}
                color={getStatusColor(status)}
                margin="0 8px 0 0"
              />{' '}
              {status === 'Production' ? (
                'In Production'
              ) : (
                <span className="swimlane-tiles__tile-status--bold">
                  Not Visible in Production
                </span>
              )}
            </p>
          </div>
          <div className="swimlane-tiles__tile-btn-wrapper">
            {i === itemsToRender.length - 1 ? (
              <KiteIcon
                color="#9ba9bd"
                size="24px"
                margin="0 16px 0 0"
                name="chevron-down"
              />
            ) : (
              <KiteIcon
                name="chevron-down"
                color="#0073d1"
                size="24px"
                margin="0 16px 0 0"
                onClick={() => handleChevronClick(itemId, 'down')}
              />
            )}
            {i === 0 ? (
              <KiteIcon color="#9ba9bd" size="24px" name="chevron-up" />
            ) : (
              <KiteIcon
                name="chevron-up"
                color="#0073d1"
                size="24px"
                onClick={() => handleChevronClick(itemId, 'up')}
              />
            )}

            <KiteContextualMenu
              items={menuItems}
              onClick={(item) => handleMenuClick(item, itemId)}
            />
          </div>
        </div>
      </div>
    );
  });

  const total = items.filter((i) => i.priorityNumber).length;
  const totalVisible = items.filter(
    (item) => item.data.status === 'Production' && item.priorityNumber
  ).length;
  return (
    <div className="swimlane-tiles">
      <h2>
        Swimlane Tiles
        {!tiles.length && (
          <span className="swimlane-tiles__subheader">
            (At least 1 required)
          </span>
        )}
      </h2>

      <p className="swimlane-tiles__copy">
        These are the titles and the order they will shown on the home page.
      </p>

      <KiteSelect
        id="swimlane-order"
        name="order"
        label="Sort Order"
        value={order}
        onChange={setSortOrder}
        maxWidth="200px"
      >
        <option value="Custom">Custom</option>
        <option value="Alphabetical">Alphabetical</option>
        <option value="Date Added">Date Added</option>
      </KiteSelect>
      {!!tiles.length && (
        <p className="swimlane-status__date">
          <span className="swimlane-status__date-title">{total} Tiles</span> •{' '}
          {totalVisible} Visible in Production
        </p>
      )}
      <div className="swimlane-tiles__tile-wrapper">
        {tiles}
        <div className="swimlane-tiles__tile-wrapper">
          <p className="swimlane-tiles__tile-order">
            {getOrdinalSuffix(tiles.length + 1)}
          </p>
          <div className="swimlane-tiles__tile">
            <p className="swimlane-tiles__tile-placeholder">
              Add from Collection
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwimlaneTiles;
