import * as React from 'react';
import { Drawer, FileDrop } from '@kite/react-kite-plus';
import { KiteSelect, KiteButton } from '@kite/react-kite';

import { serviceTypes, IService, IFile, INewFile } from '../../utils/models';

import './AddDrawer.scss';

export interface IAddDrawerPops {
  type: 'Service' | 'File' | '';
  isOpen: boolean;
  fileData: IService | IFile | INewFile | null;
  onClose: () => any;
  onSubmit: (action: 'Add' | 'Update', data) => any;
  onDelete: (id: string) => void;
  onUpdateFilename?: (id: string) => void;
}

export interface IAddDrawerState {
  serviceType: string;
  serviceTypeError: boolean;
  serviceLanguage: string;
  serviceLanguageError: boolean;
  serviceFile: any[];
  serviceFileError: boolean;
}

export const initialState: IAddDrawerState = {
  serviceType: '',
  serviceTypeError: false,
  serviceLanguage: '',
  serviceLanguageError: false,
  serviceFile: [],
  serviceFileError: false,
};

class AddDrawer extends React.Component<IAddDrawerPops, IAddDrawerState> {
  state = initialState;

  componentDidUpdate(prevProps) {
    const { fileData: newFile } = this.props;
    if (prevProps.fileData !== newFile && newFile) {
      const isService = (file: any): file is IService => {
        return 'type' in file;
      };

      const isNewFile = (file: any): file is INewFile => {
        return 'serviceFile' in file;
      };

      const newLanguage = isNewFile(newFile)
        ? newFile.serviceLanguage
        : newFile.language;

      const newFileData = isNewFile(newFile) ? [newFile.serviceFile] : [];

      const type = isService(newFile) ? newFile.type : '';

      this.setState({
        serviceLanguage: newLanguage,
        serviceType: type,
        serviceFile: newFileData,
      });
    }
  }

  handleClose = () => {
    this.setState(initialState);
    this.props.onClose();
  };

  handleChange = (e) => {
    const { name: key, value } = e.target;

    if (Object.keys(this.state).includes(key)) {
      this.setState({ [key]: value, [key + 'Error']: false } as Pick<
        IAddDrawerState,
        keyof IAddDrawerState
      >);
    }
  };

  handleAdd = (e, addedFiles) => {
    this.setState({ serviceFile: addedFiles, serviceFileError: false });
  };

  validateForm = () => {
    const { serviceType, serviceLanguage, serviceFile } = this.state;
    const { type, fileData: existingFile } = this.props;

    if (type === 'Service' && !serviceType) {
      this.setState({ serviceTypeError: true });
    }
    if (!serviceLanguage) {
      this.setState({ serviceLanguageError: true });
    }
    if (!serviceFile.length && !existingFile) {
      this.setState({ serviceFileError: true });
    }

    const fileData: any = { serviceLanguage, serviceFile: serviceFile[0] };

    if (type === 'Service') {
      fileData.serviceType = serviceType;
    }

    if (Object.values(fileData).every((val) => !!val)) {
      return fileData;
    } else {
      return null;
    }
  };

  handleSubmit = (action) => {
    const { onSubmit } = this.props;
    const { serviceType, serviceLanguage: language } = this.state;

    const fileData = this.validateForm();
    if (action === 'Update Service' || action === 'Update File') {
      onSubmit(action, { serviceType, language });
      this.handleClose();
    } else if (fileData) {
      onSubmit(action, fileData);
      this.handleClose();
    }
  };

  handleDelete = () => {
    const { onDelete, fileData } = this.props;

    const isNewFile = (file: any): file is INewFile => {
      return 'serviceFile' in file;
    };

    if (fileData && isNewFile(fileData)) {
      onDelete(fileData.serviceFile.name);
      this.handleClose();
    } else if (fileData) {
      onDelete(fileData.id);
      this.handleClose();
    }
  };

  isNewFile = () => {
    const { fileData } = this.props;
    const localIsNewFile = (file: any): file is INewFile => {
      return 'serviceFile' in file;
    };

    return localIsNewFile(fileData || {});
  };

  render() {
    const { isOpen, type, fileData, onUpdateFilename } = this.props;
    const {
      serviceType,
      serviceTypeError,
      serviceLanguage,
      serviceLanguageError,
      serviceFile,
      serviceFileError,
    } = this.state;

    const serviceOptions = serviceTypes.map((type) => (
      <option key={type} value={type}>
        {type}
      </option>
    ));

    const languages = [
      { label: 'English', value: 'en' },
      { label: 'Spanish', value: 'es' },
    ];

    const languageOptions = languages.map(({ value, label }) => (
      <option value={value} key={value}>
        {label}
      </option>
    ));

    const placeholderOption = <option value="" disabled></option>;

    const languageLabel =
      type === 'Service'
        ? 'Select the Language of this Service File'
        : 'Select File Language';

    const heading = `${fileData ? 'Update' : 'Add'} ${type}`;

    const deleteCard = (
      <div className="add-drawer__delete-card">
        <p className="add-drawer__delete-title">Delete</p>
        <p className="add-drawer__delete-copy">
          This will permanently delete this file from Spectrum Access.
        </p>
        <KiteButton
          className="add-drawer__delete-button"
          type="outline"
          onClick={this.handleDelete}
        >
          Delete File
        </KiteButton>
      </div>
    );

    const renderFileNameCard = () => {
      const isNewFile = (file: any): file is INewFile => {
        return 'serviceFile' in file;
      };
      if (!isNewFile(fileData) && onUpdateFilename && fileData) {
        return (
          <div className="add-drawer__filename-card">
            <p className="add-drawer__filename-title">Update File Name</p>
            <p className="add-drawer__filename-copy">
              ...{fileData.file.split('').slice(-50).join('')}
            </p>
            <KiteButton
              className="add-drawer__filename-button"
              type="outline"
              onClick={() => onUpdateFilename(fileData.file)}
            >
              Update Name
            </KiteButton>
          </div>
        );
      } else {
        return null;
      }
    };
    return (
      <Drawer
        isOpen={isOpen}
        disableBackgroundClose
        className="add-drawer"
        onClose={this.handleClose}
      >
        <h2>{heading}</h2>
        {fileData && renderFileNameCard()}
        {type === 'Service' && (
          <KiteSelect
            id="add-drawer-type"
            name="serviceType"
            label="Select a Service Type"
            value={serviceType}
            onChange={this.handleChange}
            errorMessage={
              serviceTypeError ? 'Please select a service type' : ''
            }
          >
            {placeholderOption}
            {serviceOptions}
          </KiteSelect>
        )}

        <KiteSelect
          id="add-drawer-language"
          name="serviceLanguage"
          label={languageLabel}
          value={serviceLanguage}
          onChange={this.handleChange}
          errorMessage={serviceLanguageError ? 'Please select a language' : ''}
        >
          {placeholderOption}
          {languageOptions}
        </KiteSelect>

        {!fileData && (
          <FileDrop
            className="add-drawer__upload"
            accept=""
            label="Add the Raw File"
            iconName="folder"
            files={serviceFile}
            onAdd={this.handleAdd}
            onRemove={() => this.setState({ serviceFile: [] })}
            showPreview={false}
          />
        )}
        {serviceFileError && (
          <p className="add-drawer__upload-error">
            Please upload a {type === 'Service' ? 'service' : 'fingerprint'}{' '}
            file
          </p>
        )}
        <KiteButton
          className="add-drawer__submit"
          onClick={() => this.handleSubmit(heading)}
        >
          {heading}
        </KiteButton>

        {fileData && !this.isNewFile() && deleteCard}
      </Drawer>
    );
  }
}

export default AddDrawer;
