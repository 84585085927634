import React, { Component } from 'react';
import { RouterComponentProps } from 'react-router-dom';

// Components
import {
  CategoriesCard,
  CountriesCard,
  DeleteFeature,
  DeleteFeatureCard,
  LanguagesCard,
  Metadata,
  NotesCard,
  StatusBar,
  Subheader,
  Notes,
  ErrorsCard,
  EpisodesTable,
  StatusCounter,
  SearchBar,
  UnresolvedErrors,
  PublishFeature,
  RemoveFeature,
  NetworkCard,
  SearchModal,
  InvalidTMSIDModal,
  AddErrorModal,
} from '../../components';

// Utils
import { ISeries, ICategory, IEpisode } from '../../utils/models';
import './Series.scss';
import { KiteSelect, KiteIcon } from '@kite/react-kite';
import {
  getSeries,
  updateSeries,
  deleteSeries,
  getNewSeriesMetadata,
} from '../../apiCalls/SeriesService';
import UnableToDeleteModal from '../../components/UnableToDeleteModal';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';

interface ISeriesPageState {
  showModal:
    | ''
    | 'notes'
    | 'delete'
    | 'unresolved errors'
    | 'publish feature'
    | 'remove'
    | 'error'
    | 'unable to delete'
    | 'update metadata'
    | 'invalid TMSID';
  seriesData: ISeries | null;
  statusFilter: string;
  selectedCategories: ICategory[];
  primaryCategory: ICategory | null;
  showCategoryWarning: boolean;
  ariaLiveContent: string;
  showMetadataAlert: boolean;
  showCategoriesAlert: boolean;
  showNotesAlert: boolean;
  currentTab: string;
  currentSeason: string;
  seriesID: string;
  searchResults: IEpisode[];
  showSearchResults: boolean;
  loadingButton: string;
  analytics: any;
  showNetworkAlert: boolean;
  showInvalidTMSIDModalOverride: boolean;
  unsyncedMetadataOverride: boolean;
}

class Series extends Component<RouterComponentProps, ISeriesPageState> {
  state: ISeriesPageState = {
    showModal: '',
    seriesData: null,
    statusFilter: 'All',
    selectedCategories: [],
    primaryCategory: null,
    showCategoryWarning: false,
    ariaLiveContent: '',
    showMetadataAlert: false,
    showCategoriesAlert: false,
    showNotesAlert: false,
    currentTab: 'Details',
    currentSeason: '1',
    seriesID: '',
    searchResults: [],
    showSearchResults: false,
    loadingButton: '',
    analytics: null,
    showNetworkAlert: false,
    showInvalidTMSIDModalOverride: false,
    unsyncedMetadataOverride: false,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    const [analytics] = this.context;
    this.setState({ analytics });
    this.updateSeriesData(true);
  }

  updateSeriesData = async (trackPageView?) => {
    const {
      match: {
        params: { series_id },
      },
      location: { search },
    } = this.props;
    const showSeasons = new URLSearchParams(search).get('seasons') === 'true';

    await getSeries(series_id).then((seriesData) => {
      this.setState({
        seriesData,
        selectedCategories: seriesData.categories,
        seriesID: series_id,
        currentTab: showSeasons
          ? `Seasons (${seriesData.seasons.length})`
          : 'Details',
        currentSeason: seriesData.seasons[0]
          ? `${seriesData.seasons[0][0].season}`
          : '',
        loadingButton: '',
      });

      if (seriesData.unsyncedMetadata && !this.state.unsyncedMetadataOverride) {
        this.handleUnsyncedMetadata();
      }
      if (seriesData.badMetadata && !this.state.showInvalidTMSIDModalOverride) {
        this.handleBadMetadata();
      }
      if (trackPageView) {
        this.state.analytics.trackPageView(`Series - ${seriesData.title}`);
      }
    });
  };

  handleUnsyncedMetadata = () => {
    this.removeFromProduction(['Unsynced metadata'], '');
    this.setState({ unsyncedMetadataOverride: true });
  };

  handleBadMetadata = () => {
    this.removeFromProduction(['Invalid TMSID'], '');
    this.setState({ showModal: 'invalid TMSID' });
  };

  handleStatusClick = async (status) => {
    const { id } = this.state.seriesData || {};
    if (status === 'Quality Control' || status === 'Back to Quality Control') {
      this.setState({ loadingButton: 'statusQC' });

      await updateSeries(id, { status: 'QualityControl' });
      this.updateSeriesData();
    } else if (status === 'Ready for Production') {
      if (this.state.seriesData?.errors.length) {
        this.setState({ showModal: 'unresolved errors' });
      } else {
        this.setState({ loadingButton: 'statusRFP' });

        await updateSeries(id, { status: 'ReadyForProduction' });
        this.updateSeriesData();
      }
    } else if (status === 'Publish to Production') {
      this.setState({ showModal: 'publish feature' });
    } else if (status === 'Remove from Production') {
      this.setState({ showModal: 'remove' });
    } else if (status === 'Add Errors') {
      this.setState({ showModal: 'error' });
    }
  };

  moveToProduction = async () => {
    this.setState({ loadingButton: 'statusPP' });

    const { id } = this.state.seriesData || {};

    await updateSeries(id, { status: 'Production' });
    this.state.analytics.track('selectAction', {
      msgCategory: 'navigation',
      msgTriggeredBy: 'user',
      opType: 'buttonClick',
      currPageElemStdName: 'publish',
      currPageElemUiName: 'Move to Production',
    });
    this.updateSeriesData();
  };

  removeFromProduction = async (
    selectedErrors: string[],
    errorDetails: string
  ) => {
    this.setState({ loadingButton: 'statusRP' });

    const { id } = this.state.seriesData || {};
    const formattedErrors = `${selectedErrors.join('---')}${
      errorDetails ? `---Other - ${errorDetails}` : ``
    }`;

    await updateSeries(id, {
      status: 'QualityControl',
      error: formattedErrors,
    });
    this.updateSeriesData();
  };

  addErrors = async (selectedErrors: string[], errorDetails: string) => {
    this.setState({ loadingButton: 'addErrors' });
    const { id } = this.state.seriesData || {};

    const formattedErrors = `${selectedErrors.join('---')}${
      errorDetails ? `---Other - ${errorDetails}` : ``
    }`;

    await updateSeries(id, {
      error: formattedErrors,
    });

    this.updateSeriesData();
  };

  handleMetadataSearch = async () => {
    this.setState({ showModal: 'update metadata' });
  };

  handleUpdateMetadata = async (tmsId, title) => {
    const { id } = this.state.seriesData || {};

    await updateSeries(id, {
      tmsId,
      title,
    });

    this.updateSeriesData();
    this.setState({
      showMetadataAlert: true,
      ariaLiveContent: 'Metadata changes saved',
    });

    setTimeout(() => {
      this.setState({
        showMetadataAlert: false,
        ariaLiveContent: '',
      });
    }, 3000);
  };

  handleChangeTSMID = (tmsId, title) => {
    this.setState({ loadingButton: 'metadata', showModal: '' });
    this.handleUpdateMetadata(tmsId, title);
  };

  handleRefresh = async () => {
    this.setState({
      loadingButton: 'refresh metadata',
      unsyncedMetadataOverride: false,
    });
    const { id } = this.state.seriesData || {};
    const { tmsId, title } = await getNewSeriesMetadata(id);
    this.handleUpdateMetadata(tmsId, title);
  };

  handleCategorySelect = async (categories) => {
    const { id } = this.state.seriesData || {};
    if (categories.length) {
      this.setState({ selectedCategories: categories });

      await updateSeries(id, {
        allCategoryIds: categories.map((cat) => cat.id),
      });
      this.updateSeriesData();

      this.setState({
        showCategoriesAlert: true,
        ariaLiveContent: 'Categories changes saved',
      });

      setTimeout(() => {
        this.setState({
          showCategoriesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    }
  };

  handlePrimaryCategorySelect = async (category) => {
    const { id } = this.state.seriesData || {};
    if (category) {
      this.setState({ primaryCategory: category });

      await updateSeries(id, {
        primaryCategoryId: category.id,
      });
      this.updateSeriesData();

      this.setState({
        showCategoriesAlert: true,
        ariaLiveContent: 'Primary category changes saved',
      });

      setTimeout(() => {
        this.setState({
          showCategoriesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    }
  };

  handleCategoryWarning = () => {
    console.log('Category Warning');
  };

  toggleNotesModal = (bool) => {
    this.setState({ showModal: bool ? 'notes' : '' });
  };

  toggleDeleteModal = (bool) => {
    this.setState({ showModal: bool ? 'delete' : '' });
  };

  handleNotesSave = async (notes) => {
    const { id } = this.state.seriesData || {};
    await updateSeries(id, { notes: `${new Date()}---${notes}` });
    await this.updateSeriesData();
    this.setState({
      showNotesAlert: true,
      ariaLiveContent: 'Notes changes saved',
    });

    setTimeout(() => {
      this.setState({
        showNotesAlert: false,
        ariaLiveContent: '',
      });
    }, 3000);
  };

  handleResolveErrors = async () => {
    this.setState({ loadingButton: 'errorsResolved' });

    const { id } = this.state.seriesData || {};
    await updateSeries(id, { error: null });
    this.updateSeriesData();
  };

  handleDeleteFeature = async () => {
    this.setState({ loadingButton: 'deleteFeature' });

    const { seriesData } = this.state;
    const { id } = seriesData || {};

    await deleteSeries(id);
    this.props.history.push('/series');
  };

  handleTabChange = (tab) => {
    this.setState({ currentTab: tab });
  };

  handleSeasonChange = (e) => {
    this.setState({ currentSeason: e.target.value });
  };

  handleFilterChange = (newFilter) => {
    this.setState({ statusFilter: newFilter });
  };

  handleSearch = (query) => {
    const { seriesData, currentSeason } = this.state;
    const { seasons } = seriesData || {};

    if (query) {
      const currentSeasonData = seasons
        ? seasons.filter((season) => season[0].season === +currentSeason)[0]
        : [];

      const results = currentSeasonData.filter((ep) =>
        ep.title.toUpperCase().includes(query.toUpperCase())
      );
      this.setState({ searchResults: results, showSearchResults: true });
    } else {
      this.setState({ searchResults: [], showSearchResults: false });
    }
  };

  handleDeleteClick = () => {
    const { seriesData } = this.state;
    const { seasons } = seriesData || {};
    const episodeCount = seasons ? seasons.flat().length : 0;
    if (episodeCount) {
      this.setState({ showModal: 'unable to delete' });
    } else {
      this.setState({ showModal: 'delete' });
    }
  };

  handleNetworkSelect = async (network) => {
    const { id } = this.state.seriesData || {};

    await updateSeries(id, { network });
    this.updateSeriesData();

    this.setState({
      showNetworkAlert: true,
      ariaLiveContent: 'Network changes saved',
    });

    setTimeout(() => {
      this.setState({
        showNetworkAlert: false,
        ariaLiveContent: '',
      });
    }, 3000);
  };

  render() {
    const {
      showModal,
      seriesData,
      statusFilter,
      selectedCategories,
      primaryCategory,
      ariaLiveContent,
      showMetadataAlert,
      showCategoriesAlert,
      showNotesAlert,
      currentTab,
      currentSeason,
      searchResults,
      showSearchResults,
      loadingButton,
      showNetworkAlert,
    } = this.state;

    let displayPage;

    const errorOptions = [
      'Incorrect Metadata',
      'Incorrect Preferences',
      'Incorrect Categories',
      'Other',
    ];

    if (seriesData && currentTab === 'Details') {
      const {
        title,
        status,
        notes,
        errors,
        seasons,
        network,
        badMetadata,
        unsyncedMetadata,
      } = seriesData || {};

      const languages = seasons.reduce((acc: string[], season) => {
        season.forEach((ep) =>
          ep.services.forEach((service) => {
            if (!acc.includes(service.language)) {
              acc.push(service.language);
            }
          })
        );
        return acc;
      }, []);

      displayPage = (
        <>
          <Subheader
            featureTitle={title}
            featureType="series"
            currentTab={currentTab}
            onSelectTab={this.handleTabChange}
            status={status}
            seasonCount={seasons.length}
          />
          <main className="series">
            {!!errors.length && (
              <ErrorsCard
                errors={errors}
                onButtonClick={this.handleResolveErrors}
                loadingButton={loadingButton}
              />
            )}
            <StatusBar
              status={status}
              onButtonClick={this.handleStatusClick}
              loadingButton={loadingButton}
            />
            <Metadata
              assetType="series"
              data={seriesData}
              onUpdate={this.handleMetadataSearch}
              onRefresh={this.handleRefresh}
              showAlert={showMetadataAlert}
              loadingButton={loadingButton}
              badMetadata={badMetadata || unsyncedMetadata}
            />
            <LanguagesCard languages={languages} />
            <CountriesCard countries={['United States']} />
            <CategoriesCard
              status={status}
              selectedCategories={selectedCategories}
              primaryCategory={primaryCategory}
              showMessage={showCategoriesAlert}
              onSelectCategories={this.handleCategorySelect}
              onSelectPrimary={this.handlePrimaryCategorySelect}
              onTriggerWarning={this.handleCategoryWarning}
            />
            <NetworkCard
              status={status}
              showMessage={showNetworkAlert}
              network={network}
              onSelect={this.handleNetworkSelect}
            />
            <NotesCard
              notesData={notes}
              onEdit={() => this.toggleNotesModal(true)}
              showAlert={showNotesAlert}
            />
            <DeleteFeatureCard
              onDelete={this.handleDeleteClick}
              loadingButton={loadingButton}
            />
          </main>
          <div className="sr-only" aria-live="polite" aria-atomic="true">
            {ariaLiveContent}
          </div>
          <Notes
            isOpen={showModal === 'notes'}
            onClose={() => this.toggleNotesModal(false)}
            onSave={this.handleNotesSave}
            notes={notes}
          />
          <DeleteFeature
            assetType="feature"
            assetTitle={title}
            isOpen={showModal === 'delete'}
            onHide={() => this.toggleDeleteModal(false)}
            onDelete={this.handleDeleteFeature}
          />
          <UnresolvedErrors
            isOpen={showModal === 'unresolved errors'}
            onClose={() => this.setState({ showModal: '' })}
            errors={errors}
          />
          <PublishFeature
            isOpen={showModal === 'publish feature'}
            onHide={() => this.setState({ showModal: '' })}
            onPublish={this.moveToProduction}
            featureType="series"
          />
          <RemoveFeature
            isOpen={showModal === 'remove'}
            assetType="series"
            errorOptions={errorOptions}
            onHide={() => this.setState({ showModal: '' })}
            onRemove={this.removeFromProduction}
          />
          <AddErrorModal
            isOpen={showModal === 'error'}
            errorOptions={errorOptions}
            onHide={() => this.setState({ showModal: '' })}
            onAdd={this.addErrors}
          />
          <UnableToDeleteModal
            isOpen={showModal === 'unable to delete'}
            onClose={() => this.setState({ showModal: '' })}
            episodeCount={seasons.flat().length}
          />
          <SearchModal
            isOpen={showModal === 'update metadata'}
            onClose={() => this.setState({ showModal: '' })}
            featureType="series"
            title={title}
            onUpdate={this.handleChangeTSMID}
          />
          <InvalidTMSIDModal
            isOpen={showModal === 'invalid TMSID'}
            onClose={() =>
              this.setState({
                showModal: '',
                showInvalidTMSIDModalOverride: true,
              })
            }
            onClick={() => {
              this.setState({
                showModal: '',
                showInvalidTMSIDModalOverride: true,
              });
              this.setState({
                showModal: 'update metadata',
              });
            }}
            featureType="series"
          />
        </>
      );
    } else if (seriesData && currentSeason) {
      const { title, status, seasons } = seriesData || {};

      const seasonOptions = seasons.map((s, i) => {
        const seasonNum = s[0].season;
        return <option value={seasonNum}>{`Season ${seasonNum}`}</option>;
      });

      const currentSeasonUnfilteredData =
        seasons.find((s) => s[0].season === +currentSeason) || [];

      const statusFilterOptions = {
        New: 'New',
        QualityControl: 'Quality Control',
        ReadyForProduction: 'Ready for Production',
        Production: 'In Production',
      };

      const currentSeasonData =
        statusFilter !== 'All'
          ? currentSeasonUnfilteredData.filter(
              (season) => season.status === statusFilterOptions[statusFilter]
            )
          : currentSeasonUnfilteredData;

      const statusCounts = [
        {
          label: 'Total Episodes',
          count: currentSeasonData.length,
        },
        {
          label: 'Newly Added',
          count: currentSeasonData.filter((episode) => episode.status === 'New')
            .length,
        },
        {
          label: 'In Quality Control',
          count: currentSeasonData.filter(
            (episode) => episode.status === 'Quality Control'
          ).length,
        },
        {
          label: 'Ready for Production',
          count: currentSeasonData.filter(
            (episode) => episode.status === 'Ready for Production'
          ).length,
        },
        {
          label: 'In Production',
          count: currentSeasonData.filter(
            (episode) => episode.status === 'In Production'
          ).length,
        },
      ];

      displayPage = (
        <>
          <Subheader
            featureTitle={title}
            featureType="series"
            currentTab={currentTab}
            onSelectTab={this.handleTabChange}
            status={status}
            seasonCount={seasons.length}
          />
          <main className="season">
            <KiteSelect
              className="season__select"
              id="series-season-select"
              name="series-season-select"
              label="Select a Season to View"
              value={currentSeason}
              onChange={this.handleSeasonChange}
            >
              {seasonOptions}
            </KiteSelect>

            <h3>Season {currentSeason}</h3>
            <StatusCounter statusCounterData={statusCounts} />
            <SearchBar
              featureType="Episodes"
              statusFilter={statusFilter}
              onFilterSelect={this.handleFilterChange}
              onSearch={this.handleSearch}
              showShadow={false}
              statusCounts={statusCounts}
            />
            <EpisodesTable
              episodeData={
                showSearchResults ? searchResults : currentSeasonData
              }
            />
          </main>
        </>
      );
    } else if (seriesData && !currentSeason) {
      const { title, status, seasons } = seriesData || {};

      displayPage = (
        <>
          <Subheader
            featureTitle={title}
            featureType="series"
            currentTab={currentTab}
            onSelectTab={this.handleTabChange}
            status={status}
            seasonCount={seasons.length}
          />
          <main className="season">
            <div className="season__none">
              <KiteIcon name="tv-f" color="#d8dde6" size="200px" />
              <p className="season__none-title">This series has no seasons</p>
              <p className="season__none-copy">
                Please add an episode or delete this series
              </p>
            </div>
          </main>
        </>
      );
    }
    return <div>{displayPage}</div>;
  }
}

export default Series;
