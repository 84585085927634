import React, { useState, useEffect } from 'react';
import { KiteButton, KiteLoader, KiteModal } from '@kite/react-kite';
import classnames from 'classnames';

import './FileNameModal.scss';

export interface IFileNameModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApply: (fileName: string) => void;
  filenames: string[];
}

const FileNameModal = ({
  isOpen,
  onClose,
  onApply,
  filenames,
}: IFileNameModalProps) => {
  const [selectedName, setSelectedName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      setSelectedName('');
      setLoading(false);
    };
  }, [isOpen]);

  const handleApply = () => {
    setLoading(true);
    onApply(selectedName);
  };

  const fileOptions = filenames.map((name) => (
    <li
      className={classnames({
        'filename-modal__option': true,
        'filename-modal__option--selected': selectedName === name,
      })}
      onClick={() => setSelectedName(name)}
      key={name}
    >
      {name}
    </li>
  ));

  return (
    <KiteModal
      className="filename-modal"
      canShow={isOpen}
      title="Update File Name"
      disableBackgroundClose
      showCloseButton={false}
    >
      <p className="filename-modal__copy">
        Select from available files to match it to the correct name.{' '}
      </p>
      {filenames.length ? (
        <ul className="filename-modal__list">{fileOptions} </ul>
      ) : (
        <KiteLoader />
      )}
      <div className="filename-modal__btn-wrapper">
        <KiteButton type="outline" onClick={onClose}>
          Cancel
        </KiteButton>
        <KiteButton
          disabled={!selectedName}
          onClick={handleApply}
          loading={loading}
        >
          Apply
        </KiteButton>
      </div>
    </KiteModal>
  );
};

export default FileNameModal;
