import React from 'react';

import { KiteCard } from '@kite/react-kite';
// import { BasicPills } from '@kite/react-kite-plus';

import './SeriesData.scss';

export interface ISeriesDataProps {
  title: string;
  tmsID: string;
  isNew: boolean;
}

const SeriesData = ({ title, tmsID, isNew }: ISeriesDataProps) => {
  return (
    <KiteCard className="series-data">
      <h3 className="series-data__title">Series</h3>
      {isNew && <span className="series-data__badge">New Series</span>}
      <p className="series-data__field">
        <span className="series-data__field-title">Title</span>
        {title}
      </p>
      <p className="series-data__field">
        <span className="series-data__field-title">TMS ID</span>
        {tmsID}
      </p>
    </KiteCard>
  );
};

export default SeriesData;
