import React from 'react';

import './ServiceIcon.scss';
import { KiteIcon } from '@kite/react-kite';
import { iconNames } from '../../utils/helpers';

export interface IServiceIconProps {
  type: string;
  status: string;
  language: string;
}

const ServiceIcon = ({ type, status, language }: IServiceIconProps) => {
  const icon = (
    <KiteIcon
      name={iconNames[type]}
      size="18px"
      color={status === 'In Production' ? '#008516' : '#9ba9bd'}
      ariaLabel={type}
    />
  );

  const bubble = (
    <div className="service-icon__bubble-bg">
      <div className="service-icon__bubble">
        <div className="invisible-hover-area" />
        {type}
        <br />
        Language: {language}
      </div>
    </div>
  );
  return (
    <span className="service-icon">
      {icon}
      {bubble}
    </span>
  );
};

export default ServiceIcon;
