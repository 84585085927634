import React from 'react';
import { KiteCard, KiteButton, KiteAlert } from '@kite/react-kite';
import dayjs from 'dayjs';

import './NotesCard.scss';
import { CSSTransition } from 'react-transition-group';

export interface INotesCardProps {
  notesData: INotesCardData;
  onEdit: () => any;
  showAlert: boolean;
}

export interface INotesCardData {
  updatedTimestamp: string;
  notes: string;
}

// TODO: Add Empty State when designs are ready
const NotesCard = ({ notesData, onEdit, showAlert }: INotesCardProps) => {
  const { updatedTimestamp, notes } = notesData;

  const parseTimeStamp = (timeStamp: string) =>
    dayjs(timeStamp).format('MM/DD/YYYY');

  return (
    <KiteCard className="notes-card">
      <div>
        <div className="notes-card__heading-wrapper">
          <h3 className="notes-card__title">Notes</h3>
          <CSSTransition
            classNames="changes-message"
            in={showAlert}
            unmountOnExit
            timeout={400}
          >
            <>
              <KiteAlert
                level="inline"
                type="confirm"
                description="Changes Saved"
              />
            </>
          </CSSTransition>
        </div>

        {updatedTimestamp ? (
          <p className="notes-card__subtitle">
            Updated {parseTimeStamp(updatedTimestamp)}
          </p>
        ) : null}

        {notes ? <p className="notes-card__copy">{notes}</p> : null}
      </div>
      <KiteButton
        className="notes-card__button"
        type="outline"
        onClick={onEdit}
      >
        Edit Notes
      </KiteButton>
    </KiteCard>
  );
};

export default NotesCard;
