import axios, { AxiosRequestConfig } from 'axios';
import { INotesCardData } from '../components/NotesCard/NotesCard';
import { serviceCleaner } from './MoviesService';
import { IEpisode } from '../utils/models';

export const episodeCleaner = (episode, useMetadata, seriesData) => {
  const {
    id,
    createdAt,
    updatedAt,
    tmsId,
    title,
    status,
    fingerprintFiles,
    metadata,
    error,
    notes,
    contents,
    seasonNo,
    episodeNo,
    series,
    seriesId,
    endTime,
    useCmsExpiration,
    customEndTime,
  } = episode;

  const { title: seriesTitle, tmsId: seriesTmsid } = series || seriesData;

  const posterUrl = `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Poster&width=250`;
  const epicUrl = `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Iconic&width=400`;

  const notesObject: INotesCardData = {
    updatedTimestamp: notes ? notes.split('---')[0] : '',
    notes: notes ? notes.split('---')[1] : '',
  };
  const statusValues = {
    New: 'New',
    QualityControl: 'Quality Control',
    ReadyForProduction: 'Ready for Production',
    Production: 'In Production',
  };

  const cleanServices: any[] = contents
    ? contents
        .map((content) => serviceCleaner(content, statusValues))
        .sort((a, b) => a.type.localeCompare(b.type))
    : [];

  const cleanFiles = fingerprintFiles.map((file) => ({
    id: file.file,
    language: file.language,
    file: file.file,
  }));

  const cleanErrors = error ? error.split('---') : [];

  const cleanEpisode: IEpisode = {
    id,
    tmsId,
    title,
    addedOn: createdAt,
    updatedOn: updatedAt,
    status: statusValues[status],
    services: cleanServices,
    errors: cleanErrors,
    year: 0,
    rating: '',
    description: '',
    posterUrl,
    epicUrl,
    notes: notesObject,
    files: cleanFiles,
    number: episodeNo,
    season: seasonNo,
    seriesTitle,
    seriesTmsid,
    seriesId,
    badMetadata: false,
    unsyncedMetadata: false,
    endTime,
    useCmsExpiration,
    customEndTime,
  };

  if (useMetadata && metadata) {
    const {
      releaseYear,
      description: descObject,
      ratings,
      episodeTitle: { en: updatedTitle },
      episodeNum,
      seasonNum,
    } = metadata;
    const { en: description } = descObject || {};

    cleanEpisode.year = releaseYear;
    cleanEpisode.description = description;

    if (
      (episodeNo !== episodeNum ||
        seasonNo !== seasonNum ||
        title !== updatedTitle) &&
      title !== 'Demo Clip'
    ) {
      cleanEpisode.unsyncedMetadata = true;
    }
    if (ratings && ratings.mpaa) {
      cleanEpisode.rating = ratings.mpaa.value;
    } else if (ratings && ratings.usTv) {
      cleanEpisode.rating = ratings.usTv.value;
    }
  } else if (useMetadata && !metadata) {
    cleanEpisode.badMetadata = true;
  }

  return cleanEpisode;
};

export const createEpisode = async (episodeData) => {
  const {
    fpFiles,
    tmsId,
    title,
    episodeNo,
    seasonNo,
    seriesId,
    useCmsExpiration,
    endTime,
    customEndTime,
  } = episodeData;
  const bodyData = {
    fingerprintFiles: fpFiles,
    status: 'New',
    available: 'Yes',
    tmsId,
    title,
    episodeNo,
    seasonNo,
    seriesId,
    useCmsExpiration,
    endTime,
    customEndTime,
  };
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: JSON.stringify(bodyData),
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const res = await axios('/api/v1/episodes', options);
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getEpisode = async (id) => {
  const res = await axios(`/api/v1/episodes/${id}?metadata=true`);
  return episodeCleaner(res.data, true, false);
};

export const getNewEpisodeMetadata = async (id) => {
  const res = await axios(`/api/v1/episodes/${id}?metadata=true`);

  const {
    id: tmsId,
    episodeTitle: { en: title },
    seasonNum: newSeason,
    episodeNum: episode,
  } = res.data.metadata;

  return {
    tmsId,
    title,
    newSeason,
    episode,
  };
};

export const updateEpisode = async (id, fields) => {
  const updatedFields = { ...fields, available: 'Yes' };
  const options: AxiosRequestConfig = {
    method: 'PATCH',
    data: JSON.stringify(updatedFields),
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res = await axios(`/api/v1/episodes/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const deleteEpisode = async (id) => {
  const options: AxiosRequestConfig = {
    method: 'DELETE',
  };
  try {
    const res = await axios(`/api/v1/episodes/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getAllEpisodes = async (config) => {
  const { order, page, sortBy, title, status, size } = config;
  const headers = {
    title: 'title',
    status: 'status',
    addedOn: 'createdAt',
    updatedOn: 'updatedAt',
  };
  const formattedQueryWithNoSplChars = title
    ? title.replaceAll(/[:&?*!]/gm, '')
    : '';
  const titleQuery = title ? `&title=${formattedQueryWithNoSplChars}` : '';
  const statusQuery = status && status !== 'All' ? `&status=${status}` : '';
  const sizeQuery = size ? `&size=${size}` : '';
  const res = await axios(
    `/api/v1/episodes?order=${order}&page=${page}${sizeQuery}&sortBy=${headers[sortBy]}${titleQuery}${statusQuery}`
  );

  const { content, totalPages, totalElements, numberByStatus } = res.data;

  const {
    New: totalNew,
    QualityControl: totalQC,
    ReadyForProduction: totalRFP,
    Production: totalProd,
  } = numberByStatus;

  const goodEpisodes = content.filter((ep) => ep.series);

  const cleanEpisodes = goodEpisodes.map((episode) =>
    episodeCleaner(episode, false, episode.series)
  );

  const totalEpisodes = totalNew + totalQC + totalRFP + totalProd;

  return {
    allEpisodes: cleanEpisodes,
    totalPages,
    totalEpisodes,
    totalNew,
    totalQC,
    totalRFP,
    totalProd,
  };
};
