import React, { useState } from 'react';

import './AssetCards.scss';
import { KiteCard, KiteButton, KiteSelect } from '@kite/react-kite';
import { serviceTypes } from '../../utils/models';
import { downloadFile } from '../../apiCalls/BucketService';

export interface IAssetCardsProps {
  files: any;
  filePrefix: string;
  onSelect: (type: string, file: string, field: string, value: string) => void;
}

const AssetCards = ({ files, filePrefix, onSelect }: IAssetCardsProps) => {
  const [loadingFile, setLoadingFile] = useState('');
  const handleDownload = async (fileName) => {
    setLoadingFile(fileName);
    await downloadFile(fileName, filePrefix);
    setLoadingFile('');
  };

  const languages = [
    { label: 'English', value: 'en' },
    { label: 'Spanish', value: 'es' },
  ];
  const placeholderOption = <option value="" disabled></option>;

  const languageOptions = languages.map(({ value, label }) => (
    <option value={value} key={value}>
      {label}
    </option>
  ));

  const serviceOptions = serviceTypes.map((type) => (
    <option key={type} value={type}>
      {type}
    </option>
  ));
  const fpCards = Object.keys(files.fp).map((file) => (
    <KiteCard className="asset-card">
      <h3 className="asset-card__title">Fingerprint File</h3>
      <p className="asset-card__field">
        <span className="asset-card__field-title">File:</span>
        <KiteButton
          type="standalone-link"
          rightIcon="download"
          onClick={() => handleDownload(file)}
          loading={file === loadingFile}
        >
          {file.split('/').pop()}
        </KiteButton>
      </p>
      <KiteSelect
        label="Language*"
        id={file + 'language'}
        name={file}
        onChange={(e) => onSelect('fp', file, 'language', e.target.value)}
        value={files.fp[file].language}
        errorMessage={
          files.fp[file].languageError ? 'Please select a language' : ''
        }
      >
        {placeholderOption}
        {languageOptions}
      </KiteSelect>
    </KiteCard>
  ));

  const serviceCards = Object.keys(files.service).map((file) => {
    const title = file.split('/').pop();
    return (
      <KiteCard className="asset-card">
        <h3 className="asset-card__title">{title}</h3>
        <p className="asset-card__field">
          <span className="asset-card__field-title">File:</span>
          <KiteButton
            type="standalone-link"
            rightIcon="download"
            onClick={() => handleDownload(file)}
            loading={file === loadingFile}
          >
            {file.split('/').pop()}
          </KiteButton>
        </p>
        <div className="asset-card__select-wrapper">
          <KiteSelect
            label="Service Type*"
            id={file + 'serviceType'}
            name={file}
            onChange={(e) =>
              onSelect('service', file, 'serviceType', e.target.value)
            }
            value={files.service[file].serviceType}
            errorMessage={
              files.service[file].serviceTypeError
                ? 'Please select a service type'
                : ''
            }
          >
            {placeholderOption}
            {serviceOptions}
          </KiteSelect>
          <KiteSelect
            label="Language*"
            id={file + 'language'}
            name={file}
            onChange={(e) =>
              onSelect('service', file, 'language', e.target.value)
            }
            value={files.service[file].language}
            errorMessage={
              files.service[file].languageError
                ? 'Please select a language'
                : ''
            }
          >
            {placeholderOption}
            {languageOptions}
          </KiteSelect>
        </div>
      </KiteCard>
    );
  });

  return (
    <>
      {fpCards}
      {serviceCards}
    </>
  );
};

export default AssetCards;
