import axios, { AxiosRequestConfig } from 'axios';
import { movieCleaner } from './MoviesService';
import { seriesCleaner } from './SeriesService';

export const getCategories = async () => {
  const res = await axios(
    '/api/v1/categories?order=asc&page=0&size=-1&sortBy=name'
  );
  return res.data.content;
};

export const getCategoryById = async (id) => {
  const movieRes = await axios(
    `/api/v1/categories/${id}/movies?order=asc&page=0&size=-1&sortBy=title`
  );
  const movies = movieRes.data.content.map((movie) =>
    movieCleaner(movie, false)
  );
  const seriesRes = await axios(
    `/api/v1/categories/${id}/series?order=asc&page=0&size=-1&sortBy=title`
  );
  const series = seriesRes.data.content.map((series) =>
    seriesCleaner(series, false)
  );

  return [...movies, ...series];
};

export const updateCategory = async (id, fields) => {
  const options: AxiosRequestConfig = {
    method: 'PATCH',
    data: JSON.stringify(fields),
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res = await axios(`/api/v1/categories/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const createCategory = async (name) => {
  const bodyData = {
    name,
    visible: false,
  };
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: JSON.stringify(bodyData),
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const res = await axios('/api/v1/categories', options);
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteCategory = async (id) => {
  const options: AxiosRequestConfig = {
    method: 'DELETE',
  };
  try {
    const res = await axios(`/api/v1/categories/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const addFeaturesToCat = async (id, features) => {
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: JSON.stringify(features),
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const res = await axios(`/api/v1/categories/${id}/bulk`, options);
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
