import React, { useContext, useEffect, useState } from 'react';

import { KiteLoader } from '@kite/react-kite';

import getAuthorization from './getAuthorization';
import Routes from '../Routes';
import { Unauthorized } from '../../pages/index.ts';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider.tsx';

const KeyCloakService = () => {
  const [analytics] = useContext(AnalyticsContext);
  const [authorization, setAuthorization] = useState(null);

  useEffect(() => {
    const getData = async () => {
      await getAuthorization(setAuthorization);
    };
    getData();
  }, []);

  useEffect(() => {
    if (authorization && authorization.roles.includes('access-admin')) {
      analytics.track('loginStop', {
        userId: authorization.user,
        opSuccess: true,
      });
    } else if (authorization) {
      analytics.track('loginStop', {
        opSuccess: false,
      });
    }
  }, [authorization]);

  if (!authorization) {
    return (
      <div className="display-loader">
        <KiteLoader />
      </div>
    );
  }
  if (authorization.roles && authorization.roles.includes('access-admin')) {
    return <Routes />;
  }

  return <Unauthorized />;
};

export default KeyCloakService;
