import axios from 'axios';

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.interceptors.request.use(
      (config) => {
        const { data, url } = config;

        let parsedData;

        const newConfig = {
          ...config,
          data: parsedData ? JSON.stringify(parsedData) : data,
        };

        if (window.location.host.split('.')[0].includes('feature')) {
          newConfig.url = `https://develop.access.spectrumflow.net${url}`;
        }

        return newConfig;
      },
      (error) => Promise.reject(error)
    );
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};
export const removeAuthToken = () => {
  delete axios.defaults.headers.common.Authorization;
};
