import React from 'react';
import dayjs from 'dayjs';

import { KiteCard, KiteButton } from '@kite/react-kite';
import { BasicPills } from '@kite/react-kite-plus';

import './ExpirationCard.scss';

export interface IExpirationCardProps {
  endTime: string | null;
  customEndTime: string | null;
  useCmsExpiration: boolean;
  onEdit: () => any;
}

const ExpirationCard = ({
  endTime,
  customEndTime,
  useCmsExpiration,
  onEdit,
}: IExpirationCardProps) => {
  let expDateRender;
  let buttonText;
  if (useCmsExpiration) {
    const formattedEndTime = endTime
      ? dayjs(endTime).format('M/D/YYYY')
      : 'N/A';
    expDateRender = `eCMS Date: ${formattedEndTime}`;
    buttonText = 'Override';
  } else if (!useCmsExpiration && customEndTime) {
    const formattedEndTime = dayjs(customEndTime).format('M/D/YYYY');
    expDateRender = `Manual Date: ${formattedEndTime}`;
    buttonText = 'Update';
  } else {
    expDateRender = 'No Expiration Date Needed';
    buttonText = 'Add';
  }

  return (
    <KiteCard className="expiration-card">
      <div className="expiration-card__heading-wrapper">
        <h3 className="expiration-card__title">Expiration Date</h3>
      </div>
      <BasicPills values={[expDateRender]} />
      <p>
        Expiration dates are suggested from eCMS. You may manually override this
        date by clicking “{buttonText} Expiration”.
      </p>

      <KiteButton
        className="expiration-card__button"
        type="outline"
        onClick={onEdit}
      >
        {buttonText} Expiration
      </KiteButton>
    </KiteCard>
  );
};

export default ExpirationCard;
