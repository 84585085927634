import React, { Component } from 'react';
import { RouterComponentProps } from 'react-router-dom';

import { SearchBar, NewAssetsTable, AssetTypePicker } from '../../components';

import { getNewAssets, updateAsset } from '../../apiCalls/AssetsService';

import './NewAssets.scss';
import { INewAsset } from '../../utils/models';
import { KiteIcon } from '@kite/react-kite';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';

export interface INewAssetsState {
  assets: INewAsset[];
  searchResults: INewAsset[];
  showTypePicker: boolean;
  selectedAsset: INewAsset | null;
  loading: boolean;
}

class NewAssets extends Component<RouterComponentProps, INewAssetsState> {
  state: INewAssetsState = {
    assets: [],
    searchResults: [],
    showTypePicker: false,
    selectedAsset: null,
    loading: false,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    this.getNewAssets();
    const [analytics] = this.context;
    analytics.trackPageView('New Assets');
  }

  getNewAssets = () => {
    this.setState({ loading: true });
    getNewAssets().then((assets) => this.setState({ assets, loading: false }));
  };

  handleRowClick = (assetData) => {
    if (assetData.tmsId) {
      const { id, assetClass: type } = assetData;
      this.props.history.push(`/add-${type}/${id}`);
    } else {
      this.setState({ selectedAsset: assetData, showTypePicker: true });
    }
  };

  handleSearch = (query) => {
    if (query) {
      const { assets } = this.state;
      const formatString = (s) =>
        s
          .replace(/[.,/#!$%^&*;:{}=\-_`'~()]/g, '')
          .replace(/\s/g, '')
          .toLowerCase();
      const formattedQuery = formatString(query);
      const formattedAssets = assets.map((asset) => {
        const { assetTitle: title } = asset;
        let formattedTitle;
        if (title.includes('_')) {
          formattedTitle = formatString(title.split('_')[1]);
        } else {
          formattedTitle = formatString(title);
        }
        return { ...asset, formattedTitle };
      });
      const searchResults = formattedAssets.filter((asset) =>
        asset.formattedTitle.includes(formattedQuery)
      );
      this.setState({ searchResults });
    } else {
      this.setState({ searchResults: [] });
    }
  };

  handleTypeSelect = async (type) => {
    const { selectedAsset } = this.state;
    if (selectedAsset) {
      const { id } = selectedAsset;
      const data = await updateAsset(id, { assetClass: type });
      if (data) {
        this.props.history.push(`/add-${type}/${id}`);
      }
    }
  };

  handleRemoveAsset = async () => {
    const { selectedAsset } = this.state;
    if (selectedAsset) {
      const { id } = selectedAsset;
      await updateAsset(id, { newAsset: false });
    }
    this.getNewAssets();
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ showTypePicker: false });
  };

  render() {
    const { assets, showTypePicker, loading, searchResults } = this.state;

    const tableElements = (
      <>
        <SearchBar
          featureType="Assets"
          onSearch={this.handleSearch}
          showShadow={false}
        />
        <NewAssetsTable
          assetData={searchResults.length ? searchResults : assets}
          onRowClick={this.handleRowClick}
          loading={loading}
        />
        <AssetTypePicker
          isOpen={showTypePicker}
          onHide={this.handleClose}
          onSelect={this.handleTypeSelect}
          onRemove={this.handleRemoveAsset}
        />
      </>
    );

    const upToDate = (
      <div className="new-assets__up-to-date">
        <KiteIcon name="folder" size="200px" color="#d8dde6" />
        <p className="new-assets__subheader">Assets are Up-To-Date</p>
        <p className="new-assets__copy">
          You have already added all available assets.
        </p>
        <p className="new-assets__copy">Check back for new assets!</p>
      </div>
    );

    return (
      <main className="new-assets">
        <h2 className="new-assets__title">New Assets</h2>
        {!assets.length && !loading ? upToDate : tableElements}
      </main>
    );
  }
}

export default NewAssets;
