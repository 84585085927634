import { INotesCardData } from '../components/NotesCard/NotesCard';

export const serviceTypes = [
  'Audio Description only',
  'Amplified Audio',
  'Audio Description + Full Audio',
  'Closed Captioning',
  'Sign Language',
];

const STlist = [...serviceTypes] as const;

export type ServiceType = typeof STlist[number];

export type IMovie = {
  id: string;
  tmsId: string;
  title: string;
  addedOn: Date;
  updatedOn: Date;
  status: TStatus;
  services: IService[];
  categories: ICategory[];
  primaryCategory: ICategory;
  primaryCategoryId?: string;
  errors: string[];
  year: number;
  rating: string;
  duration: number;
  description: string;
  genres?: string[];
  posterUrl: string;
  epicUrl: string;
  notes: INotesCardData;
  files: IFile[];
  badMetadata?: boolean;
  unsyncedMetadata?: boolean;
  endTime: string | null;
  customEndTime: string | null;
  useCmsExpiration: boolean;
};

export type IEpisode = {
  id: string;
  title: string;
  tmsId: string;
  number: number;
  addedOn: Date;
  updatedOn: Date;
  status: 'New' | 'Quality Control' | 'Ready for Production' | 'In Production';
  services: IService[];
  errors: string[];
  rating: string;
  season: number;
  year: number;
  description: string;
  posterUrl: string;
  epicUrl: string;
  notes: INotesCardData;
  files: IFile[];
  seriesTitle: string;
  seriesTmsid: string;
  seriesId: string;
  badMetadata?: boolean;
  unsyncedMetadata?: boolean;
  endTime: string | null;
  customEndTime: string | null;
  useCmsExpiration: boolean;
};

export type ISeries = {
  id: string;
  tmsId: string;
  title: string;
  addedOn: Date;
  updatedOn: Date;
  status: 'New' | 'Quality Control' | 'Ready for Production' | 'In Production';
  errors: string[];
  categories: ICategory[];
  primaryCategory: ICategory;
  primaryCategoryId?: string;
  notes: INotesCardData;
  seasons: IEpisode[][];
  rating: string;
  genre: string;
  description: string;
  posterUrl: string;
  epicUrl: string;
  network: any;
  badMetadata?: boolean;
  unsyncedMetadata?: boolean;
};

export type IService = {
  id: string;
  type: ServiceType;
  language: string;
  status: 'New' | 'Quality Control' | 'Ready for Production' | 'In Production';
  created: string;
  updated: string;
  file: string;
};

export type IFile = {
  id: string;
  language: string;
  file: string;
};

export type INewFile = {
  serviceLanguage: string;
  serviceFile: File;
};

export type ICategory = {
  id: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  name: string;
};

export type INewAsset = {
  id: string;
  createdAt: string;
  assetTitle: string;
  assetFiles: string[];
};

export type INewEpAsset = {
  id: string;
  createdAt: string;
  seriesTitle: string;
  episodeNumber: string;
  assetFiles: string[];
};

export type TStatus =
  | 'New'
  | 'Quality Control'
  | 'Ready for Production'
  | 'In Production';

export type ISwimlane = {
  endTime: string;
  id: string;
  items: ISwimlaneItem[];
  name: string;
  priorityNumber: number | null;
  startTime: string;
  status: 'NotReady' | 'Ready' | 'Scheduled' | 'Published';
  isStatic: boolean;
};

export type ISwimlaneItem = {
  data: any;
  itemId: string;
  priorityNumber: number | null;
  type: string;
};
