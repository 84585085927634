import React, { useState } from 'react';
import { KiteModal, KiteButton, KiteCheckbox } from '@kite/react-kite';

import './DeleteFeature.scss';

export interface IDeleteFeatureProps {
  isOpen: boolean;
  assetTitle: string;
  onDelete: () => any;
  onHide: (bool: boolean) => any;
  assetType: 'feature' | 'swimlane';
  buttonLoading?: boolean;
}

const DeleteFeature = ({
  isOpen,
  assetTitle,
  onDelete,
  onHide,
  assetType,
  buttonLoading,
}: IDeleteFeatureProps) => {
  const [confirmed, setConfirmed] = useState(false);

  const handleDelete = () => {
    onDelete();
    onHide(false);
  };

  return (
    <KiteModal
      className="delete-feature"
      canShow={isOpen}
      title={`Delete ${assetType}`}
      disableBackgroundClose
      showCloseButton={false}
    >
      <p className="delete-feature__copy">
        {assetType === 'feature'
          ? `Deleting this feature will permanently remove it from the system. The
        services uploaded for this feature will also be deleted. Please confirm
        that you would like to delete ${assetTitle}.`
          : 'Deleting this swimlane will permanently remove it from the system. If deployed, it will be removed from the production app. Please confirm that you would like to delete.'}
      </p>
      <KiteCheckbox
        className="delete-feature__checkbox"
        id="confirmDelete"
        name="confirmDelete"
        onChange={() => setConfirmed(!confirmed)}
        checked={confirmed}
        label={`I confirm that I would like to delete this ${assetType}.`}
      />
      <div className="delete-feature__button-wrapper">
        <KiteButton
          type="outline"
          className="delete-feature__button delete-feature__button--cancel"
          onClick={() => onHide(false)}
        >
          Cancel
        </KiteButton>
        <KiteButton
          className="delete-feature__button delete-feature__button--delete"
          disabled={!confirmed}
          onClick={handleDelete}
          loading={buttonLoading}
        >
          Delete
        </KiteButton>
      </div>
    </KiteModal>
  );
};

export default DeleteFeature;
