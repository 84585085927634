import React, { Component } from 'react';
import { RouterComponentProps } from 'react-router-dom';

// Components
import {
  CategoriesCard,
  CountriesCard,
  DeleteFeature,
  DeleteFeatureCard,
  FeatureServices,
  LanguagesCard,
  Metadata,
  NotesCard,
  StatusBar,
  Subheader,
  FilesCard,
  AddDrawer,
  Notes,
  PublishServices,
  ErrorsCard,
  UnpublishedServices,
  PublishFeature,
  RemoveFeature,
  UnresolvedErrors,
  ErrorModal,
  FileNameModal,
  SearchModal,
  InvalidTMSIDModal,
  AddErrorModal,
  ExpirationCard,
  ExpirationDateModal,
} from '../../components';

// Utils
import { IMovie, IService, IFile, ICategory } from '../../utils/models';
import * as MovieService from '../../apiCalls/MoviesService';
import {
  updateService,
  deleteService,
  createContent,
} from '../../apiCalls/ContentService';
import './Movie.scss';
import { getFolderFiles, uploadFile } from '../../apiCalls/BucketService';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';
import dayjs from 'dayjs';

interface IMoviePageState {
  showModal:
    | ''
    | 'notes'
    | 'delete'
    | 'publish service'
    | 'unpublished service'
    | 'publish feature'
    | 'remove'
    | 'error'
    | 'unresolved errors'
    | 'update metadata'
    | 'file name'
    | 'invalid TMSID'
    | 'expiration date';
  movieData: IMovie | null;
  selectedCategories: ICategory[];
  primaryCategory: ICategory | null;
  addDrawer: '' | 'Service' | 'File';
  selectedFile: IService | IFile | null;
  selectedServices: string[];
  ariaLiveContent: string;
  showMetadataAlert: boolean;
  showCategoriesAlert: boolean;
  showFilesAlert: boolean;
  showServicesAlert: boolean;
  showNotesAlert: boolean;
  showCategoriesError: 'category' | 'file-QC' | 'file-Delete' | '';
  loadingButton: string;
  analytics: any;
  targetFileNames: string[];
  showInvalidTMSIDModalOverride: boolean;
  unsyncedMetadataOverride: boolean;
}

class Movie extends Component<RouterComponentProps, IMoviePageState> {
  state: IMoviePageState = {
    showModal: '',
    movieData: null,
    selectedCategories: [],
    primaryCategory: null,
    addDrawer: '',
    selectedFile: null,
    selectedServices: [],
    ariaLiveContent: '',
    showMetadataAlert: false,
    showCategoriesAlert: false,
    showFilesAlert: false,
    showServicesAlert: false,
    showNotesAlert: false,
    showCategoriesError: '',
    loadingButton: '',
    analytics: null,
    targetFileNames: [],
    showInvalidTMSIDModalOverride: false,
    unsyncedMetadataOverride: false,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    const [analytics] = this.context;
    this.setState({ analytics });
    this.updateMovieData(true);
  }

  updateMovieData = (trackPageView?) => {
    const {
      match: {
        params: { movie_id },
      },
    } = this.props;
    MovieService.getMovie(movie_id).then((movieData) => {
      this.setState({
        movieData,
        selectedCategories: movieData.categories,
        primaryCategory: movieData.primaryCategory,
        loadingButton: '',
      });

      if (movieData.unsyncedMetadata && !this.state.unsyncedMetadataOverride) {
        this.handleUnsycnedMetadata();
      }
      if (movieData.badMetadata && !this.state.showInvalidTMSIDModalOverride) {
        this.handleBadMetadata();
      }
      if (trackPageView) {
        this.state.analytics.trackPageView(`Movie - ${movieData.title}`);
      }
    });
  };

  handleUnsycnedMetadata = () => {
    this.removeFromProduction(['Unsynced Metadata'], '');
    this.setState({ unsyncedMetadataOverride: true });
  };

  handleBadMetadata = () => {
    this.removeFromProduction(['Invalid TMSID'], '');
    this.setState({ showModal: 'invalid TMSID' });
  };

  handleStatusClick = (status) => {
    if (status === 'Quality Control' || status === 'Back to Quality Control') {
      this.moveToQC();
    } else if (status === 'Ready for Production') {
      if (this.state.movieData?.errors.length) {
        this.setState({ showModal: 'unresolved errors' });
      } else {
        this.setState({ showModal: 'unpublished service' });
      }
    } else if (status === 'Publish to Production') {
      this.setState({ showModal: 'publish feature' });
    } else if (status === 'Remove from Production') {
      this.setState({ showModal: 'remove' });
    } else if (status === 'Add Errors') {
      this.setState({ showModal: 'error' });
    }
  };

  moveToQC = async () => {
    if (!this.state.movieData?.files.length) {
      this.setState({ showCategoriesError: 'file-QC' });
    } else {
      this.setState({ loadingButton: 'statusQC' });
      const { id, services } = this.state.movieData || {};
      await MovieService.updateMovie(id, { status: 'QualityControl' });
      const servicePromises = services
        ? services.map((service) =>
            updateService(service.id, { status: 'QualityControl' })
          )
        : [];
      await Promise.all(servicePromises);
      this.updateMovieData();
    }
  };

  movetoRFP = async (publishServices) => {
    this.setState({ loadingButton: 'statusRFP' });
    const { id, services } = this.state.movieData || {};

    if (publishServices === 'yes') {
      await MovieService.updateMovie(id, { status: 'ReadyForProduction' });
      const servicePromises = services
        ? services.map((service) =>
            updateService(service.id, { status: 'ReadyForProduction' })
          )
        : [];
      await Promise.all(servicePromises);
      this.updateMovieData();
    } else {
      await MovieService.updateMovie(id, { status: 'ReadyForProduction' });
      this.updateMovieData();
    }
  };

  moveToProduction = async () => {
    this.setState({ loadingButton: 'statusPP' });

    const { id, services } = this.state.movieData || {};

    await MovieService.updateMovie(id, { status: 'Production' });
    const servicePromises = services
      ? services.map((service) =>
          service.status === 'Ready for Production'
            ? updateService(service.id, { status: 'Production' })
            : null
        )
      : [];
    await Promise.all(servicePromises);

    this.state.analytics.track('selectAction', {
      msgCategory: 'navigation',
      msgTriggeredBy: 'user',
      opType: 'buttonClick',
      currPageElemStdName: 'publish',
      currPageElemUiName: 'Move to Production',
    });

    this.updateMovieData();
  };

  removeFromProduction = async (
    selectedErrors: string[],
    errorDetails: string
  ) => {
    this.setState({ loadingButton: 'statusRP' });
    const { id, services } = this.state.movieData || {};

    const formattedErrors = `${selectedErrors.join('---')}${
      errorDetails ? `---Other - ${errorDetails}` : ``
    }`;

    await MovieService.updateMovie(id, {
      status: 'QualityControl',
      error: formattedErrors,
    });
    const servicePromises = services
      ? services.map((service) =>
          updateService(service.id, { status: 'QualityControl' })
        )
      : [];
    await Promise.all(servicePromises);
    this.updateMovieData();
  };

  addErrors = async (selectedErrors: string[], errorDetails: string) => {
    this.setState({ loadingButton: 'addErrors' });
    const { id } = this.state.movieData || {};

    const formattedErrors = `${selectedErrors.join('---')}${
      errorDetails ? `---Other - ${errorDetails}` : ``
    }`;

    await MovieService.updateMovie(id, {
      error: formattedErrors,
    });

    this.updateMovieData();
  };

  handleMetadataSearch = async () => {
    this.setState({ showModal: 'update metadata' });
  };

  handleUpdateMetadata = async (tmsId, title) => {
    const { id, services } = this.state.movieData || {};
    await MovieService.updateMovie(id, {
      tmsId,
      title,
    });
    const servicePromises = services
      ? services.map((service) => updateService(service.id, { tmsId }))
      : [];
    await Promise.all(servicePromises);
    this.updateMovieData();
    this.setState({
      showMetadataAlert: true,
      ariaLiveContent: 'Metadata changes saved',
    });

    setTimeout(() => {
      this.setState({
        showMetadataAlert: false,
        ariaLiveContent: '',
      });
    }, 3000);
  };

  handleChangeTMSID = (tmsId, title) => {
    this.setState({ loadingButton: 'metadata', showModal: '' });
    this.handleUpdateMetadata(tmsId, title);
  };

  handleRefresh = async () => {
    this.setState({
      loadingButton: 'refresh metadata',
      unsyncedMetadataOverride: false,
    });
    const { id } = this.state.movieData || {};
    const { tmsId, title } = await MovieService.getNewMovieMetadata(id);
    this.handleUpdateMetadata(tmsId, title);
  };

  handleCategorySelect = async (categories) => {
    const { id } = this.state.movieData || {};
    if (categories.length) {
      this.setState({ selectedCategories: categories });

      await MovieService.updateMovie(id, {
        allCategoryIds: categories.map((cat) => cat.id),
      });
      this.updateMovieData();

      this.setState({
        showCategoriesAlert: true,
        ariaLiveContent: 'Categories changes saved',
      });

      setTimeout(() => {
        this.setState({
          showCategoriesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    }
  };

  handlePrimaryCategorySelect = async (category) => {
    const { id } = this.state.movieData || {};
    if (category) {
      this.setState({ primaryCategory: category });

      await MovieService.updateMovie(id, {
        primaryCategoryId: category.id,
      });
      this.updateMovieData();

      this.setState({
        showCategoriesAlert: true,
        ariaLiveContent: 'Primary category changes saved',
      });

      setTimeout(() => {
        this.setState({
          showCategoriesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    }
  };

  handleCategoryWarning = () => {
    this.setState({ showCategoriesError: 'category' });
  };

  toggleNotesModal = (bool) => {
    this.setState({ showModal: bool ? 'notes' : '' });
  };
  toggleExpDateModal = (bool) => {
    this.setState({ showModal: bool ? 'expiration date' : '' });
  };

  toggleDeleteModal = (bool) => {
    this.setState({ showModal: bool ? 'delete' : '' });
  };

  handleNotesSave = async (notes) => {
    const { id } = this.state.movieData || {};
    await MovieService.updateMovie(id, { notes: `${new Date()}---${notes}` });
    this.updateMovieData();
    this.setState({
      showNotesAlert: true,
      ariaLiveContent: 'Notes changes saved',
    });

    setTimeout(() => {
      this.setState({
        showNotesAlert: false,
        ariaLiveContent: '',
      });
    }, 3000);
  };

  handleChangeExpiration = async (radioValue, dateValue) => {
    const { id } = this.state.movieData || {};
    const updateFields: any = {};

    if (radioValue === 'none') {
      updateFields.useCmsExpiration = false;
      updateFields.customEndTime = null;
    } else if (radioValue === 'manual') {
      const newDate = +dayjs(dateValue);
      updateFields.useCmsExpiration = false;
      updateFields.customEndTime = newDate;
    } else if (radioValue === 'eCMS') {
      updateFields.useCmsExpiration = true;
    }

    this.setState({ loadingButton: 'exipiration date' });
    await MovieService.updateMovie(id, updateFields);
    this.updateMovieData();
    this.setState({ loadingButton: '', showModal: '' });
  };

  handleDeleteFeature = async () => {
    this.setState({ loadingButton: 'deleteFeature' });
    const { movieData } = this.state;
    const { services, id } = movieData || {};
    const servicePromises = services
      ? services.map((service) => deleteService(service.id))
      : [];
    await Promise.all(servicePromises);
    await MovieService.deleteMovie(id);
    this.props.history.push('/movies');
  };

  handleServiceClick = async (action, id) => {
    const { movieData } = this.state;

    if (action === 'add') {
      this.setState({ addDrawer: 'Service' });
    } else if (action === 'edit') {
      const targetFile =
        movieData && movieData.services.find((service) => service.id === id);
      const selectedFile = targetFile ? targetFile : null;
      this.setState({
        addDrawer: 'Service',
        selectedFile,
      });
    } else if (action === 'publishAll') {
      const selectedServices = movieData
        ? movieData.services
            .filter((service) => service.status === 'Quality Control')
            .map((service) => service.id)
        : [];
      this.setState({
        showModal: 'publish service',
        selectedServices,
      });
    } else if (action === 'publish') {
      this.setState({
        showModal: 'publish service',
        selectedServices: [id],
      });
    } else if (action === 'remove') {
      await updateService(id, { status: 'QualityControl' });
      this.updateMovieData();
    }
  };

  handleFileClick = (action, id) => {
    if (action === 'add') {
      this.setState({ addDrawer: 'File' });
    } else {
      const { movieData } = this.state;
      const targetFile =
        movieData && movieData.files.find((file) => file.id === id);
      const selectedFile = targetFile ? targetFile : null;
      this.setState({
        addDrawer: 'File',
        selectedFile,
      });
    }
  };

  handleAddDrawerClose = () => {
    this.setState({ addDrawer: '', selectedFile: null });
  };

  handleFileSubmit = async (action, fileData) => {
    const { addDrawer, selectedFile } = this.state;

    if (action === 'Update Service' && selectedFile) {
      await updateService(selectedFile.id, fileData);
      this.updateMovieData();

      this.setState({
        showServicesAlert: true,
        ariaLiveContent: 'Services changes saved',
      });

      setTimeout(() => {
        this.setState({
          showServicesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    } else if (action === 'Update File' && selectedFile) {
      const { language: newLanguage } = fileData;
      const { files, id } = this.state.movieData || {};
      const updatedFiles = files
        ? files.map(({ language, file, id }) =>
            id === selectedFile.id
              ? { language: newLanguage, file }
              : { language, file }
          )
        : [];

      await MovieService.updateMovie(id, { fingerprintFiles: updatedFiles });
      this.updateMovieData();
      this.setState({
        showFilesAlert: true,
        ariaLiveContent: 'Files changes saved',
      });

      setTimeout(() => {
        this.setState({
          showFilesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    } else if (addDrawer === 'Service') {
      const { serviceFile, serviceLanguage, serviceType } = fileData;
      const { tmsId } = this.state.movieData || {};
      const filePath = `/assets/${tmsId}/${serviceFile.name}`;
      await uploadFile(serviceFile, `/assets/${tmsId}`);
      await createContent(
        filePath,
        serviceLanguage,
        serviceType,
        tmsId,
        'QualityControl'
      );
      this.updateMovieData();
    } else if (addDrawer === 'File') {
      const { serviceFile, serviceLanguage } = fileData;
      const { tmsId, files, id } = this.state.movieData || {};
      const filePath = `assets/${tmsId}/${serviceFile.name}`;

      await uploadFile(serviceFile, `/assets/${tmsId}`);
      const newFile = { language: serviceLanguage, file: filePath };
      const updatedFiles = files ? [...files, newFile] : [newFile];
      const fingerprintFiles = updatedFiles.map(({ language, file }) => ({
        language,
        file,
      }));
      await MovieService.updateMovie(id, { fingerprintFiles });
      this.updateMovieData();
      this.setState({
        showFilesAlert: true,
        ariaLiveContent: 'Files changes saved',
      });

      setTimeout(() => {
        this.setState({
          showFilesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    }
  };

  handleFileDelete = async (name) => {
    const { files, id } = this.state.movieData || {};
    if (files && files.length > 1) {
      const fingerprintFiles = files
        .filter((file) => file.file !== name)
        .map(({ language, file }) => ({
          language,
          file,
        }));

      await MovieService.updateMovie(id, { fingerprintFiles });
      this.updateMovieData();
      this.setState({
        showFilesAlert: true,
        ariaLiveContent: 'Files changes saved',
      });

      setTimeout(() => {
        this.setState({
          showFilesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    } else {
      this.setState({ showCategoriesError: 'file-Delete' });
    }
  };

  handleServiceDelete = async (id) => {
    await deleteService(id);
    this.updateMovieData();
    this.setState({
      showServicesAlert: true,
      ariaLiveContent: 'Services changes saved',
    });

    setTimeout(() => {
      this.setState({
        showServicesAlert: false,
        ariaLiveContent: '',
      });
    }, 3000);
  };

  handlePublishServicesHide = () => {
    this.setState({ showModal: '', selectedServices: [] });
  };

  handlePublishServices = async (ids) => {
    const servicePromises = ids.map((id) =>
      updateService(id, { status: 'Production' })
    );

    await Promise.all(servicePromises);
    this.updateMovieData();
    this.setState({
      showServicesAlert: true,
      ariaLiveContent: 'Services changes saved',
    });

    setTimeout(() => {
      this.setState({
        showServicesAlert: false,
        ariaLiveContent: '',
      });
    }, 3000);
  };

  handleResolveErrors = async () => {
    this.setState({ loadingButton: 'errorsResolved' });

    const { id } = this.state.movieData || {};
    await MovieService.updateMovie(id, { error: null });
    this.updateMovieData();
  };

  handleFilenameUpdateClick = async (fileName) => {
    this.setState({ showModal: 'file name' });
    const fileNames = await getFolderFiles(fileName);
    this.setState({ targetFileNames: fileNames });
  };

  handleFilenameChange = async (fileName) => {
    const { selectedFile } = this.state;

    const isService = (file: any): file is IService => {
      return 'type' in file;
    };

    if (isService(selectedFile)) {
      await updateService(selectedFile.id, { file: fileName });
      this.updateMovieData();
      const newSelectedFile = { ...selectedFile, file: fileName };
      this.setState({
        showServicesAlert: true,
        ariaLiveContent: 'Services changes saved',
        showModal: '',
        selectedFile: newSelectedFile,
      });

      setTimeout(() => {
        this.setState({
          showServicesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    } else if (selectedFile) {
      const { files, id } = this.state.movieData || {};
      const updatedFiles = files
        ? files.map(({ language, file, id }) =>
            id === selectedFile.id
              ? { file: fileName, language }
              : { language, file }
          )
        : [];

      await MovieService.updateMovie(id, { fingerprintFiles: updatedFiles });
      this.updateMovieData();
      const newSelectedFile = { ...selectedFile, id: fileName, file: fileName };
      this.setState({
        showFilesAlert: true,
        ariaLiveContent: 'Files changes saved',
        showModal: '',
        selectedFile: newSelectedFile,
      });

      setTimeout(() => {
        this.setState({
          showFilesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    }
  };

  render() {
    const {
      showModal,
      movieData,
      selectedCategories,
      primaryCategory,
      addDrawer,
      selectedFile,
      selectedServices,
      ariaLiveContent,
      showMetadataAlert,
      showCategoriesAlert,
      showFilesAlert,
      showServicesAlert,
      showNotesAlert,
      showCategoriesError,
      loadingButton,
      targetFileNames,
    } = this.state;

    const errorOptions = [
      'Incorrect Metadata',
      'Incorrect Preferences',
      'Incorrect Categories',
      'Error in Services',
      'Other',
    ];

    let displayPage;

    if (movieData) {
      const {
        title,
        status,
        services,
        notes,
        files,
        errors,
        badMetadata,
        unsyncedMetadata,
        endTime,
        customEndTime,
        useCmsExpiration,
      } = movieData || {};

      const languages = Array.from(
        new Set(services.map((service) => service.language))
      );

      displayPage = (
        <>
          <Subheader featureTitle={title} featureType="movie" />
          <main className="movie">
            {!!errors.length && (
              <ErrorsCard
                errors={errors}
                onButtonClick={this.handleResolveErrors}
                loadingButton={loadingButton}
              />
            )}
            <StatusBar
              status={status}
              onButtonClick={this.handleStatusClick}
              loadingButton={loadingButton}
            />
            <Metadata
              assetType="movie"
              data={movieData}
              onUpdate={this.handleMetadataSearch}
              onRefresh={this.handleRefresh}
              showAlert={showMetadataAlert}
              loadingButton={loadingButton}
              badMetadata={badMetadata || unsyncedMetadata}
            />
            <ExpirationCard
              endTime={endTime}
              customEndTime={customEndTime}
              useCmsExpiration={useCmsExpiration}
              onEdit={() => this.toggleExpDateModal(true)}
            />
            <LanguagesCard languages={languages} />
            <CountriesCard countries={['United States']} />
            <CategoriesCard
              status={status}
              selectedCategories={selectedCategories}
              primaryCategory={primaryCategory}
              showMessage={showCategoriesAlert}
              onSelectCategories={this.handleCategorySelect}
              onSelectPrimary={this.handlePrimaryCategorySelect}
              onTriggerWarning={this.handleCategoryWarning}
            />
            <FilesCard
              files={files}
              status={status}
              onButtonClick={this.handleFileClick}
              showAlert={showFilesAlert}
            />
            <FeatureServices
              services={services}
              status={status}
              onButtonClick={this.handleServiceClick}
              showAlert={showServicesAlert}
            />
            <NotesCard
              notesData={notes}
              onEdit={() => this.toggleNotesModal(true)}
              showAlert={showNotesAlert}
            />
            <DeleteFeatureCard
              onDelete={() => this.toggleDeleteModal(true)}
              loadingButton={loadingButton}
            />
          </main>
          <div className="sr-only" aria-live="polite" aria-atomic="true">
            {ariaLiveContent}
          </div>
          <Notes
            isOpen={showModal === 'notes'}
            onClose={() => this.toggleNotesModal(false)}
            onSave={this.handleNotesSave}
            notes={notes}
          />
          <ExpirationDateModal
            isOpen={showModal === 'expiration date'}
            loading={loadingButton === 'exipiration date'}
            onClose={() => this.toggleExpDateModal(false)}
            endTime={endTime}
            customEndTime={customEndTime}
            onSave={this.handleChangeExpiration}
            useCmsExpiration={useCmsExpiration}
          />
          <DeleteFeature
            assetType="feature"
            assetTitle={title}
            isOpen={showModal === 'delete'}
            onHide={() => this.toggleDeleteModal(false)}
            onDelete={this.handleDeleteFeature}
          />
          <AddDrawer
            type={addDrawer}
            isOpen={!!addDrawer}
            onClose={this.handleAddDrawerClose}
            onSubmit={this.handleFileSubmit}
            onDelete={
              addDrawer === 'Service'
                ? this.handleServiceDelete
                : this.handleFileDelete
            }
            fileData={selectedFile}
            onUpdateFilename={this.handleFilenameUpdateClick}
          />
          <PublishServices
            isOpen={showModal === 'publish service'}
            serviceIDs={selectedServices}
            onHide={this.handlePublishServicesHide}
            onPublish={this.handlePublishServices}
          />
          <UnpublishedServices
            isOpen={showModal === 'unpublished service'}
            onHide={() => this.setState({ showModal: '' })}
            onContinue={this.movetoRFP}
          />
          <PublishFeature
            isOpen={showModal === 'publish feature'}
            onHide={() => this.setState({ showModal: '' })}
            onPublish={this.moveToProduction}
            featureType="movie"
          />
          <RemoveFeature
            isOpen={showModal === 'remove'}
            assetType="movie"
            errorOptions={errorOptions}
            onHide={() => this.setState({ showModal: '' })}
            onRemove={this.removeFromProduction}
          />
          <AddErrorModal
            isOpen={showModal === 'error'}
            errorOptions={errorOptions}
            onHide={() => this.setState({ showModal: '' })}
            onAdd={this.addErrors}
          />
          <UnresolvedErrors
            isOpen={showModal === 'unresolved errors'}
            onClose={() => this.setState({ showModal: '' })}
            errors={errors}
          />
          <ErrorModal
            isOpen={!!showCategoriesError}
            onClose={() => this.setState({ showCategoriesError: '' })}
            type={showCategoriesError}
          />
          <SearchModal
            isOpen={showModal === 'update metadata'}
            onClose={() => this.setState({ showModal: '' })}
            featureType="movie"
            title={title}
            onUpdate={this.handleChangeTMSID}
          />
          <FileNameModal
            isOpen={showModal === 'file name'}
            onClose={() => this.setState({ showModal: '' })}
            filenames={targetFileNames}
            onApply={this.handleFilenameChange}
          />
          <InvalidTMSIDModal
            isOpen={showModal === 'invalid TMSID'}
            onClose={() =>
              this.setState({
                showModal: '',
                showInvalidTMSIDModalOverride: true,
              })
            }
            onClick={() => {
              this.setState({
                showModal: '',
                showInvalidTMSIDModalOverride: true,
              });
              this.setState({
                showModal: 'update metadata',
              });
            }}
            featureType="movie"
          />
        </>
      );
    }
    return <div>{displayPage}</div>;
  }
}

export default Movie;
