import React, { Component } from 'react';
import { RouterComponentProps, Link } from 'react-router-dom';

import './AllMovies.scss';
import { IMovie } from '../../utils/models';
import { MoviesTable, SearchBar, StatusCounter } from '../../components';
import { KitePagination } from '@kite/react-kite';
import { getAllMovies } from '../../apiCalls/MoviesService';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';

export interface IAllMoviesState {
  allMovies: IMovie[];
  statusFilter: string;
  currentPage: number;
  totalPages: number;
  ascending: boolean;
  sortBy: 'title' | 'createdAt' | 'updatedAt' | 'status';
  loading: boolean;
  totalMovies: number;
  totalNew: number;
  totalQC: number;
  totalRFP: number;
  totalProd: number;
}

class AllMovies extends Component<RouterComponentProps, IAllMoviesState> {
  state: IAllMoviesState = {
    allMovies: [],
    statusFilter: 'All',
    currentPage: 1,
    totalPages: 0,
    ascending: true,
    sortBy: 'title',
    loading: false,
    totalMovies: 0,
    totalNew: 0,
    totalQC: 0,
    totalRFP: 0,
    totalProd: 0,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    this.getMovieData(true, 1, 'title');
    const [analytics] = this.context;
    analytics.trackPageView('All Movies');
  }

  getMovieData = (ascending, currentPage, sortBy, title?, status?) => {
    this.setState({ loading: true });
    const order = ascending ? 'asc' : 'desc';
    const requestConfig = {
      order,
      page: currentPage - 1,
      sortBy,
      title,
      status,
      size: 20,
    };
    getAllMovies(requestConfig).then(
      ({
        allMovies,
        totalPages,
        totalMovies,
        totalNew,
        totalQC,
        totalRFP,
        totalProd,
      }) =>
        this.setState({
          allMovies,
          totalPages,
          loading: false,
          totalMovies,
          totalNew,
          totalQC,
          totalRFP,
          totalProd,
        })
    );
  };

  handleSortClick = (sortHeader) => {
    const { sortBy, ascending, currentPage } = this.state;

    if (sortHeader === sortBy) {
      this.setState({ ascending: !ascending, allMovies: [] });
      this.getMovieData(!ascending, currentPage, sortHeader);
    } else {
      this.setState({ sortBy: sortHeader, ascending: true, allMovies: [] });
      this.getMovieData(ascending, currentPage, sortHeader);
    }
  };

  handleFilterChange = (newFilter) => {
    this.setState({ statusFilter: newFilter, currentPage: 1 });
    const { sortBy, ascending } = this.state;
    const statusFilter = newFilter === 'All' ? false : newFilter;
    this.getMovieData(ascending, 1, sortBy, false, statusFilter);
  };

  handleSearch = (query) => {
    this.setState({ currentPage: 1 });
    const { sortBy, ascending } = this.state;
    this.getMovieData(ascending, 1, sortBy, query);
  };

  handleNextPage = () => {
    const { sortBy, ascending, currentPage, statusFilter } = this.state;
    const newPage = currentPage + 1;
    this.setState({ currentPage: newPage, allMovies: [] });
    this.getMovieData(ascending, newPage, sortBy, false, statusFilter);
  };

  handlePreviousPage = () => {
    const { sortBy, ascending, currentPage, statusFilter } = this.state;
    const newPage = currentPage - 1;
    this.setState({ currentPage: newPage, allMovies: [] });
    this.getMovieData(ascending, newPage, sortBy, false, statusFilter);
  };

  handlePageSelect = (page) => {
    const { sortBy, ascending, statusFilter } = this.state;
    this.setState({ currentPage: page, allMovies: [] });
    this.getMovieData(ascending, page, sortBy, false, statusFilter);
  };

  render() {
    const {
      allMovies,
      statusFilter,
      currentPage,
      totalPages,
      ascending,
      sortBy,
      loading,
      totalMovies,
      totalNew,
      totalQC,
      totalRFP,
      totalProd,
    } = this.state;

    const statusCounts = [
      {
        label: 'Total Movies',
        count: totalMovies,
      },
      {
        label: 'Newly Added',
        count: totalNew,
      },
      {
        label: 'In Quality Control',
        count: totalQC,
      },
      {
        label: 'Ready for Production',
        count: totalRFP,
      },
      {
        label: 'In Production',
        count: totalProd,
      },
    ];
    return (
      <div>
        <main className="all-movies">
          <h2 className="all-movies__title">Movies</h2>
          <Link to="add-movie" className="all-movies__add">
            Add Movie
          </Link>
          <SearchBar
            featureType="Movies"
            statusFilter={statusFilter}
            onFilterSelect={this.handleFilterChange}
            onSearch={this.handleSearch}
            showShadow={false}
            statusCounts={statusCounts}
          />
          <StatusCounter statusCounterData={statusCounts} />
          <MoviesTable
            movieData={allMovies}
            onSortClick={this.handleSortClick}
            ascending={ascending}
            sortHeader={sortBy}
            loading={loading}
          />
          {totalPages > 1 && (
            <KitePagination
              totalPages={totalPages}
              currentPage={currentPage}
              onNextPage={this.handleNextPage}
              onPrevPage={this.handlePreviousPage}
              onPageSelect={this.handlePageSelect}
            />
          )}
        </main>
      </div>
    );
  }
}

export default AllMovies;
