import React, { useState } from 'react';
import { KiteCheckbox, KiteModal } from '@kite/react-kite';
import { DatePicker } from '@kite/react-kite-plus';
import dayjs from 'dayjs';

import './SwimlaneScheduleModal.scss';
import { ISwimlane } from '../../utils/models';
import { getUnixTime } from '../../utils/helpers';

export interface ISwimlaneScheduleModalProps {
  isOpen: boolean;
  swimlaneData: ISwimlane;
  onSchedule: (start, end) => void;
  onHide: () => any;
}

const SwimlaneScheduleModal = ({
  isOpen,
  swimlaneData,
  onSchedule,
  onHide,
}: ISwimlaneScheduleModalProps) => {
  const getDateString = (date) => dayjs(date).format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(
    swimlaneData.startTime ? getDateString(swimlaneData.startTime) : ''
  );
  const [endDate, setEndDate] = useState(
    swimlaneData.endTime ? getDateString(swimlaneData.endTime) : ''
  );
  const [showEndDate, setShowEndDate] = useState(!!endDate);

  const handleCheckboxClick = () => {
    if (showEndDate) {
      setShowEndDate(false);
      setEndDate('');
    } else {
      setShowEndDate(true);
      setEndDate(
        swimlaneData.endTime ? getDateString(swimlaneData.endTime) : ''
      );
    }
  };

  const handleClick = () => {
    onSchedule(getUnixTime(startDate), getUnixTime(endDate));
    onHide();
  };
  return (
    <KiteModal
      className="swimlane-schedule-modal"
      canShow={isOpen}
      title="Schedule Publish"
      disableBackgroundClose
      ctaAction={handleClick}
      ctaCopy="Schedule"
      secondaryCtaAction={onHide}
      secondaryCtaCopy="Cancel"
    >
      <p>This will publish the swimlane to Production.</p>
      <DatePicker
        onDateChange={(date) => setStartDate(date)}
        value={startDate}
        label="Publish Date"
        minDate={getDateString(new Date())}
      />
      <KiteCheckbox
        name="toggle-end-date"
        id="toggle-end-date"
        label="End Date"
        checked={showEndDate}
        onChange={handleCheckboxClick}
        className="swimlane-schedule-modal__checkbox"
      />
      <p className="swimlane-schedule-modal__subtext">
        Set a date for this swimlane to be removed from production
      </p>
      {showEndDate && (
        <DatePicker
          onDateChange={(date) => setEndDate(date)}
          value={endDate}
          label="End Date"
          minDate={getDateString(new Date())}
        />
      )}
    </KiteModal>
  );
};

export default SwimlaneScheduleModal;
