import Keycloak from 'keycloak-js';
import { setAuthToken } from './setAuthToken';

const getAuthorization = async (setAuthorization) => {
  /** Setup for KeyCloak */
  const keyCloakConfiguration = {
    /** Should always be include */
    credentials: 'include',
    /** Will always be this URL */
    url: 'https://keycloak.spectrumtoolbox.com/auth',
    /** The realm your client is in, 'spectrumtoolbox' by default */
    realm: 'spectrumtoolbox',
    /** ClientId is the name of your client in KeyCloak */
    clientId: 'spectrum-access-admin-ui',
  };

  const keycloakInstance = Keycloak(keyCloakConfiguration);

  keycloakInstance
    .init({
      onLoad: 'login-required',
      checkLoginIframe: false,
    })
    .then(async (authenticated) => {
      if (authenticated) {
        const jwt = keycloakInstance.token;
        await setAuthToken(jwt);

        const payload = JSON.parse(window.atob(jwt.split('.')[1]));

        const pId = payload.sAMAccountName;
        const fullName = payload.name;
        const user = payload.preferred_username;
        const group = payload.resource_access['spectrum-access'];
        const roles = group ? group.roles : [];

        const isAdmin = true;

        setAuthorization({ roles, user, fullName, isAdmin, pId });
      }
    })
    .catch((err) => console.error('err', err));
};

export default getAuthorization;
