import React from 'react';
import { KiteModal } from '@kite/react-kite';

import './InvalidTMSIDModal.scss';

export interface IInvalidTMSIDModalProps {
  isOpen: boolean;
  onClose: () => any;
  onClick: () => any;
  featureType: 'episode' | 'series' | 'movie';
}

const InvalidTMSIDModal = ({
  isOpen,
  onClose,
  onClick,
  featureType,
}: IInvalidTMSIDModalProps) => {
  return (
    <KiteModal
      className="invalid-tmsid"
      canShow={isOpen}
      onHide={onClose}
      title={`Incorrect TMSID for ${featureType}`}
      disableBackgroundClose
      ctaAction={onClick}
      ctaCopy="Update TMSID"
      secondaryCtaAction={onClose}
      secondaryCtaCopy="Cancel"
    >
      <p className="unable-delete__copy">
        The metadata for this {featureType} is invalid. Please select a new
        TMSID to update the metadata.
      </p>
    </KiteModal>
  );
};

export default InvalidTMSIDModal;
