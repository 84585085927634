import React from 'react';
import { Link } from 'react-router-dom';
import { KiteIcon } from '@kite/react-kite';
import { SortableTable } from '@kite/react-kite-plus';

import ServiceIcon from '../ServiceIcon/ServiceIcon';
import TableStatus from '../TableStatus/TableStatus';

import { IMovie } from '../../utils/models';
import { renderTableDate } from '../../utils/helpers';

import './MoviesTable.scss';

export interface IMoviesTableProps {
  movieData: IMovie[];
  onSortClick: (sortHeader: string) => void;
  ascending: boolean;
  sortHeader: string;
  loading: boolean;
}

const MoviesTable = ({
  movieData,
  onSortClick,
  sortHeader,
  ascending,
  loading,
}: IMoviesTableProps) => {
  const renderServiceIcons = (services) => {
    return services.map(({ type, status, language }) => (
      <ServiceIcon type={type} status={status} language={language} />
    ));
  };
  const columns = [
    {
      label: 'Title',
      sortKey: 'title',
      size: 1.6,
    },
    {
      label: 'Added',
      sortKey: 'addedOn',
      render: ({ addedOn }) => renderTableDate(addedOn),
    },
    {
      label: 'Updated',
      sortKey: 'updatedOn',
      render: ({ updatedOn }) => renderTableDate(updatedOn),
    },
    {
      label: 'Feature Status',
      sortKey: 'status',
      render: ({ status, errors }) => (
        <TableStatus status={status} errors={errors} />
      ),
      size: 0.9,
    },
    {
      label: 'Services',
      sortKey: 'services',
      sortEnabled: false,
      render: (item) => renderServiceIcons(item.services),
      size: 0.5,
    },
    {
      label: '',
      sortKey: '',
      sortEnabled: false,
      render: (item) => (
        <Link to={`/movies/${item.id}`} className="movies-table__link">
          View Movie
          <KiteIcon name="chevron-right" color="#0073d1" />
        </Link>
      ),
      size: 0.5,
    },
  ];
  return (
    <SortableTable
      className="movies-table"
      columns={columns}
      tableData={movieData}
      initialSortHeader="title"
      onSortClick={onSortClick}
      sortHeader={sortHeader}
      reverseSort={!ascending}
      loading={loading}
    />
  );
};

export default MoviesTable;
