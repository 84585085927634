import React from 'react';
import { KiteModal, KiteButton } from '@kite/react-kite';

import './PublishServices.scss';

export interface IPublishServicesProps {
  isOpen: boolean;
  onPublish: (ids: string[]) => any;
  onHide: (bool: boolean) => any;
  serviceIDs: string[];
}

const PublishServices = ({
  isOpen,
  onPublish,
  onHide,
  serviceIDs,
}: IPublishServicesProps) => {
  const handlePublish = () => {
    onPublish(serviceIDs);
    onHide(false);
  };

  const publishTitle = 'Publish Service';
  const publishAllTitle = 'Publish Services';

  const publishCopy =
    'Publishing this service will make it available to be shown in the app. It will not be visible on this app until this episode is published.';

  const publishAllCopy =
    'Publishing these services will make them available to be shown in the app. They will not be visible on this app until this episode is published.';

  let title, copy;

  if (serviceIDs.length > 1) {
    title = publishAllTitle;
    copy = publishAllCopy;
  } else {
    title = publishTitle;
    copy = publishCopy;
  }

  return (
    <KiteModal
      className="publish-services"
      canShow={isOpen}
      title={title}
      disableBackgroundClose
      showCloseButton={false}
    >
      <p className="publish-services__copy">{copy}</p>

      <div className="publish-services__button-wrapper">
        <KiteButton
          type="outline"
          className="publish-services__button publish-services__button--cancel"
          onClick={() => onHide(false)}
        >
          Cancel
        </KiteButton>
        <KiteButton
          className="publish-services__button publish-services__button--publish"
          onClick={handlePublish}
        >
          Publish
        </KiteButton>
      </div>
    </KiteModal>
  );
};

export default PublishServices;
