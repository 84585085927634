import React, { useEffect, useState } from 'react';
import { KiteModal, KiteButton, KiteRadio } from '@kite/react-kite';
import { DatePicker, TextArea } from '@kite/react-kite-plus';

import './ExpirationDateModal.scss';

export interface IExpirationDateModalProps {
  isOpen: boolean;
  onClose: () => any;
  onSave: (radioValue: string, dateValue: string | null) => any;
  endTime: string | null;
  customEndTime: string | null;
  useCmsExpiration: boolean;
  loading?: boolean;
}

const ExpirationDateModal = ({
  isOpen,
  onClose,
  onSave,
  endTime,
  customEndTime,
  useCmsExpiration,
  loading,
}: IExpirationDateModalProps) => {
  const [radioValue, setRadioValue] = useState('none');
  const [dateValue, setDateValue] = useState<string | null>(null);
  const [dateError, setDateError] = useState(false);

  const setInitialState = () => {
    let value;
    if (useCmsExpiration) {
      value = 'eCMS';
    } else if (!useCmsExpiration && customEndTime) {
      value = 'manual';
    } else {
      value = 'none';
    }
    setRadioValue(value);
    setDateValue(endTime);
  };

  useEffect(setInitialState, [endTime, useCmsExpiration]);

  const handleClose = () => {
    setInitialState();
    onClose();
  };

  const handleSave = () => {
    if (radioValue === 'manual' && !dateValue) {
      setDateError(true);
    } else {
      onSave(radioValue, dateValue);
    }
  };

  const radioButtons = [
    {
      label: 'No Expiration Date Needed',
      value: 'none',
      checked: radioValue === 'none',
    },
    {
      label: 'Set Automatically with eCMS',
      value: 'eCMS',
      checked: radioValue === 'eCMS',
    },
    {
      label: 'Manually Set Expiration Date',
      value: 'manual',
      checked: radioValue === 'manual',
    },
  ];

  return (
    <KiteModal
      className="exp-date-modal"
      canShow={isOpen}
      title="Expiration Date"
      disableBackgroundClose
      showCloseButton={false}
    >
      <KiteRadio
        radioButtons={radioButtons}
        onChange={(e) => setRadioValue(e.target.value)}
        name="expiration-date-type"
      />
      {radioValue === 'manual' ? (
        <DatePicker
          label="Expiration Date"
          value={dateValue || ''}
          onDateChange={setDateValue}
        />
      ) : (
        <div className="exp-date-modal__date-placeholder" />
      )}
      <div className="exp-date-modal__button-wrapper">
        <KiteButton
          type="outline"
          className="exp-date-modal__button exp-date-modal__button--cancel"
          onClick={handleClose}
        >
          Cancel
        </KiteButton>
        <KiteButton
          className="exp-date-modal__button exp-date-modal__button--save"
          onClick={handleSave}
          loading={loading}
        >
          Confirm
        </KiteButton>
      </div>
    </KiteModal>
  );
};

export default ExpirationDateModal;
