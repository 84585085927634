import axios, { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';

import { IMovie, IService } from '../utils/models';
import { INotesCardData } from '../components/NotesCard/NotesCard';

export const createMovie = async (movieData) => {
  const {
    categories,
    countries,
    fpFiles,
    primaryCategory,
    tmsId,
    title,
    useCmsExpiration,
    endTime,
    customEndTime,
  } = movieData;
  const bodyData = {
    allCategoryIds: categories,
    countryWhitelist: countries,
    fingerprintFiles: fpFiles,
    primaryCategoryId: primaryCategory,
    status: 'New',
    available: 'Yes',
    tmsId,
    title,
    useCmsExpiration,
    endTime,
    customEndTime,
  };

  const options: AxiosRequestConfig = {
    method: 'POST',
    data: JSON.stringify(bodyData),
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const res = await axios('/api/v1/movies', options);
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
const serviceValuesIn = {
  audio_description: 'Audio Description only',
  audio_description_and_full_audio: 'Audio Description + Full Audio',
  closed_captions: 'Closed Captioning',
  amplified_audio: 'Amplified Audio',
  sign_language: 'Sign Language',
};

export const serviceCleaner = (service, statusValues) => {
  const {
    id,
    serviceType: type,
    language,
    createdAt,
    updatedAt,
    status,
    file,
  } = service;

  const created = dayjs(createdAt).format('h:mm A M/D/YY');
  const updated = dayjs(updatedAt).format('h:mm A M/D/YY');

  const cleanLanguage =
    language === 'en' ? 'English' : language === 'es' ? 'Spanish' : language;

  const cleanService: IService = {
    id,
    type: serviceValuesIn[type] || type,
    language: cleanLanguage,
    status: statusValues[status],
    created,
    updated,
    file: file,
  };

  return cleanService;
};

export const movieCleaner = (movie, useMetadata) => {
  const {
    id,
    createdAt,
    updatedAt,
    tmsId,
    title,
    status,
    fingerprintFiles,
    metadata,
    error,
    notes,
    primaryCategory,
    primaryCategoryId,
    allCategories,
    contents,
    endTime,
    useCmsExpiration,
    customEndTime,
  } = movie;

  const posterUrl = `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Poster&width=250`;
  const epicUrl = `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Iconic&width=400`;

  const notesObject: INotesCardData = {
    updatedTimestamp: notes ? notes.split('---')[0] : '',
    notes: notes ? notes.split('---')[1] : '',
  };
  const statusValues = {
    New: 'New',
    QualityControl: 'Quality Control',
    ReadyForProduction: 'Ready for Production',
    Production: 'In Production',
  };

  const cleanServices: any[] = contents
    ? contents
        .map((content) => serviceCleaner(content, statusValues))
        .sort((a, b) => a.type.localeCompare(b.type))
    : [];

  const cleanCategories = allCategories.map((cat) =>
    cat.name ? cat : primaryCategory
  );
  const cleanFiles = fingerprintFiles.map((file) => ({
    id: file.file,
    language: file.language,
    file: file.file,
  }));

  const cleanErrors = error ? error.split('---') : [];

  const cleanMovie: IMovie = {
    id,
    tmsId,
    title,
    addedOn: createdAt,
    updatedOn: updatedAt,
    status: statusValues[status],
    services: cleanServices,
    categories: cleanCategories,
    primaryCategory,
    primaryCategoryId,
    errors: cleanErrors,
    year: 0,
    rating: '',
    duration: 0,
    description: '',
    genres: [],
    posterUrl,
    epicUrl,
    notes: notesObject,
    files: cleanFiles,
    badMetadata: false,
    unsyncedMetadata: false,
    endTime,
    useCmsExpiration,
    customEndTime,
  };

  if (useMetadata && metadata) {
    const {
      releaseYear,
      shortDescription: { en: description },
      duration,
      ratings,
      genres,
      title: { en: updatedTitle },
      metadata: { groupTitle },
    } = metadata;

    cleanMovie.year = releaseYear;
    cleanMovie.description = description;
    cleanMovie.duration = duration / 60;

    const newTitle = updatedTitle || groupTitle;

    if (newTitle !== title) {
      cleanMovie.unsyncedMetadata = true;
    }
    if (ratings && ratings.mpaa) {
      cleanMovie.rating = ratings.mpaa.value;
    } else if (ratings && ratings.usTv) {
      cleanMovie.rating = ratings.usTv.value;
    }
    if (genres && genres.length) {
      const cleanGenres = genres.map((g) => g.name?.en).join(', ');
      cleanMovie.genres = cleanGenres;
    }
  } else if (useMetadata && !metadata) {
    cleanMovie.badMetadata = true;
  }

  return cleanMovie;
};

export const getMovie = async (id) => {
  const res = await axios(`/api/v1/movies/${id}?metadata=true`);
  return movieCleaner(res.data, true);
};

export const getNewMovieMetadata = async (id) => {
  const res = await axios(`/api/v1/movies/${id}?metadata=true`);
  const {
    title,
    id: tmsId,
    metadata: { groupTitle },
  } = res.data.metadata;

  return {
    title: title.en || groupTitle,
    tmsId,
  };
};

export const updateMovie = async (id, fields) => {
  const updatedFields = { ...fields, available: 'Yes' };
  const options: AxiosRequestConfig = {
    method: 'PATCH',
    data: JSON.stringify(updatedFields),
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res = await axios(`/api/v1/movies/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const deleteMovie = async (id) => {
  const options: AxiosRequestConfig = {
    method: 'DELETE',
  };
  try {
    const res = await axios(`/api/v1/movies/${id}`, options);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getAllMovies = async (config) => {
  const { order, page, sortBy, title, status, size } = config;
  const headers = {
    title: 'title',
    status: 'status',
    addedOn: 'createdAt',
    updatedOn: 'updatedAt',
  };
  const formattedQueryWithNoSplChars = title
    ? title.replaceAll(/[:&?*!]/gm, '')
    : '';
  const titleQuery = title ? `&title=${formattedQueryWithNoSplChars}` : '';
  const statusQuery = status && status !== 'All' ? `&status=${status}` : '';
  const sizeQuery = size ? `&size=${size}` : '';
  const res = await axios(
    `/api/v1/movies?order=${order}&page=${page}${sizeQuery}&sortBy=${headers[sortBy]}${titleQuery}${statusQuery}`
  );

  const { content, totalPages, totalElements, numberByStatus } = res.data;

  const {
    New: totalNew,
    QualityControl: totalQC,
    ReadyForProduction: totalRFP,
    Production: totalProd,
  } = numberByStatus;

  const cleanMovies = content.map((movie) => movieCleaner(movie, false));

  const totalMovies = totalNew + totalQC + totalRFP + totalProd;

  return {
    allMovies: cleanMovies,
    totalPages,
    totalMovies,
    totalNew,
    totalQC,
    totalRFP,
    totalProd,
  };
};
