import axios from 'axios';

const resultsCleaner = (data, type) => {
  return data.map((result) => {
    const {
      title: { en: title },
      id: tmsId,
      duration: seconds,
      shortDescription,
      releaseYear: year,
      ratings,
      genres,
    } = result;
    let language, description;

    const languages = {
      en: 'English',
      es: 'Spanish',
      de: 'German',
      'fr-CA': 'French',
      'pt-BR': 'Portuguese',
    };

    if (!shortDescription) {
      language = '';
      description = '';
    } else {
      let shortLang = Object.keys(shortDescription)[0];
      language = languages[shortLang] || shortLang;
      description = shortDescription[shortLang];
    }

    const duration = seconds ? seconds / 60 : 0;

    let rating;

    if (ratings && ratings.mpaa) {
      rating = ratings.mpaa.value;
    } else if (ratings && ratings.usTv) {
      rating = ratings.usTv.value;
    }

    const cleanGenres =
      genres && genres.length ? genres.map((g) => g.name?.en).join(', ') : [];

    const posterUrl = `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Poster&width=250`;
    const epicUrl = `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Iconic&width=400`;
    return {
      title,
      tmsId,
      posterUrl,
      type,
      epicUrl,
      duration,
      language,
      description,
      year,
      rating,
      genres: cleanGenres,
    };
  });
};

const episodeResCleaner = (data, type) => {
  return data.map((result) => {
    const {
      title: { en: seriesTitle },
      episodeTitle,
      id: episodeTmsid,
      shortDescription,
      seriesId,
      episodeNum: episode,
      seasonNum: season,
      releaseYear: year,
      ratings,
    } = result;

    const { en: description } = shortDescription || {};

    const title = episodeTitle ? episodeTitle.en : '';

    let rating;

    if (ratings && ratings.mpaa) {
      rating = ratings.mpaa.value;
    } else if (ratings && ratings.usTv) {
      rating = ratings.usTv.value;
    }

    const posterUrl = `https://cdnimg.spectrum.net/imageserver/program/${episodeTmsid}?sourceType=colorHybrid&twccategory=Poster&width=250`;
    const epicUrl = `https://cdnimg.spectrum.net/imageserver/program/${episodeTmsid}?sourceType=colorHybrid&twccategory=Iconic&width=400`;

    const cleanResult = {
      series: seriesTitle,
      title,
      episodeTmsid,
      seriesTmsid: seriesId,
      type,
      description,
      episode,
      season,
      year,
      rating,
      posterUrl,
      epicUrl,
    };
    return cleanResult;
  });
};

const seriesResCleaner = (data) => {
  const {
    title: { en: title },
    description: longDescription,
    shortDescription,
    ratings,
    totalSeasons: seasons,
    genres,
    id: tmsId,
  } = data;

  const { en: description } = shortDescription || longDescription || {};

  let rating;

  if (ratings && ratings.mpaa) {
    rating = ratings.mpaa.value;
  } else if (ratings && ratings.usTv) {
    rating = ratings.usTv.value;
  }

  const posterUrl = `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Poster&width=250`;
  const epicUrl = `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Iconic&width=400`;

  const genre = genres.map((genre) => genre.name.en).join(', ');

  const cleanResult = {
    title,
    description,
    seasons,
    genre,
    rating,
    posterUrl,
    epicUrl,
    tmsId,
  };
  return cleanResult;
};

export const searchDS = async (query: string, types: string[]) => {
  const formattedQuery = query.split(' ').join();
  const formattedQueryWithNoSplChars = formattedQuery.replaceAll(
    /[:&?*!]/gm,
    ''
  );
  const formattedTypes = types.join('%2C%20');
  const url = `/api/v1/search/ds?keyword=${formattedQueryWithNoSplChars}&types=${formattedTypes}`;

  const res = await axios(url);
  const { data } = res;

  let cleanData;

  if (types[0] === 'episode') {
    cleanData = {
      new: episodeResCleaner(data.others, 'new'),
      existing: episodeResCleaner(data.suggests, 'existing'),
    };
  } else {
    cleanData = {
      new: resultsCleaner(data.others, 'new'),
      existing: resultsCleaner(data.suggests, 'existing'),
    };
  }

  return cleanData;
};

export const searchByTmsid = async (tmsid: string) => {
  const url = `/api/v1/search/ds/${tmsid}`;

  const { data } = await axios(url);

  if (!data) {
    return null;
  } else if (data.objectType === 'Series') {
    return seriesResCleaner(data);
  } else if (data.objectType === 'Episode') {
    return episodeResCleaner([data], 'existing')[0];
  } else {
    const cleanData = resultsCleaner([data], 'existing')[0];
    return cleanData;
  }
};
