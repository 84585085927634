import React, { useState, useEffect } from 'react';

import FilterMenu from '../FilterMenu';
import { KiteButton, KiteIcon } from '@kite/react-kite';

import './SearchBar.scss';
// import { TFilters } from '../FilterMenu/FilterMenu';

export interface ISearchBarProps {
  featureType: 'Series' | 'Assets' | 'Episodes' | 'Movies' | 'Titles';
  // filters?: TFilters;
  statusFilter?: string;
  startingQuery?: string;
  // onFilterSelect?: (filter: TFilters) => void;
  onFilterSelect?: (filter: string) => void;
  onSearch: (query: string) => void;
  showShadow: boolean;
  statusCounts?: any;
}

const SearchBar = ({
  featureType,
  statusFilter,
  onFilterSelect,
  onSearch,
  startingQuery,
  showShadow,
  statusCounts,
}: ISearchBarProps) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(startingQuery || '');
  }, [startingQuery]);

  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      onSearch(value);
    }
  };

  const handleClear = () => {
    setValue('');
    onSearch('');
  };

  const handleChange = (value) => {
    setValue(value);
    if (!value) {
      onSearch('');
    }
  };

  return (
    <div className="search-bar">
      {featureType !== 'Titles' && statusFilter && onFilterSelect && (
        <FilterMenu
          type={featureType}
          statusFilter={statusFilter}
          onSelect={onFilterSelect}
          statusCounts={statusCounts}
        />
      )}
      <div className="search-bar__input-wrapper">
        <input
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={(e) => handleKeydown(e)}
          className="search-bar__input"
          placeholder={`Search${
            featureType === 'Titles' ? '' : ' All'
          } ${featureType}`}
          value={value}
        />
        <KiteIcon className="search-bar__icon" name="search" size="20px" />
        {value && (
          <KiteIcon
            className="search-bar__clear"
            name="x-circle"
            onClick={handleClear}
          />
        )}
      </div>
      <KiteButton
        className="search-bar__button"
        type={featureType === 'Titles' ? 'primary' : 'outline'}
        onClick={() => onSearch(value)}
      >
        Search {featureType}
      </KiteButton>
      {showShadow && (
        <KiteIcon
          name="plus-circle-f"
          size="340px"
          className="add-movie__icon-shadow"
          color="#f8f8f8"
        />
      )}
    </div>
  );
};

export default SearchBar;
