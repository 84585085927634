import { KiteModal } from '@kite/react-kite';
import React from 'react';
import { sortPriority } from '../../utils/helpers';
import { ISwimlane } from '../../utils/models';

import './SwimlanePreview.scss';

export interface ISwimlanePreviewProps {
  swimlanes: ISwimlane[];
  isOpen: boolean;
  onClose: () => void;
}

const SwimlanePreview = ({
  swimlanes,
  isOpen,
  onClose,
}: ISwimlanePreviewProps) => {
  const renderSLPosters = (items) => {
    const renderList = items.length
      ? items.filter((i) => i.priorityNumber).sort(sortPriority())
      : new Array(10).fill({ data: {} });
    return renderList.map((item) => (
      <img src={getImageUrl(item.data.tmsId)} alt={item.data.title} />
    ));
  };
  const getImageUrl = (tmsId) =>
    `https://cdnimg.spectrum.net/imageserver/program/${tmsId}?sourceType=colorHybrid&twccategory=Poster&width=110`;

  const swimlaneRows = swimlanes.map((swimlane) => (
    <div className="swimlane-preview__swimlane" key={swimlane.id}>
      <h2>{swimlane.name}</h2>
      <div className="swimlane-preview__scroll">
        {renderSLPosters(swimlane.items)}
      </div>
    </div>
  ));
  return (
    <div className="kite-theme-dark">
      <KiteModal
        className="swimlane-preview"
        canShow={isOpen}
        showCloseButton={false}
        title="Production Preview"
        secondaryCtaAction={onClose}
        secondaryCtaCopy="Close"
      >
        <div className="swimlane-preview__wrapper">{swimlaneRows}</div>
      </KiteModal>
    </div>
  );
};

export default SwimlanePreview;
