import {
  KiteAlert,
  KiteButton,
  KiteIcon,
  KiteInput,
  KiteModal,
  KiteTooltip,
} from '@kite/react-kite';
import React, { Component } from 'react';
import { RouterComponentProps, Link, Prompt } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';
import { ISwimlane } from '../../utils/models';
import {
  createSwimlane,
  getAllSwimlanes,
  deleteSwimlane,
  updateSwimlane,
} from '../../apiCalls/SwimlaneService';
import './AllSwimlanes.scss';
import { DeleteFeature, SwimlanePreview } from '../../components';
import { sortPriority } from '../../utils/helpers';

export interface IAllSwimlanesState {
  swimlanes: ISwimlane[];
  stageSwimlanes: ISwimlane[];
  showModal: 'create' | 'delete' | 'deploy' | 'preview' | '';
  loadingButton: 'create' | 'delete' | 'deploy' | '';
  targetId: string;
  loading: boolean;
  inputValue: string;
  inputError: string;
  unsavedChanges: boolean;
}

class AllSwimlanes extends Component<RouterComponentProps, IAllSwimlanesState> {
  state: IAllSwimlanesState = {
    swimlanes: [],
    stageSwimlanes: [],
    showModal: '',
    loadingButton: '',
    targetId: '',
    loading: false,
    inputValue: '',
    inputError: '',
    unsavedChanges: false,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    this.getSwimlanes();
    const [analytics] = this.context;
    analytics.trackPageView('New Assets');
  }

  componentDidUpdate = () => {
    if (this.state.unsavedChanges) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  };

  getSwimlanes = () => {
    this.setState({ loading: true });
    getAllSwimlanes().then((swimlanes) =>
      this.setState({
        swimlanes,
        stageSwimlanes: swimlanes,
        loading: false,
        loadingButton: '',
        showModal: '',
        inputValue: '',
        unsavedChanges: false,
      })
    );
  };

  updatePriority = (id: string, type: 'increase' | 'decrease') => {
    const { stageSwimlanes } = this.state;
    const operator = type === 'increase' ? -1.5 : 1.5;
    const newSwimlanes = stageSwimlanes
      .map((sl) =>
        sl.id === id
          ? {
              ...sl,
              priorityNumber: sl.priorityNumber
                ? sl.priorityNumber + operator
                : null,
            }
          : sl
      )
      .sort(sortPriority())
      .map((sl, i) => ({ ...sl, priorityNumber: i + 1 }));
    this.setState({ stageSwimlanes: newSwimlanes, unsavedChanges: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: '', inputValue: '' });
  };

  handleCreateSwimlane = async () => {
    const { inputValue: name, swimlanes } = this.state;
    if (!name.length) {
      this.setState({ inputError: 'Swimlane name is required' });
    } else if (swimlanes.find((sl) => sl.name === name)) {
      this.setState({ inputError: 'Swimlane Name is taken' });
    } else {
      this.setState({ loadingButton: 'create' });
      await createSwimlane(name, swimlanes.length + 1);
      this.getSwimlanes();
    }
  };

  handleDelete = async () => {
    const { targetId } = this.state;
    this.setState({ loadingButton: 'delete' });
    await deleteSwimlane(targetId);
    this.getSwimlanes();
  };

  handleDeploy = async () => {
    this.setState({ loadingButton: 'deploy' });
    const { stageSwimlanes } = this.state;
    const slPromises = stageSwimlanes.map((sl) => {
      const { id, status, priorityNumber } = sl;
      const updateFields: any = { priorityNumber };
      if (status === 'Ready') {
        updateFields.status = 'Published';
      }
      return updateSwimlane(id, updateFields);
    });

    await Promise.all(slPromises);
    this.getSwimlanes();
  };

  render() {
    const {
      swimlanes,
      stageSwimlanes,
      showModal,
      inputValue,
      inputError,
      loadingButton,
      unsavedChanges,
    } = this.state;

    const getScheduleTime = (startTime, endTime) => {
      const beforeWindow = startTime && new Date(startTime) > new Date();
      const afterWindow = endTime && new Date(endTime) < new Date();
      if (beforeWindow) {
        return 'scheduled';
      }
      if (afterWindow) {
        return 'expired';
      } else {
        return 'live';
      }
    };

    const getStatusText = (status, startTime, endTime) => {
      if (status === 'NotReady') {
        return 'Not Ready';
      } else if (getScheduleTime(startTime, endTime) === 'scheduled') {
        return `Scheduled ${dayjs(startTime).format('M/D/YYYY')}`;
      } else if (getScheduleTime(startTime, endTime) === 'expired') {
        return (
          <span className="swimlanes__tile-status--expired">
            Expired {dayjs(endTime).format('M/D/YYYY')}
          </span>
        );
      } else {
        return status;
      }
    };

    const generateTiles = (type: 'stage' | 'prod' | 'preview') => {
      let slList;

      if (type === 'prod') {
        slList = swimlanes.filter(
          ({ status, startTime, endTime }) =>
            status === 'Published' &&
            getScheduleTime(startTime, endTime) === 'live'
        );
      } else if (type === 'preview') {
        slList = stageSwimlanes.filter(
          ({ status, startTime, endTime }) =>
            status !== 'NotReady' &&
            getScheduleTime(startTime, endTime) !== 'expired'
        );
      } else {
        slList = stageSwimlanes;
      }

      return slList.map((sl, i) => {
        const { name, id, status, items, isStatic, startTime, endTime } = sl;
        return (
          <div
            key={id}
            className={classNames({
              swimlanes__tile: true,
              'swimlanes__tile--published':
                status === 'Published' || type === 'preview',
            })}
          >
            <div className="swimlanes__tile-details">
              {isStatic ? (
                <p className="swimlanes__tile-name">{name}</p>
              ) : (
                <Link className="swimlanes__tile-link" to={`/swimlanes/${id}`}>
                  {name}
                  <KiteIcon
                    name="chevron-right"
                    color="#0073d1"
                    size="12px"
                    margin="0 0 0 4px"
                  />
                </Link>
              )}
              {!isStatic && `${items.length} Titles • `}
              <span className="swimlanes__tile-status">
                {getStatusText(status, startTime, endTime)}
              </span>
            </div>
            <div className="swimlanes__tile-right">
              {type === 'stage' && (
                <>
                  {i === slList.length - 1 ? (
                    <KiteIcon
                      color="#9ba9bd"
                      size="24px"
                      margin="0 16px 0 0"
                      name="chevron-down"
                    />
                  ) : (
                    <KiteIcon
                      color="#0073d1"
                      size="24px"
                      margin="0 16px 0 0"
                      onClick={() => this.updatePriority(id, 'decrease')}
                      name="chevron-down"
                    />
                  )}
                  {i === 0 ? (
                    <KiteIcon
                      color="#9ba9bd"
                      size="24px"
                      margin="0 24px 0 0"
                      name="chevron-up"
                    />
                  ) : (
                    <KiteIcon
                      color="#0073d1"
                      size="24px"
                      margin="0 24px 0 0"
                      onClick={() => this.updatePriority(id, 'increase')}
                      name="chevron-up"
                    />
                  )}
                </>
              )}
              {isStatic && (
                <KiteTooltip>
                  This swimlane is determined by user specific data.
                </KiteTooltip>
              )}
              {!isStatic && type === 'stage' && (
                <KiteIcon
                  color="#d6312b"
                  size="18px"
                  onClick={() =>
                    this.setState({ showModal: 'delete', targetId: id })
                  }
                  name="trash-f"
                />
              )}
            </div>
          </div>
        );
      });
    };

    const createModal = (
      <KiteModal
        canShow={showModal === 'create'}
        onHide={this.handleCloseModal}
        title="Create Swimlane"
        className="swimlanes__create-modal"
        ctaCopy="Create Swimlane"
        ctaAction={this.handleCreateSwimlane}
        ctaLoading={loadingButton === 'create'}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={this.handleCloseModal}
      >
        <KiteInput
          id="create-swimlane"
          value={inputValue}
          label="Swimlane Name*"
          maxWidth="100%"
          onChange={(e) =>
            this.setState({ inputValue: e.target.value, inputError: '' })
          }
          errorMessage={inputError}
        />
      </KiteModal>
    );

    const deployModal = (
      <KiteModal
        canShow={showModal === 'deploy'}
        className="swimlanes__deploy-modal"
        title="Deploy to Production"
        ctaCopy="Deploy"
        ctaAction={this.handleDeploy}
        ctaLoading={loadingButton === 'deploy'}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={() => this.setState({ showModal: '' })}
        onHide={() => this.setState({ showModal: '' })}
      >
        <p>
          This will update the swimlanes shown in the production app. Scheduled
          swimlanes and swimlanes that are Not Ready will not be moved.
        </p>
        <h2>Swimlane Order</h2>
        <div className="swimlanes__deploy-tiles">
          {generateTiles('preview')}
        </div>
      </KiteModal>
    );

    return (
      <>
        <main className="swimlanes">
          <h2 className="swimlanes__title">Swimlane</h2>
          <KiteButton
            className="swimlanes__create-btn"
            type="outline"
            onClick={() => this.setState({ showModal: 'create' })}
          >
            Create Swimlane
          </KiteButton>
          <div className="swimlanes__card-wrapper">
            <div className="swimlanes__card">
              <h3 className="swimlanes__card-title">In Staging App</h3>
              <p className="swimlanes__card-copy">
                Select the order of the swimlanes.
              </p>
              {unsavedChanges && (
                <KiteAlert
                  className="swimlanes__alert"
                  description="Deploy to Production to save changes"
                  level="inline"
                  title="Unsaved Changes"
                  type="caution"
                />
              )}
              <div className="swimlanes__card-cta">
                <KiteButton
                  size="small"
                  type="outline"
                  onClick={() => this.setState({ showModal: 'preview' })}
                >
                  Production Preview
                </KiteButton>
                <KiteButton
                  size="small"
                  onClick={() => this.setState({ showModal: 'deploy' })}
                >
                  Deploy to Production
                </KiteButton>
              </div>
              <div className="swimlanes__tile-wrapper">
                {generateTiles('stage')}
              </div>
            </div>
            <div className="swimlanes__card swimlanes__card--prod">
              <h3 className="swimlanes__card-title">In Production</h3>
              <p className="swimlanes__card-copy">
                These are the swimlanes currently in production
              </p>
              <div className="swimlanes__tile-wrapper">
                {generateTiles('prod')}
              </div>
            </div>
          </div>
        </main>
        {createModal}
        {deployModal}
        <DeleteFeature
          isOpen={showModal === 'delete'}
          assetTitle=""
          onHide={() => this.setState({ showModal: '', targetId: '' })}
          onDelete={this.handleDelete}
          buttonLoading={loadingButton === 'delete'}
          assetType="swimlane"
        />
        <SwimlanePreview
          swimlanes={stageSwimlanes}
          isOpen={showModal === 'preview'}
          onClose={() => this.setState({ showModal: '' })}
        />
        <Prompt
          when={unsavedChanges}
          message="You have unsaved changes. Are you sure you want to leave?"
        />
      </>
    );
  }
}

export default AllSwimlanes;
