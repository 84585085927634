import React from 'react';

import './DuplicateServiceModal.scss';
import { KiteModal } from '@kite/react-kite';

export interface IDuplicateServiceModalProps {
  isOpen: boolean;
  onClick: (option: 'replace' | 'keep') => void;
  onHide: () => any;
  services: string[];
}

const DuplicateServiceModal = ({
  isOpen,
  onClick,
  onHide,
  services,
}: IDuplicateServiceModalProps) => {
  const handleClick = (type) => {
    onClick(type);
    onHide();
  };
  return (
    <KiteModal
      className="duplicate-service"
      canShow={isOpen}
      title="Service already exists"
      disableBackgroundClose
      showCloseButton={false}
      ctaAction={() => handleClick('keep')}
      ctaCopy="Keep Both"
      secondaryCtaAction={() => handleClick('replace')}
      secondaryCtaCopy="Replace Existing"
      tertiaryCtaAction={onHide}
      tertiaryCtaCopy="Cancel"
    >
      <p>The following services already exist for this feature</p>
      <ul>
        {services.map((ser) => (
          <li>{ser}</li>
        ))}
      </ul>
      <p>Would you like to replace existing services?</p>
    </KiteModal>
  );
};

export default DuplicateServiceModal;
