import React from 'react';
import { KiteModal, KiteButton } from '@kite/react-kite';

import './UnableToDeleteModal.scss';

export interface IUnableToDeleteModalProps {
  isOpen: boolean;
  onClose: () => any;
  episodeCount: number;
}

const UnableToDeleteModal = ({
  isOpen,
  onClose,
  episodeCount,
}: IUnableToDeleteModalProps) => {
  const episodeCopy =
    episodeCount > 1 ? `are ${episodeCount} episodes` : 'is 1 episode';
  return (
    <KiteModal
      className="unable-delete"
      canShow={isOpen}
      title="Unable to Delete Series Until All Episodes are Deleted"
      disableBackgroundClose
      showCloseButton={false}
    >
      <p className="unable-delete__copy">
        There {episodeCopy} currently in this series. You must delete them
        before deleting the series.
      </p>
      <KiteButton
        type="outline"
        className="unable-delete__button"
        onClick={onClose}
      >
        Cancel
      </KiteButton>
    </KiteModal>
  );
};

export default UnableToDeleteModal;
