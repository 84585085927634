import React, { useEffect, useState } from 'react';
import { KiteModal, KiteButton } from '@kite/react-kite';

import './Notes.scss';
import { TextArea } from '@kite/react-kite-plus';
import { INotesCardData } from '../NotesCard/NotesCard';

export interface INotesProps {
  isOpen: boolean;
  notes: INotesCardData;
  onClose: () => any;
  onSave: (notes: string) => any;
}

const Notes = ({ isOpen, onClose, onSave, notes: noteData }: INotesProps) => {
  const [notes, setNotes] = useState(noteData.notes);
  const [notesError, setNotesError] = useState(false);

  useEffect(() => setNotes(noteData.notes), [noteData]);

  const handleClose = () => {
    setNotes('');
    setNotesError(false);
    onClose();
  };

  const handleSave = () => {
    if (notes.length) {
      onSave(notes);
      handleClose();
    } else {
      setNotesError(true);
    }
  };

  const handleChange = (e) => {
    setNotes(e.target.value);
    setNotesError(false);
  };

  return (
    <KiteModal
      className="notes"
      canShow={isOpen}
      title="Notes"
      disableBackgroundClose
      showCloseButton={false}
    >
      <TextArea
        id="notes-input"
        onChange={handleChange}
        value={notes}
        height="300px"
        errorMessage={notesError ? 'Please add a note before saving' : ''}
      />
      <div className="notes__button-wrapper">
        <KiteButton
          type="outline"
          className="notes__button notes__button--cancel"
          onClick={handleClose}
        >
          Cancel
        </KiteButton>
        <KiteButton
          className="notes__button notes__button--save"
          onClick={handleSave}
        >
          Save
        </KiteButton>
      </div>
    </KiteModal>
  );
};

export default Notes;
