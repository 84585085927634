import React, { Component } from 'react';
import { RouterComponentProps, Link } from 'react-router-dom';
import * as SearchService from '../../apiCalls/SearchService';

import {
  SearchBar,
  Metadata,
  CountriesCard,
  CategoriesCard,
  ServerError,
  SeriesResult,
} from '../../components';
import { KiteIcon, KiteCard, KiteButton, KiteLoader } from '@kite/react-kite';

import { ICategory } from '../../utils/models';
import './AddSeries.scss';
import { ISeriesResult } from '../../components/SeriesResult/SeriesResult';
import { createSeries } from '../../apiCalls/SeriesService';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';

export interface IAddSeriesState {
  results: ISeriesResult[];
  currentStep: number;
  selectedSeries: ISeriesResult | null;
  selectedCategories: ICategory[];
  primaryCategory: ICategory | null;
  serverError: boolean;
  loading: boolean;
}

class AddSeries extends Component<RouterComponentProps, IAddSeriesState> {
  state: IAddSeriesState = {
    results: [],
    currentStep: 1,
    selectedSeries: null,
    selectedCategories: [],
    primaryCategory: null,
    serverError: false,
    loading: false,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    const [analytics] = this.context;
    analytics.trackPageView('Add Series');
  }

  handleSearch = async (query) => {
    this.setState({ loading: true });
    const data = await SearchService.searchDS(query, ['series']);
    this.setState({ results: [...data.new, ...data.existing], loading: false });
  };

  handleAddClick = (id) => {
    const { results } = this.state;
    const targetSeries = results.find((series) => series.tmsId === id);
    // const { categories, primaryCategory } = targetSeries || {};

    this.setState({
      selectedSeries: targetSeries || null,
      currentStep: 2,
      // selectedCategories: categories || [],
      // primaryCategory: primaryCategory || '',
    });
    window.scrollTo(0, 0);
  };

  handleCategorySelect = (categories) => {
    this.setState({ selectedCategories: categories });
  };

  handlePrimaryCategorySelect = (category) => {
    this.setState({ primaryCategory: category });
  };

  handleCategoryWarning = () => {
    console.log('Category Warning');
  };

  handleBackClick = () => {
    const { currentStep } = this.state;

    this.setState({
      currentStep: currentStep - 1,
      selectedSeries: null,
      selectedCategories: [],
      primaryCategory: null,
    });

    window.scrollTo(0, 0);
  };

  handleAddSeries = async () => {
    const { selectedSeries, selectedCategories, primaryCategory } = this.state;

    const { tmsId, title } = selectedSeries || {};

    const seriesData = {
      categories: selectedCategories.map((cat) => cat.id),
      countries: ['United States'],

      primaryCategory: primaryCategory ? primaryCategory.id : '',
      tmsId,
      title,
    };

    const seriesResponse = await createSeries(seriesData);

    if (!seriesResponse) {
      this.setState({ serverError: true });
      return null;
    }

    this.props.history.push(`/series/${seriesResponse.id}`);
  };

  render() {
    const {
      results,
      currentStep,
      selectedSeries,
      selectedCategories,
      primaryCategory,

      serverError,
      loading,
    } = this.state;

    const displaySeriesResults = results.map((series) => (
      <SeriesResult
        key={series.tmsId}
        series={series}
        onAdd={this.handleAddClick}
      />
    ));

    const steps = {
      1: (
        <>
          <p className="add-series__step">Step 1: Search for Series to Add</p>
          <KiteCard className="add-series__results-card">
            <p className="add-series__title">Search by Title</p>
            <SearchBar
              featureType="Titles"
              onSearch={this.handleSearch}
              showShadow={!results.length}
            />
            {!!results.length && (
              <p className="add-series__title">
                Found Series ({results.length})
              </p>
            )}
            {displaySeriesResults}
            {!results.length && !loading && (
              <p className="add-series__no-results">
                Search series by title to view available series
              </p>
            )}
            {loading && (
              <KiteLoader
                className="add-series__loader"
                loaderTitle="Loading Series Metadata"
                secondaryMessage="Searching for titles"
              />
            )}
          </KiteCard>
        </>
      ),
      2: selectedSeries ? (
        <>
          <p className="add-series__step">Step 2: Add Preferences</p>
          <Metadata
            assetType="series"
            data={selectedSeries}
            showAlert={false}
            onUpdate={() => {}}
            isNew
          />
          <CountriesCard countries={['United States']} />
          <CategoriesCard
            status="New"
            selectedCategories={selectedCategories}
            primaryCategory={primaryCategory}
            showMessage={false}
            onSelectCategories={this.handleCategorySelect}
            onSelectPrimary={this.handlePrimaryCategorySelect}
            onTriggerWarning={this.handleCategoryWarning}
          />

          <div className="add-series__button-wrapper">
            <KiteButton type="outline" onClick={this.handleBackClick}>
              Back
            </KiteButton>
            <KiteButton onClick={this.handleAddSeries}>Add Series</KiteButton>
          </div>
        </>
      ) : null,
    };

    let backLink = (
      <Link to="series" className="add-series__back-link">
        <KiteIcon name="chevron-left" size="22px" /> Back to Series
      </Link>
    );

    return (
      <div>
        <main className="add-series">
          {backLink}
          <h2>Add Series</h2>
          {steps[currentStep]}
        </main>
        <ServerError
          isOpen={serverError}
          onClose={() => this.setState({ serverError: false })}
        />
      </div>
    );
  }
}

export default AddSeries;
