import React from 'react';

import './AssetTypePicker.scss';
import {
  KiteModal,
  KiteButton,
  KiteIcon,
  KiteFontIcon,
} from '@kite/react-kite';

export interface IAssetTypePickerProps {
  isOpen: boolean;
  onSelect: (assetType: string) => void;
  onHide: (bool: boolean) => void;
  onRemove: () => void;
}

const AssetTypePicker = ({
  isOpen,
  onSelect,
  onRemove,
  onHide,
}: IAssetTypePickerProps) => {
  const handleSelect = (assetType: string) => {
    onSelect(assetType);
    onHide(false);
  };

  return (
    <KiteModal
      className="asset-type-picker"
      canShow={isOpen}
      title="What Type of Asset are you Adding?"
      showCloseButton={false}
    >
      <div className="asset-type-picker__assets">
        <button
          className="asset-type-picker__assets-button"
          onClick={() => handleSelect('movie')}
        >
          <KiteIcon
            className="icon"
            color="#0073D1"
            margin="0"
            name="ticket-f"
            size="64px"
          />
          <span className="title">Movie</span>
        </button>
        <button
          className="asset-type-picker__assets-button"
          onClick={() => handleSelect('episode')}
        >
          <KiteFontIcon
            className="icon"
            color="#0073D1"
            name="tv-f"
            size="64px"
          />
          <span className="title">Episode</span>
        </button>
      </div>
      <KiteButton
        className="asset-type-picker__cancel"
        type="outline"
        onClick={() => onHide(false)}
      >
        Cancel
      </KiteButton>
      <div className="asset-type-picker__bottom">
        <p className="asset-type-picker__bottom-copy">
          Is this asset here by mistake?{' '}
        </p>
        <KiteButton
          type="standalone-link"
          className="asset-type-picker__bottom-button"
          onClick={onRemove}
        >
          Remove Asset from Admin
        </KiteButton>
      </div>
    </KiteModal>
  );
};

export default AssetTypePicker;
