import React from 'react';
import './NewServiceCard.scss';
import { KiteIcon, KiteFontIcon } from '@kite/react-kite';
import { iconNames } from '../../utils/helpers';

export interface INewServiceFile {
  serviceType: string;
  serviceFile: File;
  serviceLanguage: string;
}

export interface IServiceCardProps {
  serviceData: INewServiceFile;
  onRemove: (name: string) => any;
}

const ServiceCard = ({ serviceData, onRemove }: IServiceCardProps) => {
  const {
    serviceType: type,
    serviceFile: file,
    serviceLanguage: language,
  } = serviceData;

  return (
    <div className="service-card new-service-card">
      <div className="service-card__heading-wrapper">
        <KiteIcon
          name={iconNames[type]}
          color="#9ba9bd"
          size="40px"
          onClick={() => onRemove(file.name)}
        />
        <h3>{type}</h3>
      </div>
      <p className="service-card__field">
        <span className="service-card__field-title">Filename:</span>
        {file.name}
      </p>
      <p className="service-card__field">
        <span className="service-card__field-title">Language:</span>
        {language}
      </p>
      <KiteFontIcon
        className="new-service-card__close"
        name="x-f"
        size="14px"
        color="#63738a"
        ariaLabel={`Remove ${file.name}`}
        onClick={() => onRemove(file.name)}
      />
    </div>
  );
};

export default ServiceCard;
