import React, { Component } from 'react';
import { RouterComponentProps } from 'react-router-dom';

import './AllEpisodes.scss';
import { IEpisode } from '../../utils/models';
import { AllEpisodesTable, SearchBar, StatusCounter } from '../../components';
import { KitePagination } from '@kite/react-kite';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';
import { getAllEpisodes } from '../../apiCalls/EpisodesService';

export interface IAllEpisodesState {
  allEpisodes: IEpisode[];
  statusFilter: string;
  currentPage: number;
  totalPages: number;
  ascending: boolean;
  sortBy: 'title' | 'createdAt' | 'updatedAt' | 'status';
  loading: boolean;
  totalEpisodes: number;
  totalNew: number;
  totalQC: number;
  totalRFP: number;
  totalProd: number;
}

class AllEpisodes extends Component<RouterComponentProps, IAllEpisodesState> {
  state: IAllEpisodesState = {
    allEpisodes: [],
    statusFilter: 'All',
    currentPage: 1,
    totalPages: 0,
    ascending: true,
    sortBy: 'title',
    loading: false,
    totalEpisodes: 0,
    totalNew: 0,
    totalQC: 0,
    totalRFP: 0,
    totalProd: 0,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    this.getEpisodeData(true, 1, 'title');
    const [analytics] = this.context;
    analytics.trackPageView('All Episodes');
  }

  getEpisodeData = (ascending, currentPage, sortBy, title?, status?) => {
    this.setState({ loading: true });
    const order = ascending ? 'asc' : 'desc';
    const requestConfig = {
      order,
      page: currentPage - 1,
      sortBy,
      title,
      status,
      size: 20,
    };
    getAllEpisodes(requestConfig).then(
      ({
        allEpisodes,
        totalPages,
        totalEpisodes,
        totalNew,
        totalQC,
        totalRFP,
        totalProd,
      }) =>
        this.setState({
          allEpisodes,
          totalPages,
          loading: false,
          totalEpisodes,
          totalNew,
          totalQC,
          totalRFP,
          totalProd,
        })
    );
  };

  handleSortClick = (sortHeader) => {
    const { sortBy, ascending, currentPage } = this.state;

    if (sortHeader === sortBy) {
      this.setState({ ascending: !ascending, allEpisodes: [] });
      this.getEpisodeData(!ascending, currentPage, sortHeader);
    } else {
      this.setState({ sortBy: sortHeader, ascending: true, allEpisodes: [] });
      this.getEpisodeData(ascending, currentPage, sortHeader);
    }
  };

  handleFilterChange = (newFilter) => {
    this.setState({ statusFilter: newFilter, currentPage: 1 });
    const { sortBy, ascending } = this.state;
    const statusFilter = newFilter === 'All' ? false : newFilter;
    this.getEpisodeData(ascending, 1, sortBy, false, statusFilter);
  };

  handleSearch = (query) => {
    this.setState({ currentPage: 1 });
    const { sortBy, ascending } = this.state;
    this.getEpisodeData(ascending, 1, sortBy, query);
  };

  handleNextPage = () => {
    const { sortBy, ascending, currentPage, statusFilter } = this.state;
    const newPage = currentPage + 1;
    this.setState({ currentPage: newPage, allEpisodes: [] });
    this.getEpisodeData(ascending, newPage, sortBy, false, statusFilter);
  };

  handlePreviousPage = () => {
    const { sortBy, ascending, currentPage, statusFilter } = this.state;
    const newPage = currentPage - 1;
    this.setState({ currentPage: newPage, allEpisodes: [] });
    this.getEpisodeData(ascending, newPage, sortBy, false, statusFilter);
  };

  handlePageSelect = (page) => {
    const { sortBy, ascending, statusFilter } = this.state;
    this.setState({ currentPage: page, allEpisodes: [] });
    this.getEpisodeData(ascending, page, sortBy, false, statusFilter);
  };

  render() {
    const {
      allEpisodes,
      statusFilter,
      currentPage,
      totalPages,
      ascending,
      sortBy,
      loading,
      totalEpisodes,
      totalNew,
      totalQC,
      totalRFP,
      totalProd,
    } = this.state;

    const statusCounts = [
      {
        label: 'Total Episodes',
        count: totalEpisodes,
      },
      {
        label: 'Newly Added',
        count: totalNew,
      },
      {
        label: 'In Quality Control',
        count: totalQC,
      },
      {
        label: 'Ready for Production',
        count: totalRFP,
      },
      {
        label: 'In Production',
        count: totalProd,
      },
    ];
    return (
      <div>
        <main className="all-movies">
          <h2 className="all-movies__title">Episodes</h2>

          <SearchBar
            featureType="Episodes"
            statusFilter={statusFilter}
            onFilterSelect={this.handleFilterChange}
            onSearch={this.handleSearch}
            showShadow={false}
            statusCounts={statusCounts}
          />
          <StatusCounter statusCounterData={statusCounts} />
          <AllEpisodesTable
            episodeData={allEpisodes}
            onSortClick={this.handleSortClick}
            ascending={ascending}
            sortHeader={sortBy}
            loading={loading}
          />
          {totalPages > 1 && (
            <KitePagination
              totalPages={totalPages}
              currentPage={currentPage}
              onNextPage={this.handleNextPage}
              onPrevPage={this.handlePreviousPage}
              onPageSelect={this.handlePageSelect}
            />
          )}
        </main>
      </div>
    );
  }
}

export default AllEpisodes;
