import React from 'react';

import './LanguagesCard.scss';
import { KiteCard } from '@kite/react-kite';
import { BasicPills } from '@kite/react-kite-plus';

export interface ILanguagesCardProps {
  languages: string[];
}

const LanguagesCard = ({ languages }: ILanguagesCardProps) => {
  return (
    <KiteCard className="languages-card">
      <h3 className="languages-card__title">Languages</h3>
      {languages && languages[0] && (
        <BasicPills className="languages-card__languages" values={languages} />
      )}
    </KiteCard>
  );
};

export default LanguagesCard;
