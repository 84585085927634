import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import * as CategoryService from '../../apiCalls/CategoryService';

import { KiteCard, KiteSelect, KiteAlert } from '@kite/react-kite';
import { MultiSelect, BasicPills } from '@kite/react-kite-plus';

import { TStatus, ICategory } from '../../utils/models';
import './CategoriesCard.scss';

export interface ICategoriesCardProps {
  status: TStatus;
  selectedCategories: ICategory[];
  primaryCategory: ICategory | null;
  showMessage?: boolean;
  onSelectCategories: (categories: ICategory[]) => any;
  onSelectPrimary: (category: ICategory | null) => any;
  onTriggerWarning: () => void;
  isSeries?: boolean;
}

const CategoriesCard = ({
  status,
  selectedCategories,
  primaryCategory,
  showMessage,
  onSelectCategories,
  onSelectPrimary,
  onTriggerWarning,
  isSeries,
}: ICategoriesCardProps) => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [orderedCategories, setOrderedCategories] = useState(
    selectedCategories.map((cat) => cat.name)
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await CategoryService.getCategories();
      setCategories(data);
    };

    fetchData();
  }, []);

  const removeCategory = (targetCategory: string) => {
    if (selectedCategories.length === 1) {
      return onTriggerWarning();
    }

    const updatedCategories = selectedCategories.filter(
      (category) => category.name !== targetCategory
    );
    handleSelectCategories(updatedCategories);
  };

  const handleSelectCategories = (categories: ICategory[]) => {
    // if there are no categories left
    if (!categories.length) {
      const tempCategories = [...selectedCategories];
      onSelectCategories([]);
      onSelectCategories(tempCategories);
      return onTriggerWarning();
    }
    // if primary category has been removed
    if (
      !primaryCategory ||
      !categories.find((cat) => cat === primaryCategory)
    ) {
      onSelectPrimary(categories[0]);
    }

    onSelectCategories(categories);
  };

  const orderCategories = (categories, primaryCategory) => {
    const ordered = [
      primaryCategory,
      ...categories.filter(
        (category) => category.name !== primaryCategory.name
      ),
    ];
    setOrderedCategories(ordered.map((cat) => cat.name));
  };

  useEffect(() => {
    if (primaryCategory) orderCategories(selectedCategories, primaryCategory);
  }, [selectedCategories, primaryCategory]);

  return (
    <KiteCard className="categories-card">
      <h3 className="categories-card__title">
        {isSeries && 'Series '}Categories
        <CSSTransition
          classNames="changes-message"
          in={showMessage}
          unmountOnExit
          timeout={400}
        >
          <>
            <KiteAlert
              level="inline"
              type="confirm"
              description="Changes Saved"
            />
          </>
        </CSSTransition>
      </h3>
      {status !== 'Ready for Production' ? (
        <>
          <MultiSelect
            className="categories-card__select-categories"
            label="Categories"
            defaultSelections={selectedCategories}
            options={categories}
            optionKey="name"
            onSelectionsChange={() => {}}
            onClose={handleSelectCategories}
            placeholder="Select categories"
          />
          {selectedCategories.length ? (
            <div className="categories-card__categories-container">
              <span className="primary-label">Primary</span>
              <BasicPills
                className="categories-card__categories"
                values={orderedCategories}
                onRemovePill={removeCategory}
              />
            </div>
          ) : null}
          <hr />
          <KiteSelect
            className="categories-card__select-primary"
            name="primaryCategory"
            id="primaryCategory"
            label="Primary Category"
            disabled={!selectedCategories.length}
            value={primaryCategory ? primaryCategory.name : ''}
            onChange={(e) =>
              onSelectPrimary(
                selectedCategories.find((cat) => cat.name === e.target.value) ||
                  null
              )
            }
          >
            {selectedCategories.map((category) => (
              <option value={category.name} key={category.id}>
                {category.name}
              </option>
            ))}
          </KiteSelect>
          <p className="categories-card__caption">
            This will determine the swimlane in the App.
          </p>
        </>
      ) : (
        <div className="categories-card__categories-container">
          <span className="primary-label">Primary</span>
          <BasicPills
            className="categories-card__categories"
            values={orderedCategories}
          />
        </div>
      )}
    </KiteCard>
  );
};

export default CategoriesCard;
