import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { ISwimlane } from '../../utils/models';
import { KiteButton } from '@kite/react-kite';
import './SwimlaneStatus.scss';

export interface ISwimlaneStatusProps {
  swimlaneData: ISwimlane;
  onEditClick: () => void;
  onCtaClick: (type: 'remove' | 'notReady' | 'schedule' | 'ready') => void;
  loadingButton: string;
}

const SwimlaneStatus = ({
  swimlaneData,
  onEditClick,
  onCtaClick,
  loadingButton,
}: ISwimlaneStatusProps) => {
  const { endTime, items, status, startTime } = swimlaneData;

  const getScheduleTime = (startTime, endTime) => {
    const beforeWindow = startTime && new Date(startTime) > new Date();
    const afterWindow = endTime && new Date(endTime) < new Date();
    if (beforeWindow) {
      return 'Scheduled';
    }
    if (afterWindow) {
      return 'Expired';
    } else {
      return false;
    }
  };

  const localStatus = getScheduleTime(startTime, endTime) || status;

  const statusCopy = {
    Ready: 'This swimlane is Ready to Deploy to the production app.',
    Published: 'This swimlane is deployed to the production app.',
    NotReady:
      'Move the status to Ready to Deploy to make it visible in staging.',
    Scheduled:
      'This swimlane is scheduled to be deployed to the production app.',
    Expired:
      'This swimlane has expired. Remove it from production to re-deploy it or update its dates.',
  };

  const statusCTA = {
    Ready: (
      <div className="swimlane-status__cta-wrapper">
        <KiteButton
          type="outline"
          className="swimlane-status__cta swimlane-status__cta--red"
          onClick={() => onCtaClick('notReady')}
          loading={loadingButton === 'statusRemove'}
        >
          Not Ready to Deploy
        </KiteButton>
      </div>
    ),
    Published: (
      <div className="swimlane-status__cta-wrapper">
        <KiteButton
          type="outline"
          className="swimlane-status__cta swimlane-status__cta--red"
          onClick={() => onCtaClick('remove')}
          loading={loadingButton === 'statusRemove'}
        >
          Remove from Production
        </KiteButton>
      </div>
    ),
    NotReady: (
      <div className="swimlane-status__cta-wrapper">
        <KiteButton
          type="outline"
          className={classNames({
            'swimlane-status__cta': true,
            'swimlane-status__cta--disabled': !items.length,
          })}
          onClick={() => onCtaClick('schedule')}
          disabled={!items.length}
        >
          Schedule Deployment
        </KiteButton>
        <KiteButton
          type="primary"
          className="swimlane-status__cta"
          onClick={() => onCtaClick('ready')}
          disabled={!items.length}
        >
          Ready to Deploy
        </KiteButton>
      </div>
    ),
    Scheduled: (
      <div className="swimlane-status__cta-wrapper swimlane-status__cta-wrapper--column">
        <KiteButton
          type="outline"
          className="swimlane-status__cta swimlane-status__cta--red"
          onClick={() => onCtaClick('remove')}
          loading={loadingButton === 'statusRemove'}
        >
          Not Ready to Deploy
        </KiteButton>
        <p className="swimlane-status__date">
          <span className="swimlane-status__date-title">Scheduled For</span>
          {startTime ? dayjs(startTime).format('M/D/YYYY') : '--'}
        </p>
      </div>
    ),
    Expired: (
      <div className="swimlane-status__cta-wrapper">
        <KiteButton
          type="outline"
          className="swimlane-status__cta swimlane-status__cta--red"
          onClick={() => onCtaClick('remove')}
          loading={loadingButton === 'statusRemove'}
        >
          Remove from Production
        </KiteButton>
      </div>
    ),
  };

  const statusDates = (
    <div className="swimlane-status__dates">
      <p className="swimlane-status__date">
        <span className="swimlane-status__date-title">Start Date</span>
        {startTime ? dayjs(startTime).format('M/D/YYYY') : '--'}
      </p>
      <p className="swimlane-status__date">
        {localStatus === 'Expired' ? (
          <span className="swimlane-status--expired">
            <span className="swimlane-status__date-title">Expired</span>
            {endTime ? dayjs(endTime).format('M/D/YYYY') : '--'}
          </span>
        ) : (
          <>
            <span className="swimlane-status__date-title">End Date</span>
            {endTime ? dayjs(endTime).format('M/D/YYYY') : '--'}
          </>
        )}
      </p>
      <KiteButton
        className="swimlane-status__edit-btn"
        type="standalone-link"
        leftIcon="edit-f"
        size="small"
        onClick={onEditClick}
      >
        Edit Dates
      </KiteButton>
    </div>
  );
  return (
    <div className="swimlane-status">
      <h2>Status</h2>
      <p className="swimlane-status__copy">{statusCopy[localStatus]}</p>
      {status !== 'NotReady' && statusDates}
      {statusCTA[localStatus]}
    </div>
  );
};

export default SwimlaneStatus;
