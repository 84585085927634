import React, { Component } from 'react';
import { RouterComponentProps } from 'react-router-dom';

// Components
import {
  CountriesCard,
  DeleteFeature,
  DeleteFeatureCard,
  FeatureServices,
  LanguagesCard,
  Metadata,
  NotesCard,
  StatusBar,
  Subheader,
  FilesCard,
  AddDrawer,
  Notes,
  PublishServices,
  ErrorsCard,
  UnpublishedServices,
  PublishFeature,
  RemoveFeature,
  UnresolvedErrors,
  ErrorModal,
  FileNameModal,
  SearchModal,
  InvalidTMSIDModal,
  AddErrorModal,
  ExpirationCard,
  ExpirationDateModal,
} from '../../components';

// Utils
import { IEpisode, IService, IFile } from '../../utils/models';
import './Episode.scss';
import {
  getEpisode,
  updateEpisode,
  deleteEpisode,
  getNewEpisodeMetadata,
} from '../../apiCalls/EpisodesService';
import {
  updateService,
  deleteService,
  createContent,
} from '../../apiCalls/ContentService';
import { getFolderFiles, uploadFile } from '../../apiCalls/BucketService';
import { getSeries, updateSeries } from '../../apiCalls/SeriesService';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';
import dayjs from 'dayjs';

interface IEpisodePageState {
  showModal:
    | ''
    | 'delete'
    | 'notes'
    | 'publish service'
    | 'unpublished service'
    | 'publish feature'
    | 'remove'
    | 'error'
    | 'unresolved error'
    | 'update'
    | 'file name'
    | 'invalid TMSID'
    | 'expiration date';
  episodeData: IEpisode | null;
  showCategoryWarning: boolean;
  addDrawer: '' | 'Service' | 'File';
  selectedFile: IService | IFile | null;
  selectedServices: string[];
  ariaLiveContent: string;
  showMetadataAlert: boolean;
  showCategoriesAlert: boolean;
  showFilesAlert: boolean;
  showServicesAlert: boolean;
  showNotesAlert: boolean;
  showCategoriesError: 'category' | 'file-QC' | 'file-Delete' | '';
  loadingButton: string;
  analytics: any;
  targetFileNames: string[];
  showInvalidTMSIDModalOverride: boolean;
  unsyncedMetadataOverride: boolean;
}

class Episode extends Component<RouterComponentProps, IEpisodePageState> {
  state: IEpisodePageState = {
    showModal: '',
    episodeData: null,
    showCategoryWarning: false,
    addDrawer: '',
    selectedFile: null,
    selectedServices: [],
    ariaLiveContent: '',
    showMetadataAlert: false,
    showCategoriesAlert: false,
    showFilesAlert: false,
    showServicesAlert: false,
    showNotesAlert: false,
    showCategoriesError: '',
    loadingButton: '',
    analytics: null,
    targetFileNames: [],
    showInvalidTMSIDModalOverride: false,
    unsyncedMetadataOverride: false,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    const [analytics] = this.context;
    this.setState({ analytics });
    this.updateEpisodeData(true);
  }

  updateEpisodeData = async (trackPageView?) => {
    const {
      match: {
        params: { episode_id },
      },
    } = this.props;
    const episodeData = await getEpisode(episode_id);
    this.setState({
      episodeData,
      loadingButton: '',
    });

    if (episodeData.unsyncedMetadata && !this.state.unsyncedMetadataOverride) {
      this.handleUnsyncedMetadata();
    }
    if (episodeData.badMetadata && !this.state.showInvalidTMSIDModalOverride) {
      this.handleBadMetadata();
    }

    if (trackPageView) {
      this.state.analytics.trackPageView(
        `Episode - ${episodeData.seriesTitle} - ${episodeData.title}`
      );
    }
  };

  handleUnsyncedMetadata = () => {
    this.removeFromProduction(['Unsynced metadata'], '');
    this.setState({ unsyncedMetadataOverride: true });
  };

  handleBadMetadata = () => {
    this.removeFromProduction(['Invalid TMSID'], '');
    this.setState({ showModal: 'invalid TMSID' });
  };

  handleStatusClick = (status) => {
    if (status === 'Quality Control' || status === 'Back to Quality Control') {
      this.moveToQC();
    } else if (status === 'Ready for Production') {
      if (this.state.episodeData?.errors.length) {
        this.setState({ showModal: 'unresolved error' });
      } else {
        this.setState({ showModal: 'unpublished service' });
      }
    } else if (status === 'Publish to Production') {
      this.setState({ showModal: 'publish feature' });
    } else if (status === 'Remove from Production') {
      this.setState({ showModal: 'remove' });
    } else if (status === 'Add Errors') {
      this.setState({ showModal: 'error' });
    }
  };

  moveToQC = async () => {
    if (!this.state.episodeData?.files.length) {
      this.setState({ showCategoriesError: 'file-QC' });
    } else {
      this.setState({ loadingButton: 'statusQC' });

      const { id, services } = this.state.episodeData || {};

      await updateEpisode(id, { status: 'QualityControl' });
      const servicePromises = services
        ? services.map((service) =>
            updateService(service.id, { status: 'QualityControl' })
          )
        : [];
      await Promise.all(servicePromises);
      await this.updateEpisodeData();
    }
  };

  movetoRFP = async (publishServices) => {
    this.setState({ loadingButton: 'statusRFP' });

    const { id, services } = this.state.episodeData || {};

    if (publishServices === 'yes') {
      await updateEpisode(id, { status: 'ReadyForProduction' });
      const servicePromises = services
        ? services.map((service) =>
            updateService(service.id, { status: 'ReadyForProduction' })
          )
        : [];
      await Promise.all(servicePromises);
      await this.updateEpisodeData();
    } else {
      await updateEpisode(id, { status: 'ReadyForProduction' });
      await this.updateEpisodeData();
    }
  };

  moveToProduction = async () => {
    this.setState({ loadingButton: 'statusPP' });

    const { id, services } = this.state.episodeData || {};

    await updateEpisode(id, { status: 'Production' });
    const servicePromises = services
      ? services.map((service) =>
          service.status === 'Ready for Production'
            ? updateService(service.id, { status: 'Production' })
            : null
        )
      : [];
    await Promise.all(servicePromises);
    this.state.analytics.track('selectAction', {
      msgCategory: 'navigation',
      msgTriggeredBy: 'user',
      opType: 'buttonClick',
      currPageElemStdName: 'publish',
      currPageElemUiName: 'Move to Production',
    });
    await this.updateEpisodeData();
  };

  removeFromProduction = async (
    selectedErrors: string[],
    errorDetails: string
  ) => {
    this.setState({ loadingButton: 'statusRP' });

    const { id, services } = this.state.episodeData || {};

    const formattedErrors = `${selectedErrors.join('---')}${
      errorDetails ? `---Other - ${errorDetails}` : ``
    }`;

    await updateEpisode(id, {
      status: 'QualityControl',
      error: formattedErrors,
    });
    const servicePromises = services
      ? services.map((service) =>
          updateService(service.id, { status: 'QualityControl' })
        )
      : [];
    await Promise.all(servicePromises);
    await this.updateEpisodeData();
  };

  addErrors = async (selectedErrors: string[], errorDetails: string) => {
    this.setState({ loadingButton: 'addErrors' });
    const { id } = this.state.episodeData || {};

    const formattedErrors = `${selectedErrors.join('---')}${
      errorDetails ? `---Other - ${errorDetails}` : ``
    }`;

    await updateEpisode(id, {
      error: formattedErrors,
    });

    await this.updateEpisodeData();
  };

  handleMetadataSearch = async () => {
    this.setState({ showModal: 'update' });
  };

  handleUpdateMetadata = async (newMetadata) => {
    const { id, services, season: existingSeason, seriesId } =
      this.state.episodeData || {};
    const { tmsId, title, newSeason, episode } = newMetadata;

    if (newSeason !== existingSeason) {
      await this.checkSeasonUpdate(seriesId, newSeason, existingSeason);
    }

    await updateEpisode(id, {
      tmsId,
      title,
      seasonNo: newSeason,
      episodeNo: episode,
    });

    const servicePromises = services
      ? services.map((service) => updateService(service.id, { tmsId }))
      : [];
    await Promise.all(servicePromises);
    await this.updateEpisodeData();
    this.setState({
      showMetadataAlert: true,
      ariaLiveContent: 'Metadata changes saved',
    });

    setTimeout(() => {
      this.setState({
        showMetadataAlert: false,
        ariaLiveContent: '',
      });
    }, 3000);
  };

  handleChangeTSMID = (newMetadata) => {
    this.setState({ showModal: '', loadingButton: 'metadata' });
    this.handleUpdateMetadata(newMetadata);
  };

  handleRefresh = async () => {
    this.setState({
      loadingButton: 'refresh metadata',
      unsyncedMetadataOverride: false,
    });
    const { id } = this.state.episodeData || {};
    const newMetadata = await getNewEpisodeMetadata(id);
    this.handleUpdateMetadata(newMetadata);
  };

  checkSeasonUpdate = async (seriesId, newSeason, existingSeason) => {
    const { seasons } = await getSeries(seriesId);
    let newSeasons = [...seasons].map((s) => s[0].season);
    if (!seasons.find((s) => s[0].season === newSeason)) {
      newSeasons.push(newSeason);
    }
    const existingSeasonItem = seasons.find(
      (s) => s[0].season === existingSeason
    );
    const existingSeasonIndex = existingSeasonItem
      ? seasons.indexOf(existingSeasonItem)
      : null;
    if (
      typeof existingSeasonIndex === 'number' &&
      seasons[existingSeasonIndex].length === 1
    ) {
      newSeasons = newSeasons.filter((s) => s !== existingSeason);
    }

    await updateSeries(seriesId, { seasons: newSeasons });
  };

  toggleNotesModal = (bool) => {
    this.setState({ showModal: bool ? 'notes' : '' });
  };

  toggleExpDateModal = (bool) => {
    this.setState({ showModal: bool ? 'expiration date' : '' });
  };

  toggleDeleteModal = (bool) => {
    this.setState({ showModal: bool ? 'delete' : '' });
  };

  handleNotesSave = async (notes) => {
    const { id } = this.state.episodeData || {};
    await updateEpisode(id, { notes: `${new Date()}---${notes}` });
    await this.updateEpisodeData();
    this.setState({
      showNotesAlert: true,
      ariaLiveContent: 'Notes changes saved',
    });

    setTimeout(() => {
      this.setState({
        showNotesAlert: false,
        ariaLiveContent: '',
      });
    }, 3000);
  };

  handleChangeExpiration = async (radioValue, dateValue) => {
    const { id } = this.state.episodeData || {};
    const updateFields: any = {};

    if (radioValue === 'none') {
      updateFields.useCmsExpiration = false;
      updateFields.customEndTime = null;
    } else if (radioValue === 'manual') {
      const newDate = +dayjs(dateValue);
      updateFields.useCmsExpiration = false;
      updateFields.customEndTime = newDate;
    } else if (radioValue === 'eCMS') {
      updateFields.useCmsExpiration = true;
    }

    this.setState({ loadingButton: 'exipiration date' });
    await updateEpisode(id, updateFields);
    await this.updateEpisodeData();
    this.setState({ loadingButton: '', showModal: '' });
  };

  handleDeleteFeature = async () => {
    this.setState({ loadingButton: 'deleteFeature' });

    const { episodeData } = this.state;
    const { services, id, seriesId, season } = episodeData || {};
    const servicePromises = services
      ? services.map((service) => deleteService(service.id))
      : [];
    await Promise.all(servicePromises);
    await deleteEpisode(id);
    const { seasons } = await getSeries(seriesId);
    if (
      !seasons[0][0] ||
      !seasons.find((s) => (s[0] ? s[0].season === season : false))
    ) {
      const newSeasons = seasons
        .map((s) => (s[0] ? s[0].season : null))
        .filter((s) => s !== season);
      await updateSeries(seriesId, { seasons: newSeasons });
    }
    this.handleBackClick();
  };

  handleServiceClick = async (action, id) => {
    const { episodeData } = this.state;

    if (action === 'add') {
      this.setState({ addDrawer: 'Service' });
    } else if (action === 'edit') {
      const targetFile =
        episodeData &&
        episodeData.services.find((service) => service.id === id);
      const selectedFile = targetFile ? targetFile : null;
      this.setState({
        addDrawer: 'Service',
        selectedFile,
      });
    } else if (action === 'publishAll') {
      const selectedServices = episodeData
        ? episodeData.services
            .filter((service) => service.status === 'Quality Control')
            .map((service) => service.id)
        : [];
      this.setState({
        showModal: 'publish service',
        selectedServices,
      });
    } else if (action === 'publish') {
      this.setState({
        showModal: 'publish service',
        selectedServices: [id],
      });
    } else if (action === 'remove') {
      await updateService(id, { status: 'QualityControl' });
      await this.updateEpisodeData();
    }
  };

  handleFileClick = (action, id) => {
    if (action === 'add') {
      this.setState({ addDrawer: 'File' });
    } else {
      const { episodeData } = this.state;
      const targetFile =
        episodeData && episodeData.files.find((file) => file.id === id);
      const selectedFile = targetFile ? targetFile : null;
      this.setState({
        addDrawer: 'File',
        selectedFile,
      });
    }
  };

  handleAddDrawerClose = () => {
    this.setState({ addDrawer: '', selectedFile: null });
  };

  handleFileSubmit = async (action, fileData) => {
    const { addDrawer, selectedFile } = this.state;

    if (action === 'Update Service' && selectedFile) {
      await updateService(selectedFile.id, fileData);
      await this.updateEpisodeData();

      this.setState({
        showServicesAlert: true,
        ariaLiveContent: 'Services changes saved',
      });

      setTimeout(() => {
        this.setState({
          showServicesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    } else if (action === 'Update File' && selectedFile) {
      const { language: newLanguage } = fileData;
      const { files, id } = this.state.episodeData || {};
      const updatedFiles = files
        ? files.map(({ language, file, id }) =>
            id === selectedFile.id
              ? { language: newLanguage, file }
              : { language, file }
          )
        : [];

      await updateEpisode(id, { fingerprintFiles: updatedFiles });
      await this.updateEpisodeData();
      this.setState({
        showFilesAlert: true,
        ariaLiveContent: 'Files changes saved',
      });

      setTimeout(() => {
        this.setState({
          showFilesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    } else if (addDrawer === 'Service') {
      const { serviceFile, serviceLanguage, serviceType } = fileData;
      const { tmsId } = this.state.episodeData || {};
      const filePath = `/assets/${tmsId}/${serviceFile.name}`;
      await uploadFile(serviceFile, `/assets/${tmsId}`);
      await createContent(
        filePath,
        serviceLanguage,
        serviceType,
        tmsId,
        'QualityControl'
      );
      await this.updateEpisodeData();
    } else if (addDrawer === 'File') {
      const { serviceFile, serviceLanguage } = fileData;
      const { tmsId, files, id } = this.state.episodeData || {};
      const filePath = `assets/${tmsId}/${serviceFile.name}`;

      await uploadFile(serviceFile, `/assets/${tmsId}`);
      const newFile = { language: serviceLanguage, file: filePath };
      const updatedFiles = files ? [...files, newFile] : [newFile];
      const fingerprintFiles = updatedFiles.map(({ language, file }) => ({
        language,
        file,
      }));
      await updateEpisode(id, { fingerprintFiles });
      await this.updateEpisodeData();
      this.setState({
        showFilesAlert: true,
        ariaLiveContent: 'Files changes saved',
      });

      setTimeout(() => {
        this.setState({
          showFilesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    }
  };

  handleFileDelete = async (name) => {
    const { files, id } = this.state.episodeData || {};
    if (files && files.length > 1) {
      const fingerprintFiles = files
        .filter((file) => file.file !== name)
        .map(({ language, file }) => ({
          language,
          file,
        }));

      await updateEpisode(id, { fingerprintFiles });
      await this.updateEpisodeData();
      this.setState({
        showFilesAlert: true,
        ariaLiveContent: 'Files changes saved',
      });

      setTimeout(() => {
        this.setState({
          showFilesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    } else {
      this.setState({ showCategoriesError: 'file-Delete' });
    }
  };

  handleServiceDelete = async (id) => {
    await deleteService(id);
    await this.updateEpisodeData();
    this.setState({
      showServicesAlert: true,
      ariaLiveContent: 'Services changes saved',
    });

    setTimeout(() => {
      this.setState({
        showServicesAlert: false,
        ariaLiveContent: '',
      });
    }, 3000);
  };

  handlePublishServicesHide = () => {
    this.setState({ showModal: '', selectedServices: [] });
  };

  handlePublishServices = async (ids) => {
    const servicePromises = ids.map((id) =>
      updateService(id, { status: 'Production' })
    );

    await Promise.all(servicePromises);
    await this.updateEpisodeData();
    this.setState({
      showServicesAlert: true,
      ariaLiveContent: 'Services changes saved',
    });

    setTimeout(() => {
      this.setState({
        showServicesAlert: false,
        ariaLiveContent: '',
      });
    }, 3000);
  };

  handleResolveErrors = async () => {
    this.setState({ loadingButton: 'errorsResolved' });

    const { id } = this.state.episodeData || {};
    await updateEpisode(id, { error: null });
    await this.updateEpisodeData();
  };

  handleBackClick = () => {
    const { pathname } = this.props.location;
    const seasonUrl = pathname.split('/episodes')[0];
    this.props.history.push(`${seasonUrl}?seasons=true`);
  };

  handleFilenameUpdateClick = async (fileName) => {
    this.setState({ showModal: 'file name' });
    const fileNames = await getFolderFiles(fileName);
    this.setState({ targetFileNames: fileNames });
  };

  handleFilenameChange = async (fileName) => {
    const { selectedFile } = this.state;

    const isService = (file: any): file is IService => {
      return 'type' in file;
    };

    if (isService(selectedFile)) {
      await updateService(selectedFile.id, { file: fileName });
      await this.updateEpisodeData();
      const newSelectedFile = { ...selectedFile, file: fileName };
      this.setState({
        showServicesAlert: true,
        ariaLiveContent: 'Services changes saved',
        showModal: '',
        selectedFile: newSelectedFile,
      });

      setTimeout(() => {
        this.setState({
          showServicesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    } else if (selectedFile) {
      const { files, id } = this.state.episodeData || {};
      const updatedFiles = files
        ? files.map(({ language, file, id }) =>
            id === selectedFile.id
              ? { file: fileName, language }
              : { language, file }
          )
        : [];

      await updateEpisode(id, { fingerprintFiles: updatedFiles });
      await this.updateEpisodeData();
      const newSelectedFile = { ...selectedFile, id: fileName, file: fileName };
      this.setState({
        showFilesAlert: true,
        ariaLiveContent: 'Files changes saved',
        showModal: '',
        selectedFile: newSelectedFile,
      });

      setTimeout(() => {
        this.setState({
          showFilesAlert: false,
          ariaLiveContent: '',
        });
      }, 3000);
    }
  };

  render() {
    const {
      episodeData,
      showModal,
      addDrawer,
      selectedFile,
      selectedServices,
      ariaLiveContent,
      showMetadataAlert,
      showFilesAlert,
      showServicesAlert,
      showNotesAlert,
      showCategoriesError,
      loadingButton,
      targetFileNames,
    } = this.state;

    const errorOptions = [
      'Incorrect Metadata',
      'Incorrect Preferences',
      'Incorrect Categories',
      'Error in Services',
      'Other',
    ];

    let displayPage;

    if (episodeData) {
      const {
        title,
        status,
        services,
        notes,
        files,
        errors,
        seriesTitle,
        season,
        number,
        badMetadata,
        unsyncedMetadata,
        endTime,
        customEndTime,
        useCmsExpiration,
      } = episodeData || {};

      const languages = Array.from(
        new Set(services.map((service) => service.language))
      );

      displayPage = (
        <>
          <Subheader
            featureTitle={title}
            featureType="episode"
            status={status}
            seriesTitle={seriesTitle}
            seasonNumber={season}
            episodeNumber={number}
            onButtonClick={this.handleBackClick}
          />
          <main className="episode">
            {!!errors.length && (
              <ErrorsCard
                errors={errors}
                onButtonClick={this.handleResolveErrors}
                loadingButton={loadingButton}
              />
            )}
            <StatusBar
              status={status}
              onButtonClick={this.handleStatusClick}
              loadingButton={loadingButton}
            />
            <Metadata
              assetType="episode"
              data={episodeData}
              onUpdate={this.handleMetadataSearch}
              onRefresh={this.handleRefresh}
              showAlert={showMetadataAlert}
              loadingButton={loadingButton}
              badMetadata={badMetadata || unsyncedMetadata}
            />
            <ExpirationCard
              endTime={endTime}
              customEndTime={customEndTime}
              useCmsExpiration={useCmsExpiration}
              onEdit={() => this.toggleExpDateModal(true)}
            />
            <LanguagesCard languages={languages} />
            <CountriesCard countries={['United States']} />

            <FilesCard
              files={files}
              status={status}
              onButtonClick={this.handleFileClick}
              showAlert={showFilesAlert}
            />
            <FeatureServices
              services={services}
              status={status}
              onButtonClick={this.handleServiceClick}
              showAlert={showServicesAlert}
            />
            <NotesCard
              notesData={notes}
              onEdit={() => this.toggleNotesModal(true)}
              showAlert={showNotesAlert}
            />
            <DeleteFeatureCard
              onDelete={() => this.toggleDeleteModal(true)}
              loadingButton={loadingButton}
            />
          </main>
          <div className="sr-only" aria-live="polite" aria-atomic="true">
            {ariaLiveContent}
          </div>
          <Notes
            isOpen={showModal === 'notes'}
            onClose={() => this.toggleNotesModal(false)}
            onSave={this.handleNotesSave}
            notes={notes}
          />
          <ExpirationDateModal
            isOpen={showModal === 'expiration date'}
            loading={loadingButton === 'exipiration date'}
            onClose={() => this.toggleExpDateModal(false)}
            endTime={endTime}
            customEndTime={customEndTime}
            onSave={this.handleChangeExpiration}
            useCmsExpiration={useCmsExpiration}
          />
          <DeleteFeature
            assetType="feature"
            assetTitle={title}
            isOpen={showModal === 'delete'}
            onHide={() => this.toggleDeleteModal(false)}
            onDelete={this.handleDeleteFeature}
          />
          <AddDrawer
            type={addDrawer}
            isOpen={!!addDrawer}
            onClose={this.handleAddDrawerClose}
            onSubmit={this.handleFileSubmit}
            onDelete={
              addDrawer === 'Service'
                ? this.handleServiceDelete
                : this.handleFileDelete
            }
            fileData={selectedFile}
            onUpdateFilename={this.handleFilenameUpdateClick}
          />
          <PublishServices
            isOpen={showModal === 'publish service'}
            serviceIDs={selectedServices}
            onHide={this.handlePublishServicesHide}
            onPublish={this.handlePublishServices}
          />
          <UnpublishedServices
            isOpen={showModal === 'unpublished service'}
            onHide={() => this.setState({ showModal: '' })}
            onContinue={this.movetoRFP}
          />
          <PublishFeature
            isOpen={showModal === 'publish feature'}
            onHide={() => this.setState({ showModal: '' })}
            onPublish={this.moveToProduction}
            featureType="movie"
          />
          <RemoveFeature
            isOpen={showModal === 'remove'}
            assetType="movie"
            errorOptions={errorOptions}
            onHide={() => this.setState({ showModal: '' })}
            onRemove={this.removeFromProduction}
          />
          <AddErrorModal
            isOpen={showModal === 'error'}
            errorOptions={errorOptions}
            onHide={() => this.setState({ showModal: '' })}
            onAdd={this.addErrors}
          />
          <UnresolvedErrors
            isOpen={showModal === 'unresolved error'}
            onClose={() => this.setState({ showModal: '' })}
            errors={errors}
          />
          <ErrorModal
            isOpen={!!showCategoriesError}
            onClose={() => this.setState({ showCategoriesError: '' })}
            type={showCategoriesError}
          />
          <SearchModal
            isOpen={showModal === 'update'}
            onClose={() => this.setState({ showModal: '' })}
            featureType="episode"
            title={seriesTitle}
            episodeTitle={title}
            onUpdate={this.handleChangeTSMID}
          />
          <FileNameModal
            isOpen={showModal === 'file name'}
            onClose={() => this.setState({ showModal: '' })}
            filenames={targetFileNames}
            onApply={this.handleFilenameChange}
          />
          <InvalidTMSIDModal
            isOpen={showModal === 'invalid TMSID'}
            onClose={() =>
              this.setState({
                showModal: '',
                showInvalidTMSIDModalOverride: true,
              })
            }
            onClick={() => {
              this.setState({
                showModal: '',
                showInvalidTMSIDModalOverride: true,
              });
              this.setState({
                showModal: 'update',
              });
            }}
            featureType="episode"
          />
        </>
      );
    }
    return <div>{displayPage}</div>;
  }
}

export default Episode;
