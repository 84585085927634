import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';

import './Nav.scss';
import { KiteIcon, KiteBadge } from '@kite/react-kite';
import { getNewAssets } from '../../apiCalls/AssetsService';

const Nav = () => {
  const [assetNum, setAssetNum] = useState(0);
  useEffect(() => {
    getNewAssets().then((assets) => setAssetNum(assets.length));
  });
  return (
    <header className="nav">
      <div className="nav__content">
        <h1>Spectrum Access Admin</h1>
        <nav>
          <ul>
            <li>
              <NavLink
                className="nav__link nav__new-assets"
                activeClassName="nav__link--active"
                to="/new-assets"
              >
                <span
                  className="nav__link-content"
                  tabIndex={-1}
                  data-link="New Assets"
                >
                  <KiteIcon
                    className="nav__link-icon"
                    name="folder"
                    color="#9ba9bd"
                  />
                  <span className="nav__new-assets-text"> New Assets</span>
                  {!!assetNum && (
                    <KiteBadge
                      badge={`${assetNum}`}
                      className="nav__link-badge"
                    />
                  )}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav__link"
                activeClassName="nav__link--active"
                to="/movies"
              >
                <span
                  className="nav__link-content"
                  tabIndex={-1}
                  data-link="Movies"
                >
                  <KiteIcon
                    className="nav__link-icon"
                    name="ticket-f"
                    color="#9ba9bd"
                  />
                  Movies
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav__link"
                activeClassName="nav__link--active"
                to="/series"
              >
                <span
                  className="nav__link-content"
                  tabIndex={-1}
                  data-link="Series"
                >
                  <KiteIcon
                    className="nav__link-icon"
                    name="tv-f"
                    color="#9ba9bd"
                  />
                  Series
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav__link"
                activeClassName="nav__link--active"
                to="/episodes"
              >
                <span
                  className="nav__link-content"
                  tabIndex={-1}
                  data-link="Episodes"
                >
                  <KiteIcon
                    className="nav__link-icon"
                    name="library"
                    color="#9ba9bd"
                  />
                  Episodes
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav__link"
                activeClassName="nav__link--active"
                to="/swimlanes"
              >
                <span
                  className="nav__link-content"
                  tabIndex={-1}
                  data-link="Swimlanes"
                >
                  <KiteIcon
                    className="nav__link-icon"
                    name="on-demand"
                    color="#9ba9bd"
                  />
                  Swimlanes
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav__link"
                activeClassName="nav__link--active"
                to="/categories"
              >
                <span
                  className="nav__link-content"
                  tabIndex={-1}
                  data-link="Categories"
                >
                  <KiteIcon
                    className="nav__link-icon"
                    name="grid-f"
                    color="#9ba9bd"
                  />
                  Categories
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav__link"
                activeClassName="nav__link--active"
                to="/search"
              >
                <span
                  className="nav__link-content"
                  tabIndex={-1}
                  data-link="Search"
                >
                  <KiteIcon
                    className="nav__link-icon"
                    name="search"
                    color="#9ba9bd"
                  />
                  Search
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav__link"
                activeClassName="nav__link--active"
                to="/reports"
              >
                <span
                  className="nav__link-content"
                  tabIndex={-1}
                  data-link="reports"
                >
                  <KiteIcon
                    className="nav__link-icon"
                    name="document"
                    color="#9ba9bd"
                  />
                  Reports
                </span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Nav;
