import React from 'react';

import './StatusBar.scss';
import { KiteCard, KiteButton } from '@kite/react-kite';
import { LinearProgressStepper } from '@kite/react-kite-plus';
import { TStatus } from '../../utils/models';

export interface TStatusBarProps {
  status: TStatus;
  onButtonClick: (button: string) => any;
  loadingButton: string;
}

const StatusBar = ({
  status,
  onButtonClick,
  loadingButton,
}: TStatusBarProps) => {
  let displayCopy;
  let displayButtons;

  switch (status) {
    case 'New':
      displayCopy =
        'Move the status to Quality Control when work begins on this feature.';
      displayButtons = (
        <KiteButton
          className="status-bar__button"
          onClick={() => onButtonClick('Quality Control')}
          loading={loadingButton === 'statusQC'}
        >
          Quality Control
        </KiteButton>
      );
      break;
    case 'Quality Control':
      displayCopy =
        'Move the status to Ready for Production when edits are complete.';
      displayButtons = (
        <>
          <KiteButton
            className="status-bar__button--red"
            type="outline"
            onClick={() => onButtonClick('Add Errors')}
            loading={loadingButton === 'addErrors'}
          >
            Add Errors
          </KiteButton>
          <KiteButton
            className="status-bar__button"
            onClick={() => onButtonClick('Ready for Production')}
            loading={loadingButton === 'statusRFP'}
          >
            Ready for Production
          </KiteButton>
        </>
      );
      break;
    case 'Ready for Production':
      displayCopy =
        'Move the status to Production when all details are reviewed and verified.';
      displayButtons = (
        <>
          <KiteButton
            className="status-bar__button"
            type="outline"
            onClick={() => onButtonClick('Back to Quality Control')}
            loading={loadingButton === 'statusQC'}
          >
            Back to Quality Control
          </KiteButton>
          <KiteButton
            className="status-bar__button"
            onClick={() => onButtonClick('Publish to Production')}
            loading={loadingButton === 'statusPP'}
          >
            Publish to Production
          </KiteButton>
        </>
      );
      break;
    case 'In Production':
      displayCopy = 'Remove from production to make edits and add services.';
      displayButtons = (
        <KiteButton
          className="status-bar__button--red"
          type="outline"
          onClick={() => onButtonClick('Remove from Production')}
          loading={loadingButton === 'statusRP'}
        >
          Remove from Production
        </KiteButton>
      );
      break;
  }

  const steps = [
    {
      kiteIcon: 'plus-circle-f',
      label: 'New',
      status: 'initial',
    },
    {
      kiteIcon: 'edit-circle',
      label: 'Quality Control',
      status: 'initial',
    },
    {
      kiteIcon: 'arrow-up-circle',
      label: 'Ready for Production',
      status: 'initial',
    },
    {
      kiteIcon: 'checkmark-circle-f',
      label: 'In Production',
      status: 'initial',
    },
  ];

  return (
    <KiteCard className="status-bar">
      <h2>Status</h2>
      <p className="status-bar__copy">{displayCopy}</p>
      <LinearProgressStepper activeStep={status} steps={steps} />
      <div className="status-bar__button-wrapper">{displayButtons}</div>
    </KiteCard>
  );
};

export default StatusBar;
